import React, { FC, useState, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { addToast } from "shared/Toast";
import { LoginQuery, resetPassword } from "Services/API/Post";
import jwt_decode from "jwt-decode";
import LoadingSpinner from "shared/LoadingSpinner";
import BotToast from "shared/BotToast";
import { t } from "i18next";
import Label from "components/Label/Label";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const history = useHistory();
  const [lopen, setlopen] = React.useState(false);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const [username, setusername] = useState("");
  const [lpass, setlpass] = useState("");
  const [aKey, setaKey] = useState("login");

  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);
  const [errPass, seterrPass] = useState(false);
  const [errUsername, seterrerrUsername] = useState("");
  const [errLpass, seterrerrLpass] = useState("");

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const Login = async (e?: any) => {

    e.preventDefault();

    let obj = {
      email: username,
      language: t("langKey"),
      type: username?.includes("@") === true ? "email" : "mobile",
    }

    console.log("send >>>", obj)

    if (obj.email === "") {
      seterrerrUsername("This field required")
    }

    if (obj.email !== "") {
      setlopen(true);
      resetPassword(obj)
        .then((res) => {
          setlopen(false);
          // setOpenToast(true)
          // setAlertClass("success");
          // setColor(COLORS.Primary)
          // addToast("Password reset link has been emailed", "success");

          // setbOpen(true);
          // setbMsg("Password reset link has been emailed");
          // setbStatus("success");
          history.push("/reset-password-otp");
          // setMsg("Password reset link has been emailed")
          // if (res.status) {
          //   localStorage.setItem(
          //     `${window.location.hostname}.login`,
          //     JSON.stringify(res)
          //   );
          // }

          // setEmail("");
          // setPassword("");

          // history.push("/");
          // setTimeout(() => { setMsg(""); }, 4000);
        })
        .catch((error) => {
          setlopen(false);
          console.log(error)
          // setColor("red");
          // setAlertClass("error");
          // setOpenToast(true)
          // setColor("red")
          // addToast(error.response.data.message, "warning");

          setbOpen(true);
          setbMsg(error.response.data.message);
          setbStatus("warning");
          // setMsg(error.response.data.message)
        });
    }




  };

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {

        setbOpen(false)

      }, 10000);
    }
  }, [bOpen])

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot Password || Booking React Template</title>
      </Helmet>
      <LoadingSpinner open={lopen} />

      <div className="w-full flex justify-center items-center">


        <div className="w-full px-3 sm:px-0 md:w-6/12 lg:w-4/12 xl:w-[29%]">


          <div className=" mb-24 lg:mb-32 bg-[#F4F4F4] mt-14 rounded-lg">
            <div className="py-10">

              <h2 className="mb-10 flex items-center text-3xl md:text-2xl font-bold text-[#003d35] dark:text-neutral-100 justify-center text-center">
                {t('signInForgot')}

              </h2>
              <div className="px-10 sm:px-12 space-y-6 ">
                {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
                {/* OR */}
                {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
                {/* FORM */}
                <form className="grid grid-cols-1 gap-6" onSubmit={Login}>
                  <label className="block">
                    <Label className="text-sm" >               {t('signUpEmail')} / {t('fiContact')}

                    </Label>

                    {errUsername && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        {errUsername}
                      </div>
                    )}
                    <Input
                      type="text"
                      // placeholder={`${t('signUpEmail')} / ${t('fiContact')}`}
                      className="mt-1"
                      onChange={(e) => {
                        setusername(e.target.value);
                        seterrerrUsername("");
                      }}
                      value={username}
                    />

                  </label>

                  <BotToast open={bOpen} msg={bMsg} status={bStatus} />
                  {/* <ButtonPrimary type="submit">Continue</ButtonPrimary> */}

                  <button
                    className={
                      `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b] 
        w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}


                    type="submit"
                  >
                    {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                    <span className="block  text-sm font-medium">
                      {t('appDetails')}

                    </span>

                  </button>
                </form>

                {/* ==== */}
                {/* <span className="block text-center text-gray-500 dark:text-neutral-300">
                  {t("logCap1")} {` `}
                  <Link className="text-[#b69d52]" to="/signup">
                    {t("logCap2")}
                  </Link>
                </span> */}
              </div>
            </div>

          </div>

        </div>

      </div>


    </div>
  );
};

export default PageLogin;
