// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/Maps.png";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";
import FAQs from "./FAQ";

export interface PageAboutProps {
    className?: string;
}

const FOUNDER_DEMO: People[] = [
    {
        id: "1",
        name: `Niamh O'Shea`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "4",
        name: `Danien Jame`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "3",
        name: `Orla Dwyer`,
        job: "Co-founder, Chairman",
        avatar:
            "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "2",
        name: `Dara Frazier`,
        job: "Co-Founder, Chief Strategy Officer",
        avatar:
            "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
];


const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Privacy Policy || Mangalya.lk</title>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            {/* <BgGlassmorphism /> */}

            <div className="container py-16 lg:pt-10 lg:pb-28 space-y-16 lg:space-y-14">
                {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}

                <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10 ">

                    <div className="w-full">

                        <div className="w-full space-y-2">



                            <h2 className="flex justify-center text-2xl font-semibold text-neutral-900 md:text-4xl dark:text-neutral-100">
                                Frequently Asked Questions
                            </h2>


                        </div>
                        <FAQs />
                    </div>

                </div>





                {/* <SectionFounder /> */}
                {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

                {/* <SectionPromo3 /> */}
            </div>
        </div>
    );
};

export default PageAbout;
