import Axios from "../../Axios";

export const DeleteCard = async (id) => {
  return await Axios({
    pathname: `api/v1/cart/${id}`,
    method: "delete",
  });
};
export const DeleteWishlist = async (id) => {
  return await Axios({
    pathname: `api/v1/wishlist/${id}`,
    method: "delete",
  });
};
export const DeleteOrderHistory = async (id) => {
  return await Axios({
    pathname: `api/v1/orderhistory/${id}`,
    method: "delete",
  });
};
export const DeleteFAQ = async (id) => {
  return await Axios({
    pathname: `api/v1/faq/${id}`,
    method: "delete",
  });
};

export const DeleteJob = async (id) => {
  return await Axios({
    pathname: `api/v1/job/${id}`,
    method: "delete",
  });
};

export const DeleteEvent = async (id) => {
  return await Axios({
    pathname: `api/v1/event/${id}`,
    method: "delete",
  });
};

export const DeleteProduct = async (id) => {
  return await Axios({
    pathname: `api/v1/product/${id}`,
    method: "delete",
  });
};

export const DeleteContract = async (id) => {
  return await Axios({
    pathname: `api/v1/serviceseeker/${id}`,
    method: "delete",
  });
};

export const DeleteClean = async (id) => {
  return await Axios({
    pathname: `api/v1/cleaningtype/${id}`,
    method: "delete",
  });
};

export const DeleteRecieved = async (id) => {
  return await Axios({
    pathname: `api/v1/receivedQuotation/${id}`,
    method: "delete",
  });
};

export const DeleteProfile = async (id) => {
  return await Axios({
    pathname: `api/v1/member/${id}`,
    method: "delete",
  });
};

export const DeleteAd = async (id) => {
  return await Axios({
    pathname: `api/v1/ad/${id}`,
    method: "delete",
  });
};

export const DeleteServicePackage = async (id) => {
  return await Axios({
    pathname: `api/v1/servicePackage/${id}`,
    method: "delete",
  });
};