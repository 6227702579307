import React, { FC, useState, useEffect, useRef, useContext } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import {
  CurrentUser,
  getCities,
  getCompany,
  getDistricts,
  getFAQs,
  getSignUpVerification
} from "Services/API/Get";
import LoadingSpinner from "shared/LoadingSpinner";
import { postContacts } from "Services/API/Post";
import { addToast } from "shared/Toast";
import SectionPromo2 from "components/SectionPromoHeader";
import cover from "images/cover/cover-9.png";
import Company1 from "images/avatars/Image-1.png";
import PageCollection2 from "containers/PageCollection2";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import Gateway from "./Gateway";
import Jobs from "./Jobs";
import Blogs from "./Blogs";
import Account from "./Account";
import ChangePassword from "./ChangePassword";
import Billing from "./Billing";
import SectionSliderReviews from "components/SectionSliderCategories/SectionSliderReviewsNew";
import CreateStore from "./CreateStore";
import OrderSummary from "./OrderSummary";
import Stocks from "./Stocks";
import MyJobs from "./MyJobs";
import Listing from "./Listing";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import Events from "./Events";
import BlogList from "./BlogList";
import SaveList from "./SaveList";
import { EditUserQuery, UpdateUserwithemail } from "Services/API/Put";
import AuthContext from "Context/AuthContext";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import { upperCase } from "lodash";
import Contracts from "./Contracts";
import FAQForm from "components/FAQForm";
import CleaningTypes from "./CleaningTypes";
import ShopClean from "./shopClean";

import demCover from "images/cover/demo-1.jpg";
import demAvatar from "images/cover/demo-2.jpg";

import AppliedJobs from "./AppliedJobs";

import AppliedContracts from "./AppliedContracts";
import Resizer from "react-image-file-resizer";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop
} from "react-image-crop";

import Cropper from "react-easy-crop";

import AddGallery from "./AddGallery";
import Gallery from "./Gallery";
import { Rating, Tooltip, Zoom } from "@mui/material";

import Delivery from "./Delivery";

import HireSummary from "./HireSummary";

import QuotClean from "./QuotClean";
import ModalQuickView from "components/ModalCover";

import MyOrderSummary from "./MyOrderSummary";
import MyHireSummary from "./MyHireSummary";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { COLORS } from "Constant/Colors";
import { FONTS_INTER } from "Constant";

import ModalQuickViewToast from "components/ModalToast";

import MyProfies from "./MyProfiles";

import Packages from "./Packages";

import ShopPackages from "./shopPackages";
import Profiles from "./Profiles";

import Replies from "./Replies";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  maxWidth: 400,
  border: 0
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.Primary
    },
    secondary: {
      main: "#f44336"
    }
  }
});

export interface PageContactProps {
  className?: string;
}

const panelClassName =
  "p-4 pt-3 last:pb-0  text-sm dark:text-slate-300 leading-6";

const DEMO_DATA = [
  {
    name: "Our team of skilled creative event specialists blend world-class tech with innovative ideas ?",
    content:
      "We provide the creativity, innovation and the tools to reimagine meetings and corporate event management, conferencing, group and incentive travel, production and events. From virtualand hybrid events, to in person immersive experiences and unconventional showstoppers. We do it all. And we do it exceptionally well. Need travel management support for your next event? No dramas. Our operational team is highly experienced and know how to make the travel experience and bookings flow. Smooth"
  },
  {
    name: "Fabric + Care",
    content: `<ul class="list-disc list-inside leading-7">
    <li>Made from a sheer Belgian power micromesh.</li>
    <li>
    74% Polyamide (Nylon) 26% Elastane (Spandex)
    </li>
    <li>
    Adjustable hook & eye closure and straps
    </li>
    <li>
    Hand wash in cold water, dry flat
    </li>
  </ul>`
  },

  {
    name: "How it Fits",
    content:
      "Use this as a guide. Preference is a huge factor — if you're near the top of a size range and/or prefer more coverage, you may want to size up."
  },
  {
    name: "FAQ",
    content: `
    <ul class="list-disc list-inside leading-7">
    <li>All full-priced, unworn items, with tags attached and in their original packaging are eligible for return or exchange within 30 days of placing your order.</li>
    <li>
    Please note, packs must be returned in full. We do not accept partial returns of packs.
    </li>
    <li>
    Want to know our full returns policies? Here you go.
    </li>
    <li>
    Want more info about shipping, materials or care instructions? Here!
    </li>
  </ul>
    `
  }
];

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter"
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com"
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890"
  }
];

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const history = useHistory();

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const { user, setUser } = useContext(AuthContext);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlasttName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [premail, setpremail] = useState("");
  const [lopen, setlopen] = React.useState(false);

  const [homeNo, sethomeNo] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeCity, sethomeCity] = useState("");
  const [homepostCode, sethomepostCode] = useState("");

  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);
  const [errhomepostCode, seterrhomepostCode] = useState(false);
  const [errhomeAddress, seterrhomeAddress] = useState(false);

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [password, setPassword] = useState("");
  const [repassword, setrePassword] = useState("");
  const [idisable, setidisable] = useState(true);
  const [errfirstName, seterrfirstName] = useState(false);
  const [errlastName, seterrlasttName] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [erremail, seterremail] = useState(false);

  const [errPass, seterrPass] = useState(false);
  const [errRePass, seterrRePass] = useState("");

  const [userId, setuserId] = useState("");
  const [currUser, setcurrUser] = useState<any>("");
  const [eDisable, seteDisable] = useState(true);
  const [eDisable2, seteDisable2] = useState(true);

  const [selectedTab, setselectedTab] = useState<any>(99);

  const [selectedTab2, setselectedTab2] = useState(null);

  const [company, setcompany] = useState<any>("");

  const [subject, setsubject] = useState("");

  const [message, setmessage] = useState("");

  const [errsubject, seterrsubject] = useState(false);
  const [errmessage, seterrmessage] = useState(false);

  const [faqs, setfaqs] = useState([]);

  const [avatar, setavatar] = useState<any>("");
  const [imgcover, setimgcover] = useState<any>("");

  let proId: any = null;

  proId = useParams();

  proId = proId.id;

  const [imgSrc, setImgSrc] = useState<any>("");
  const [ImgSrcavatar, setImgSrcavatar] = useState<any>("");
  // const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);

  const [crop, setCrop] = useState<any>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(4 / 1);

  const [reviewData, setreviewData] = useState<any>([]);

  const [reviewRank, setreviewRank] = useState<any>(0);

  const [compProvider, setcompProvider] = useState(false);

  const [compSeller, setcompSeller] = useState(false);

  const [compSeeker, setcompSeeker] = useState(false);

  const [compStripe, setcompStripe] = useState(false);

  const [showModalQuickView, setShowModalQuickView] = useState(false);

  const [openSuccess, setopenSuccess] = React.useState(false);

  const [serviceQuery, setserviceQuery] = useState<any>(false);

  React.useEffect(() => {
    let pQuery = query.get("pricing");

    let sQuery = query.get("payment");

    let tabQuery = query.get("selectPage");

    // if (pQuery === "true") {
    //   // setlOpen(true);
    //   // setTimeout(() => {
    //   // }, 2000);
    //   scrollToElement2();
    //   setlOpen(false);
    //   history.push("/home");
    //   // scrollToElement2();
    // }

    if (sQuery === "true") {
      // setlOpen(true);
      // setTimeout(() => {
      // }, 2000);
      // setSteps(6);

      // setopenSuccess(true);

      // addToast("Your order has been placed successfully !", "success", 8000);
      history.push("/profile");
      // scrollToElement2();
    }

    if (tabQuery === "profiles") {
      setselectedTab(31);
    } else if (tabQuery === "ads") {
      setselectedTab(7);
    } else if (tabQuery === "change password") {
      setselectedTab(10);
      setserviceQuery(false);
    } else if (tabQuery === "account") {
      setselectedTab(9);
      setserviceQuery(true);
    } else if (tabQuery === "add product") {
      setselectedTab(12);
      setserviceQuery(false);
    } else if (tabQuery === "product list") {
      setselectedTab(14);
      setserviceQuery(false);
    } else if (tabQuery === "services") {
      setselectedTab(20);
      setserviceQuery(true);
    } else if (tabQuery === "packages") {
      setselectedTab(32);
      setserviceQuery(true);
    } else if (tabQuery === "quotations") {
      setselectedTab(28);
      setserviceQuery(false);
    } else if (tabQuery === "add profile") {
      setselectedTab(34);
    } else if (tabQuery === "replies") {
      setselectedTab(35);
    }
  }, [query]);

  const getVerification = () => {
    let token = query.get("token");
    if (token !== null) {
      // setlopen(true);
      setlopen(true);
      getSignUpVerification(token)
        .then((res) => {
          UpdateUserwithemail(JSON.parse(res.data[0].data))
            .then((res) => {
              setlopen(false);
              addToast("Successfully updated", "success");
              getCurrentUser();
              history.push("/account");

              // setTimeout(() => {
              //   history.push("/account");
              //   window.location.reload();
              // }, 2000);
            })
            .catch((error) => {
              // setlopen(false);
              console.log("Error", error.response);
              setlopen(false);
              addToast(error.response.data.message, {
                appearance: "warning",
                autoDismiss: true
              });
            });
        })
        .catch((error) => {
          // setlopen(false);
          console.log("Error", error.response);
          setlopen(false);
          addToast(error.response.data.message, {
            appearance: "warning",
            autoDismiss: true
          });
        });
    }
  };

  const getCurrentUser = () => {
    console.log("BOBO", proId);

    CurrentUser(proId !== undefined ? proId : null)
      .then((res) => {
        console.log("hello>>>>", res);
        if (res.statusCode === 200) {
          const cData = res.data[0];

          if (!proId) {
            setUser(cData);
          }

          console.log("helloAAAAAA>>>>", !query.get("selectPage"));

          if (
            !query.get("selectPage") ||
            cData?.userType === "service provider"
          ) {
            if (!selectedTab2) {
              if (
                proId === undefined &&
                (cData?.userType === "service seeker" ||
                  cData?.userType === "wholesaler" ||
                  cData?.userType === "retail supplier" ||
                  cData?.userType === "service provider")
              ) {
                if (
                  cData?.userType === "service provider" &&
                  !query.get("selectPage")
                ) {
                  setselectedTab(9);
                  setserviceQuery(true);
                } else if (cData?.userType != "service provider") {
                  setselectedTab(9);
                }
              } else if (proId === undefined) {
                setselectedTab(31);
              } else {
                if (cData?.userType === "service provider") {
                  // setselectedTab(21);
                  setselectedTab(21);
                  setserviceQuery(true);
                } else {
                  setselectedTab(31);
                }
              }
            } else {
              setselectedTab(selectedTab2);
            }
          }

          if (cData?.userType === "service provider") {
            if (
              !(
                (
                  cData?.firstName &&
                  cData?.lastName &&
                  cData?.country &&
                  cData?.email &&
                  cData?.about &&
                  cData?.homeAddress &&
                  cData?.homeCity &&
                  cData?.homeDistrict &&
                  cData?.homepostCode &&
                  // cData?.businessRegistrationNumber &&
                  cData?.businessName &&
                  // cData?.establishedYear &&
                  cData?.contactNumber
                )
                // cData?.StripeKey
              )
            ) {
              setcompProvider(true);
            } else {
              setcompProvider(false);
            }

            if (!cData?.StripeKey) {
              setcompStripe(true);
            } else {
              setcompStripe(false);
            }
          } else if (
            cData?.userType === "wholesaler" ||
            cData?.userType === "retail supplier"
          ) {
            if (
              !(
                cData?.firstName &&
                cData?.lastName &&
                cData?.country &&
                cData?.email &&
                cData?.about &&
                cData?.homeAddress &&
                cData?.homeCity &&
                cData?.homeDistrict &&
                cData?.homepostCode &&
                cData?.businessRegistrationNumber &&
                cData?.businessName &&
                cData?.establishedYear &&
                cData?.contactNumber
              )
            ) {
              setcompSeller(true);
            } else {
              setcompSeller(false);
            }

            if (!cData?.StripeKey) {
              setcompStripe(true);
            } else {
              setcompStripe(false);
            }
          } else if (cData?.userType === "service seeker") {
            if (
              !(
                cData?.firstName &&
                cData?.lastName &&
                // cData?.country &&
                cData?.email &&
                // cData?.about &&
                // cData?.homeAddress &&
                // cData?.homeCity &&
                // cData?.homeDistrict &&
                // cData?.homepostCode

                // cData?.supplierCategory
                // cData?.businessName &&
                // cData?.establishedYear
                cData?.contactNumber
              )
            ) {
              setcompSeeker(true);
            } else {
              setcompSeeker(false);
            }
          }

          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.mobileNumber
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setcurrUser(cData);
          // setName(cData?.firstName + " " + cData?.lastName);
          setfirstName(cData?.firstName);
          setlasttName(cData?.lastName);
          setemail(cData?.email);
          setpremail(cData?.email);
          setmobile(cData?.mobileNumber);
          sethomeDistrict(
            cData?.homeDistrict !== null ? cData?.homeDistrict : ""
          );
          sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
          sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
          sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
          sethomepostCode(
            cData?.homepostCode !== null ? cData?.homepostCode : ""
          );
          setuserId(cData?.id);
          setimgcover(cData?.cover !== null ? cData?.cover : "");
          setavatar(cData?.avatar !== null ? cData?.avatar : "");

          getDistricts()
            .then((res) => {
              var mobile22 = res.data?.filter(function (el: any) {
                return el.name_en === cData?.homeDistrict;
              });

              sethomeDistrictId(mobile22.length > 0 ? mobile22[0]?.id : 0);

              // setallODistricts(res.data);
            })
            .catch((error) => {
              console.log("Error", error.response);
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });

          // localStorage.setItem(
          //   `${window.location.hostname}.profile`,
          //   JSON.stringify(obj)
          // );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);

        history.push("/login");
        // addToast("Please Login", "warning");
      });
  };

  React.useEffect(() => {
    if (showModalQuickView === false) {
      getCurrentUser();
      getVerification();
      listDistricts();
      getAllFaqs();
    }
  }, [proId, selectedTab2, showModalQuickView, query]);

  useEffect(() => {
    if (!showModalQuickView) {
      setImgSrc("");
      setImgSrcavatar("");
    }
  }, [showModalQuickView]);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId]);

  const listDistricts = () => {
    getDistricts()
      .then((res) => {
        console.log("districts>>>", res);
        setallHDistricts(res.data);
        // setallODistricts(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };
  const listHomecities = () => {
    getCities(homeDistrictId)
      .then((res) => {
        console.log("cities>>>", res);
        setallHCities(res.data);
      })
      .catch((error) => {
        console.log("Error", error.response);
        setallHCities([]);
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  const handleChangeHdist = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(1);
    }

    console.log("state>>", value);
  };
  const handleChangeHcity = (e?: any, value?: any) => {
    if (value !== null) {
      sethomeCity(value.name);
      sethomepostCode(value.postcode);
      seterrhomeCity(false);
      seterrhomepostCode(false);
    } else {
      sethomeCity("");
      sethomepostCode("");
    }

    console.log("city>>", value);
  };

  const getAllFaqs = () => {
    getFAQs(proId !== undefined ? proId : null)
      .then((res) => {
        console.log(">>>>>faqs", res.data);
        setfaqs(res.data);

        // setTimeout(() => {
        //   history.push("/account");
        //   window.location.reload();
        // }, 2000);
      })
      .catch((error) => {
        // setlopen(false);
        console.log("Error", error.response);
        setfaqs([]);
        setlopen(false);
        // addToast(error.response.data.message, { appearance: "warning", autoDismiss: true });
      });
  };

  const updateImages = async () => {
    let obj = {
      firstName: currUser.firstName === null ? "" : currUser.firstName,
      lastName: currUser.lastName === null ? "" : currUser.lastName,
      supplierCategory:
        currUser.supplierCategory === null ? "" : currUser.supplierCategory,
      businessName: currUser.businessName === null ? "" : currUser.businessName,
      businessRegistrationNumber:
        currUser.businessRegistrationNumber === null
          ? ""
          : currUser.businessRegistrationNumber,
      establishedYear:
        currUser.establishedYear === null ? "" : currUser.establishedYear,
      website: currUser.website === null ? "" : currUser.website,
      country: currUser.country === null ? "" : currUser.country,
      contactNumber:
        currUser.contactNumber === null ? "" : currUser.contactNumber,
      contactNumber2:
        currUser.contactNumber2 === null ? "" : currUser.contactNumber2,
      email: currUser.email === null ? "" : currUser.email,
      contactName: currUser.contactName === null ? "" : currUser.contactName,
      about: currUser.about === null ? "" : currUser.about,
      homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
      homeAddress: currUser.homeAddress === null ? "" : currUser.homeAddress,
      homeCity: currUser.homeCity === null ? "" : currUser.homeCity,
      homeDistrict: currUser.homeDistrict === null ? "" : currUser.homeDistrict,
      homepostCode: currUser.homepostCode === null ? "" : currUser.homepostCode,
      note: currUser.note === null ? "" : currUser.note,
      cover: imgcover,
      avatar: avatar,
      logo: currUser.logo === null ? "" : currUser.logo,
      paymentGateway:
        currUser.paymentGateway === null ? "" : currUser.paymentGateway,
      userType: currUser.userType === null ? "" : currUser.userType,
      clientId: currUser.clientId === null ? "" : currUser.clientId,
      secretId: currUser.secretId === null ? "" : currUser.secretId,
      deliveryFee: currUser.deliveryFee === null ? "" : currUser.deliveryFee,
      salesCount: currUser.salesCount === null ? "" : currUser.salesCount,
      password: "",
      serviceProviderImages: !currUser.serviceProviderImages
        ? ""
        : JSON.parse(currUser.serviceProviderImages),
      serviceProvierVideos: !currUser.serviceProvierVideos
        ? ""
        : currUser.serviceProvierVideos,
      cleaningTypeId:
        currUser.cleaningTypeId === null ? "" : currUser.cleaningTypeId,
      shippitKey: currUser.shippitKey === null ? "" : currUser.shippitKey,
      websiteRequest:
        currUser.websiteRequest === null ? 0 : currUser.websiteRequest,
      refundPolicyDescription: currUser?.refundPolicyDescription
        ? currUser?.refundPolicyDescription
        : "",
      refundPolicyDocument: currUser?.refundPolicyDocument
        ? currUser?.refundPolicyDocument
        : "",
      deliveryPolicyDescription: currUser?.deliveryPolicyDescription
        ? currUser?.deliveryPolicyDescription
        : "",
      deliveryPolicyDocument: currUser?.deliveryPolicyDocument
        ? currUser?.deliveryPolicyDocument
        : "",

      publicLiabilityInsurance:
        currUser.publicLiabilityInsurance === null
          ? 0
          : currUser.publicLiabilityInsurance,
      IndemnityInsurance:
        currUser.IndemnityInsurance === null ? 0 : currUser.IndemnityInsurance,
      workCoverInsurance:
        currUser.workCoverInsurance === null ? 0 : currUser.workCoverInsurance,
      workersCompensationInsurance:
        currUser.workersCompensationInsurance === null
          ? 0
          : currUser.workersCompensationInsurance,
      policeClearanceCertificate:
        currUser.policeClearanceCertificate === null
          ? 0
          : currUser.policeClearanceCertificate,
      workingWithChildren:
        currUser.workingWithChildren === null
          ? 0
          : currUser.workingWithChildren,

      policeClearanceCertificateDoc: currUser?.policeClearanceCertificateDoc
        ? currUser?.policeClearanceCertificateDoc
        : "",
      workingWithChildrenDoc: currUser?.workingWithChildrenDoc
        ? currUser?.workingWithChildrenDoc
        : "",
      issuedDate: currUser?.issuedDate ? currUser?.issuedDate : "",
      expiryDate: currUser?.expiryDate ? currUser?.expiryDate : "",
      mainId: currUser?.mainId ? currUser?.mainId : "",
      subId: currUser?.subId ? currUser?.subId : "",
      title: currUser?.title ? currUser?.title : "",
      accType: currUser?.accType ? currUser?.accType : "",
      profUpdate: false
    };
    console.log("obj>>>>", obj);

    if (true) {
      setlopen(true);

      EditUserQuery(obj)
        .then((res) => {
          setlopen(false);
          addToast("Successfully updated", "success");
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Successfully updated");
          setImgSrc("");
          setImgSrcavatar("");
          setCrop({ x: 0, y: 0 });
          setZoom(1);
          setCroppedArea(null);

          getCurrentUser();
          // seteDisable(true);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlopen(false);
          console.log("Error", error.response);
          addToast(error.response.data.message, "warning");
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else {
      // scrollElement();
    }
  };

  useEffect(() => {
    if (
      (imgcover != "" && imgcover.includes("/uploads/") === false) ||
      (avatar != "" && avatar.includes("/uploads/") === false)
    ) {
      updateImages();
      setShowModalQuickView(false);
    }
  }, [imgcover, avatar]);

  const resizeFile = (file?: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        320,
        320,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  function onImageLoad(e?: any) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onSelectFile(e?: any) {
    setImgSrcavatar("");
    if (e.target.files && e.target.files.length > 0) {
      // setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        setImgSrc(reader.result);
        setShowModalQuickView(true);
      };
    }
  }

  function onSelectFile2(e?: any) {
    setImgSrc("");
    if (e.target.files && e.target.files.length > 0) {
      // setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        setImgSrcavatar(reader.result);
        setShowModalQuickView(true);
      };
    }
  }

  const onCropComplete = (
    croppedAreaPercentage?: any,
    croppedAreaPixels?: any
  ) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onCropDone = (imgCroppedArea?: any, type?: any) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context: any = canvasEle.getContext("2d");

    let imageObj1 = new Image();

    if (type === "cover") {
      imageObj1.src = imgSrc;
    } else if (type === "avatar") {
      imageObj1.src = ImgSrcavatar;
    }

    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/png");

      console.log(">>>>>", dataURL);

      if (type === "cover") {
        setimgcover(dataURL);
      } else if (type === "avatar") {
        setavatar(dataURL);
      }

      // setImgAfterCrop(dataURL);
      // setCurrentPage("img-cropped");
    };
  };

  return (
    <>
      <ModalQuickView
        // getAllFaqs={getAllFaqs}
        imgSrc={imgSrc}
        setimgcover={setimgcover}
        setavatar={setavatar}
        setImgSrc={setImgSrc}
        setImgSrcavatar={setImgSrcavatar}
        ImgSrcavatar={ImgSrcavatar}
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />

      <div
        className={`nc-PageContact overflow-hidden ${className} ${
          selectedTab != 1 ? "pb-24 lg:pb-40" : ""
        }`}
        data-nc-id="PageContact"
      >
        <LoadingSpinner open={lopen} />
        <Helmet>
          <title>Profile || Mangalya.lk</title>
        </Helmet>
        {/* <SectionPromo2 bg={cover} /> */}
        <div>
          {currUser?.userType === "service provider" && (
            <div className="flex-shrink-0 flex justify-center w-full">
              <div className="relative w-full overflow-hidden flex">
                {
                  // imgcover ?
                  // !imgSrc ?
                  true ? (
                    <img
                      // src={cover}
                      src={
                        imgcover.includes("/uploads/") === true
                          ? IMAGE_SERVER_URL_DEV + imgcover
                          : imgcover
                      }
                      alt=""
                      className="h-[220px] md:h-[280px] xl:h-[290px] 2xl:h-[300px] w-full object-cover z-0 bg-center"
                    />
                  ) : (
                    // <div className="h-[220px] md:h-[280px] xl:h-[290px] 2xl:h-[300px] w-full object-cover z-0 bg-center">
                    // </div>
                    <>
                      <div className="h-[220px] md:h-[280px] xl:h-[290px] 2xl:h-[300px] w-full z-0 bg-center">
                        <Cropper
                          image={imgSrc}
                          aspect={aspectRatio}
                          crop={crop}
                          zoom={zoom}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                          style={{
                            containerStyle: {
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#fff"
                            }
                          }}
                        />
                      </div>
                    </>
                  )

                  // <img
                  //   // src={cover}
                  //   src={imgSrc}

                  //   alt=""
                  //   className="h-[220px] md:h-[280px] xl:h-[290px] 2xl:h-[300px] w-full object-cover z-0 bg-center"
                  // />
                }

                {!imgSrc && (
                  <div
                    className={`absolute z-[1] inset-0   ${
                      imgcover && proId === undefined
                        ? "hover:bg-black hover:bg-opacity-30"
                        : imgcover
                        ? ""
                        : "bg-gray-400 bg-opacity-70"
                    }  flex flex-col items-center justify-center text-transparent ${
                      imgcover && proId === undefined
                        ? "hover:text-neutral-50"
                        : imgcover
                        ? "text-transparent"
                        : "text-neutral-50"
                    } cursor-pointer`}
                  >
                    <svg
                      className={`${imgcover ? "" : "text-neutral-50"}`}
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                        stroke="currentColor"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span
                      className={`mt-1 text-[10.8px] ${
                        imgcover ? "" : "text-neutral-50"
                      }`}
                    >
                      {imgcover
                        ? "Change Image"
                        : proId != undefined
                        ? "Cover Image"
                        : "Upload Image"}
                    </span>
                    <span
                      className={`text-[10px] ${
                        imgcover ? "" : "text-neutral-50"
                      }`}
                    >
                      {imgcover
                        ? "(1920 x 500) px"
                        : proId != undefined
                        ? ""
                        : "(1920 x 500) px"}
                    </span>
                    {proId === undefined && (
                      <input
                        type="file"
                        className="absolute inset-0 opacity-0 cursor-pointer"
                        onChange={onSelectFile}
                        // onChange={(e: any) => {

                        //   let file = e.target.files[0];

                        //   var extension = file.type;

                        //   extension = extension.split("/")[1];

                        //   console.log(">>>", extension.split("/")[1])

                        //   let reader = new FileReader();
                        //   reader.readAsDataURL(file);
                        //   let fileInp = e.target.files[0];
                        //   let baseURL;
                        //   // on reader load somthing...
                        //   reader.onload = () => {
                        //     // setImgSrc(reader.result?.toString())
                        //     // return;
                        //     // Make a fileInfo Object
                        //     baseURL = reader.result;
                        //     let obj: any =
                        //     {
                        //       image: baseURL,
                        //       thumbnail: false,
                        //       name: file.name,
                        //       ext: upperCase(extension)
                        //     }
                        //       ;

                        //     setimgcover(baseURL);

                        //   };
                        // }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* <div className="flex justify-center relative z-[1] -mt-12 ">
        <div className="w-24 h-24 object-fill bg-contain rounded-full border-2 border-white"
          style={{
            backgroundImage: `url(${Company1})`
          }}
        >

        </div>

      </div> */}

        {/* {
          (imgSrc || ImgSrcavatar) &&

          <div className="flex w-full mt-2">
            <div className="w-full flex justify-end space-x-2 pr-2">
              <button
                className={
                  `rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b] text-slate-50 font-medium px-2 py-1`}
                onClick={() => {
                  if (imgSrc) {
                    onCropDone(croppedArea, "cover");
                  } else if (!imgSrc && ImgSrcavatar) {
                    console.log(">>>hello")
                    onCropDone(croppedArea, "avatar");
                  }

                }}

              >

                <span className="block  text-sm font-medium "

                >
                  Save
                </span>

              </button>
              <button
                className={
                  `rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-slate-100 text-slate-900 font-medium px-2 py-1`}
                onClick={() => {
                  setImgSrc("");
                  setImgSrcavatar("");
                  setCrop({ x: 0, y: 0 });
                  setZoom(1);
                  setCroppedArea(null);
                }}

              >

                <span className="block  text-sm font-medium "

                >
                  Cancel
                </span>

              </button>
            </div>

          </div>
        } */}
        {currUser?.userType === "service provider" && (
          <div className="flex-shrink-0 flex justify-center w-full -mt-12">
            <div className="relative rounded-full overflow-hidden flex">
              {
                // !ImgSrcavatar ?

                true ? (
                  <img
                    // src={cover}
                    src={
                      avatar.includes("/uploads/") === true
                        ? IMAGE_SERVER_URL_DEV + avatar
                        : avatar
                    }
                    alt=""
                    className="w-24 h-24 rounded-full object-cover z-[2] border-2 border-white"
                  />
                ) : (
                  <>
                    <div className="w-24 h-24 rounded-full object-cover z-[2] border-2 border-white">
                      <Cropper
                        image={ImgSrcavatar}
                        aspect={1 / 1}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                        style={{
                          containerStyle: {
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#fff"
                          }
                        }}
                      />
                    </div>
                  </>
                )
                // <div className="w-24 h-24 rounded-full object-cover z-[2] border-2 border-white">
                // </div>

                // <img
                //   // src={cover}
                //   src={demAvatar}
                //   alt=""
                //   className="w-24 h-24 rounded-full object-cover z-[2] border-2 border-white"
                // />
              }
              {/* <img
              // src={Company1}
              src={avatar.includes('/uploads/') === true ? IMAGE_SERVER_URL_DEV + avatar : avatar}
              alt=""
              className="w-24 h-24 rounded-full object-cover z-[2] border-2 border-white"
            /> */}
              {!ImgSrcavatar && (
                <div
                  className={`absolute z-[3] inset-0   ${
                    avatar && proId === undefined
                      ? "hover:bg-black hover:bg-opacity-30"
                      : avatar
                      ? ""
                      : "bg-gray-400"
                  }  flex flex-col items-center justify-center text-transparent ${
                    avatar && proId === undefined
                      ? "hover:text-neutral-50"
                      : avatar
                      ? "text-transparent"
                      : "text-neutral-50"
                  } cursor-pointer`}
                >
                  <svg
                    className={`${avatar ? "" : "text-neutral-50"}`}
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span
                    className={`mt-1 text-[10.8px] ${
                      avatar ? "" : "text-neutral-50"
                    }`}
                  >
                    {avatar
                      ? "Change Image"
                      : proId != undefined
                      ? "Profile Image"
                      : "Upload Image"}
                  </span>
                  <span
                    className={`text-[8px] ${avatar ? "" : "text-neutral-50"}`}
                  >
                    {avatar
                      ? "(500 x 500)px"
                      : proId != undefined
                      ? ""
                      : "(500 x 500)px"}
                  </span>

                  {proId === undefined && (
                    <input
                      type="file"
                      className="absolute inset-0 opacity-0 cursor-pointer"
                      onChange={onSelectFile2}
                      // onChange={async (e: any) => {

                      //   let file = e.target.files[0];

                      //   var extension = file.type;

                      //   extension = extension.split("/")[1];

                      //   console.log(">>>", extension.split("/")[1])

                      //   let reader = new FileReader();
                      //   reader.readAsDataURL(file);
                      //   let fileInp = e.target.files[0];
                      //   let baseURL;
                      //   // on reader load somthing...
                      //   reader.onload = () => {
                      //     // Make a fileInfo Object
                      //     baseURL = reader.result;
                      //     let obj: any =
                      //     {
                      //       image: baseURL,
                      //       thumbnail: false,
                      //       name: file.name,
                      //       ext: upperCase(extension)
                      //     }
                      //       ;

                      //     setavatar(baseURL);

                      //   };
                      // }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {/* <div className="flex justify-center w-full px-2">
        <div className="flex justify-center mt-4 lg:space-x-0 bg-gray-200 w-3xl px-4 py-2 xl:px-8 grid grid-cols-4 gap-6 xl:flex">
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 1 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(1);
            }}
          >
            Shop
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 2 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(2);
            }}>
            About Us
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 3 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(3);
            }}>
            My account
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 4 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(4);
            }}>
            FAQs
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 5 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(5);
            }}>
            Reviews
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 9 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(9);
            }}>
            Account
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 6 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(6);
            }}>
            Payment
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 7 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(7);
            }}>
            Jobs
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 8 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(8);
            }}>
            Blogs
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 10 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(10);
            }}>
            Change Password
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 11 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(11);
            }}>
            Billing
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 12 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(12);
            }}>
            Add product
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 13 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(13);
            }}>
            Order Summary
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 14 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(14);
            }}>
            Stocks
          </span>
          <span className={`ont-semibold cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 15 ? "text-[#2d867b]" : ""}`}
            onClick={() => {
              setselectedTab(15);
            }}>
            Listing
          </span>


        </div>

      </div> */}
        {(proId != undefined || proId === undefined) &&
          currUser?.userType === "service provider" &&
          serviceQuery === true && (
            <>
              {proId != undefined && (
                <hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>
              )}
              <div
                className={`flex justify-center w-full px-2 ${
                  proId === undefined && "mt-6"
                }`}
              >
                <div className="flex text-sm xl:text-sm text-neutral-900 dark:text-neutral-100 lg:space-x-0  px-4  xl:px-8 gap-x-4 lg:gap-x-2 xl:gap-x-3 2xl:gap-x-3 overflow-x-auto hiddenScrollbar">
                  {proId === undefined &&
                  (currUser?.userType === "service seeker" ||
                    currUser?.userType === "wholesaler" ||
                    currUser?.userType === "retail supplier" ||
                    currUser?.userType === "service provider") ? (
                    <>
                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 1 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                  onClick={() => {
                    setselectedTab(1);
                  }}
                >
                  Shop
                </span> */}
                      <span
                        // className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 9 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        className={`py-3 w-[170px] font-bold text-center rounded-lg cursor-pointer whitespace-nowrap ${
                          selectedTab === 9
                            ? "text-white bg-[#2D867B] "
                            : "font-medium bg-[#F4F4F4] text-[#003D35]"
                        }`}
                        onClick={() => {
                          setselectedTab(9);
                        }}
                      >
                        Account Info
                      </span>

                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 11 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                  onClick={() => {
                    setselectedTab(11);
                  }}>
                  Billing
                </span> */}

                      {/* {
                    (currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") &&

                    <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 6 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(6);
                      }}>
                      Payment
                    </span>

                  }

                  {
                    (currUser?.userType === "retail supplier") &&

                    <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 26 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(26);
                      }}>
                      Delivery
                    </span>

                  } */}

                      {
                        // (currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") &&
                        //   <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap ${(
                        //     // compSeller || compStripe
                        //     false
                        //   ) ? "text-gray-400" : "hover:text-[#2d867b]"} ${selectedTab === 12 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        //     onClick={() => {
                        //       // if (!(compSeller || compStripe))
                        //       setselectedTab(12);
                        //     }}>
                        //     Add product
                        //     {
                        //   (compSeller || compStripe) &&
                        //   <Tooltip title={"Profile or payment gateway process is incomplete"} placement="top" TransitionComponent={Zoom} arrow >
                        //     <i className="las la-question-circle cursor-pointer ml-1 text-lg"></i>
                        //   </Tooltip>
                        // }
                        //   </span>
                      }

                      {/* {
                    currUser?.userType != "service seeker" &&

                    <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 19 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(19);
                      }}>
                      FAQs
                    </span>
                  } */}

                      {
                        // (currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") &&
                        // <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 14 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        //   onClick={() => {
                        //     setselectedTab(14);
                        //   }}>
                        //   Product List
                        // </span>
                      }

                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 8 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                  onClick={() => {
                    setselectedTab(8);
                  }}>
                  Blogs
                </span> */}

                      {currUser?.userType === "service provider" && (
                        <>
                          <span
                            // className={`py-5 md:py-8 cursor-pointer ${compProvider ? "text-gray-400" : "hover:text-[#2d867b]"} whitespace-nowrap  ${selectedTab === 20 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                            className={`py-3 w-[170px] font-bold text-center rounded-lg cursor-pointer whitespace-nowrap ${
                              selectedTab === 20
                                ? "text-white bg-[#2D867B] "
                                : "font-medium bg-[#F4F4F4] text-[#003D35]"
                            }`}
                            onClick={() => {
                              if (!compProvider) setselectedTab(20);
                            }}
                          >
                            Services
                            {compProvider && (
                              <Tooltip
                                title={"Complete account info first"}
                                placement="top"
                                TransitionComponent={Zoom}
                                arrow
                              >
                                <i className="las la-question-circle cursor-pointer ml-1 text-lg"></i>
                              </Tooltip>
                            )}
                          </span>

                          <span
                            // className={`py-5 md:py-8 cursor-pointer ${compProvider ? "text-gray-400" : "hover:text-[#2d867b]"} whitespace-nowrap  ${selectedTab === 32 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                            className={`py-3 w-[170px] font-bold text-center rounded-lg cursor-pointer whitespace-nowrap ${
                              selectedTab === 32
                                ? "text-white bg-[#2D867B] "
                                : "font-medium bg-[#F4F4F4] text-[#003D35]"
                            }`}
                            onClick={() => {
                              if (!compProvider) setselectedTab(32);
                            }}
                          >
                            Packages
                            {compProvider && (
                              <Tooltip
                                title={"Complete account info first"}
                                placement="top"
                                TransitionComponent={Zoom}
                                arrow
                              >
                                <i className="las la-question-circle cursor-pointer ml-1 text-lg"></i>
                              </Tooltip>
                            )}
                          </span>

                          {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 28 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                            onClick={() => {
                              setselectedTab(28);
                            }}>
                            Quotations Enquiries
                          </span> */}
                        </>
                      )}

                      {/* {
                    currUser?.userType === "service provider" &&

                    <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 24 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(24);
                      }}>
                      Add Gallery
                    </span>

                  } */}

                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 10 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        onClick={() => {
                          setselectedTab(10);
                        }}>
                        Change Password
                      </span> */}

                      {/* {
                    (currUser?.userType != "service seeker") &&

                    <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap ${(compSeller || compProvider) ? "text-gray-400" : "hover:text-[#2d867b]"} ${selectedTab === 7 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        if (!(compSeller || compProvider))
                          setselectedTab(7);
                      }}>
                      Jobs

                      {
                        (compSeller || compProvider) &&

                        <Tooltip title={"Complete account info first"} placement="top" TransitionComponent={Zoom} arrow >
                          <i className="las la-question-circle cursor-pointer ml-1 text-lg"></i>

                        </Tooltip>
                      }
                    </span>
                  } */}

                      {
                        // currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier" &&
                        // currUser?.userType === "service provider" &&
                        // <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 13 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        //   onClick={() => {
                        //     setselectedTab(13);
                        //   }}>
                        //   {"My Order Summary"}
                        // </span>
                      }

                      {/* {
                    (currUser?.userType === "service provider") &&

                    <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 28 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(28);
                      }}>
                      Quotations Enquiries
                    </span>

                  } */}

                      {
                        // <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 13 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        //   onClick={() => {
                        //     setselectedTab(13);
                        //   }}>
                        //   {(currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") ? "Order Summary" : "My Order Summary"}
                        // </span>
                      }
                      {
                        // currUser?.userType != "wholesaler" &&
                        // < span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 27 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        //   onClick={() => {
                        //     setselectedTab(27);
                        //   }}>
                        //   {
                        //     currUser?.userType === "retail supplier" ? "Rental Summary" : "My Rental Summary"
                        //   }
                        // </span>
                      }
                      {
                        // (currUser?.userType === "wholesaler" || currUser?.userType === "retail supplier") &&
                        // < span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 29 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        //   onClick={() => {
                        //     setselectedTab(29);
                        //   }}>
                        //   My Order Summary
                        // </span>
                      }

                      {/* {
                    (currUser?.userType === "retail supplier") &&

                    < span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 30 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(30);
                      }}>

                      My Rental Summary

                    </span>

                  } */}

                      {
                        // (currUser?.userType === "service provider" && currUser?.userType != "service seeker") &&
                        // <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 23 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        //   onClick={() => {
                        //     setselectedTab(23);
                        //   }}>
                        //   My Quotations
                        // </span>
                      }

                      {/* {
                    (currUser?.userType != "wholesaler" && currUser?.userType != "retail supplier") &&

                    <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 22 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(22);
                      }}>
                      My Applied Ads
                    </span>

                  } */}

                      {/* {
                    currUser?.userType === "service seeker" &&

                    <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap ${compSeeker ? "text-gray-400" : "hover:text-[#2d867b]"} ${selectedTab === 18 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        if (!compSeeker)
                          setselectedTab(18);
                      }}>
                      Contracts
                      {
                        compSeeker &&

                        <Tooltip title={"Complete account info first"} placement="top" TransitionComponent={Zoom} arrow >
                          <i className="las la-question-circle cursor-pointer ml-1 text-lg"></i>

                        </Tooltip>
                      }
                    </span>


                  } */}

                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 17 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                  onClick={() => {
                    setselectedTab(17);
                  }}>
                  Events
                </span> */}

                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 16 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                    onClick={() => {
                      setselectedTab(16);
                    }}>
                    Saved List
                  </span> */}
                    </>
                  ) : proId === undefined ? (
                    <>
                      {currUser?.userType === "service provider" && (
                        <span
                          className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                            selectedTab === 9
                              ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                              : "font-medium"
                          }`}
                          onClick={() => {
                            setselectedTab(9);
                          }}
                        >
                          Account Info
                        </span>
                      )}

                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 11 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                    onClick={() => {
                      setselectedTab(11);
                    }}>
                    Billing
                  </span> */}
                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 6 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                    onClick={() => {
                      setselectedTab(6);
                    }}>
                    Payment
                  </span> */}

                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 13 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(13);
                      }}>
                      My Order Summary
                    </span>

                    <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 27 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(27);
                      }}>
                      Rental Summary
                    </span> */}

                      {currUser?.userType != "service provider" && (
                        <span
                          className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                            selectedTab === 31
                              ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                              : "font-medium"
                          }`}
                          onClick={() => {
                            setselectedTab(31);
                          }}
                        >
                          Profiles
                        </span>
                      )}

                      {
                        // (currUser?.userType != "service seeker") &&

                        <span
                          className={`py-5 md:py-8 cursor-pointer whitespace-nowrap ${
                            // compSeller || compProvider
                            false
                              ? "text-gray-400"
                              : "hover:text-[#2d867b]"
                          } ${
                            selectedTab === 7
                              ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                              : "font-medium"
                          }`}
                          onClick={() => {
                            // if (!(compSeller || compProvider))
                            setselectedTab(7);
                          }}
                        >
                          Ads
                          {/* {
                          (compSeller || compProvider) &&

                          <Tooltip title={"Complete account info first"} placement="top" TransitionComponent={Zoom} arrow >
                            <i className="las la-question-circle cursor-pointer ml-1 text-lg"></i>

                          </Tooltip>
                        } */}
                        </span>
                      }

                      <span
                        className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                          selectedTab === 10
                            ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                            : "font-medium"
                        }`}
                        onClick={() => {
                          setselectedTab(10);
                        }}
                      >
                        Change Password
                      </span>

                      {
                        // (currUser?.userType === "service provider" && currUser?.userType != "service seeker") &&

                        <span
                          className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                            selectedTab === 23
                              ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                              : "font-medium"
                          }`}
                          onClick={() => {
                            setselectedTab(23);
                          }}
                        >
                          My Quotations
                        </span>
                      }

                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 22 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                    onClick={() => {
                      setselectedTab(22);
                    }}>
                    My Jobs
                  </span> */}

                      {/* {
                      (currUser?.userType != "wholesaler" && currUser?.userType != "retail supplier") &&

                      <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 22 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        onClick={() => {
                          setselectedTab(22);
                        }}>
                        My Applied Ads
                      </span>

                    } */}

                      {/* {
                      // currUser?.userType === "buyer" &&

                      <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 16 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        onClick={() => {
                          setselectedTab(16);
                        }}>
                        Save List
                      </span>

                    } */}
                    </>
                  ) : (
                    <>
                      {currUser?.userType === "service provider" ? (
                        <>
                          {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 1 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                                onClick={() => {
                                  setselectedTab(1);
                                }}
                              >
                                Shop
                              </span> */}

                          <span
                            className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                              selectedTab === 21
                                ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                                : "font-medium"
                            }`}
                            onClick={() => {
                              setselectedTab(21);
                            }}
                          >
                            Services
                          </span>

                          <span
                            className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                              selectedTab === 33
                                ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                                : "font-medium"
                            }`}
                            onClick={() => {
                              setselectedTab(33);
                            }}
                          >
                            Packages
                          </span>
                        </>
                      ) : (
                        <span
                          className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                            selectedTab === 1
                              ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                              : "font-medium"
                          }`}
                          onClick={() => {
                            setselectedTab(1);
                          }}
                        >
                          Shop
                        </span>
                      )}

                      {currUser?.establishedYear && currUser?.about && (
                        <span
                          className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                            selectedTab === 2
                              ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                              : "font-medium"
                          }`}
                          onClick={() => {
                            setselectedTab(2);
                          }}
                        >
                          About Us
                        </span>
                      )}

                      {currUser?.homeAddress &&
                        currUser?.homeDistrict &&
                        currUser?.homeCity && (
                          <span
                            className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                              selectedTab === 3
                                ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                                : "font-medium"
                            }`}
                            onClick={() => {
                              setselectedTab(3);
                            }}
                          >
                            Contact Us
                          </span>
                        )}

                      {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 4 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                      onClick={() => {
                        setselectedTab(4);
                      }}>
                      FAQs
                    </span> */}
                      <span
                        className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${
                          selectedTab === 5
                            ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]"
                            : "font-medium"
                        }`}
                        onClick={() => {
                          setselectedTab(5);
                        }}
                      >
                        Reviews
                      </span>

                      {/* {
                      (currUser?.userType === "service provider" && ((currUser.serviceProviderImages ? JSON.parse(currUser.serviceProviderImages).length > 0 : true) || currUser?.serviceProvierVideos)) &&

                      <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 25 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
                        onClick={() => {
                          setselectedTab(25);
                        }}
                      >
                        Gallery
                      </span>


                    } */}
                    </>
                  )}
                  {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 1 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(1);
            }}
          >
            Shop
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 2 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(2);
            }}>
            About Us
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 3 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(3);
            }}>
            Contact Us
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 4 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(4);
            }}>
            FAQs
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 5 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(5);
            }}>
            Reviews
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 9 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(9);
            }}>
            Account Info
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 6 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(6);
            }}>
            Payment
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 7 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(7);
            }}>
            Jobs
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 8 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(8);
            }}>
            Blogs
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 10 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(10);
            }}>
            Change Password
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 11 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(11);
            }}>
            Billing
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 12 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(12);
            }}>
            Add product
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 13 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(13);
            }}>
            Order Summary
          </span>
          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 14 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(14);
            }}>
            Stocks
          </span> */}
                  {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 15 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(15);
            }}>
            Listing
          </span> */}

                  {/* <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 16 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(16);
            }}>
            Save List
          </span>

          <span className={`py-5 md:py-8 cursor-pointer whitespace-nowrap hover:text-[#2d867b] ${selectedTab === 17 ? "text-[#2d867b] font-bold border-b-2 border-[#2d867b]" : "font-medium"}`}
            onClick={() => {
              setselectedTab(17);
            }}>
            Events
          </span> */}
                </div>
              </div>
              {proId != undefined && (
                <hr className="border-slate-200 dark:border-slate-700"></hr>
              )}
            </>
          )}

        {selectedTab === 1 && <PageCollection2 proId={proId} />}

        {selectedTab === 2 && (
          <>
            <div className="flex justify-center w-full mt-12">
              <span className="block text-sm font-medium flex justify-center w-full text-center text-slate-500 ">
                Established &nbsp;<span>{currUser?.establishedYear}</span>
              </span>
            </div>

            {/* <div className="flex justify-center w-full mt-8">

            <span className="block text-sm font-bold flex justify-center text-center md:w-[700px] px-4">
              Our team of skilled creative event specialists blend world-class tech with innovative ideas to produce authentic
              brand and event experiences.
            </span>


          </div> */}

            <div className="flex justify-center w-full mt-5">
              <span className="block text-sm font-medium flex justify-center text-center md:w-[700px] px-4">
                {/* We provide the creativity, innovation and the tools to reimagine meetings and corporate event management,
              conferencing, group and incentive travel, production and events. From virtual and hybrid events, to in person
              immersive experiences and unconventional showstoppers. We do it all. And we do it exceptionally well.
              Need travel management support for your next event? No dramas. Our operational team is highly experienced and
              know how to make the travel experience and bookings flow. Smooth. */}
                {currUser?.about}
              </span>
            </div>

            {currUser?.userType === "service provider" && (
              <>
                <div className="w-full mt-5">
                  <div className="flex justify-center">
                    <div>
                      {currUser?.publicLiabilityInsurance === 1 && (
                        <span className="flex justify-start text-sm items-center text-slate-900 dark:text-neutral-400 text-left">
                          {currUser?.publicLiabilityInsurance === 1 ? (
                            <i className="fa fa-check-circle text-lg text-left mr-2 text-[#002930]"></i>
                          ) : (
                            <i className="fa fa-times text-lg text-left mr-2 text-red-600"></i>
                          )}
                          Public Liability Insurance
                        </span>
                      )}

                      {currUser?.IndemnityInsurance === 1 && (
                        <span className="flex justify-start text-sm items-center text-slate-900 dark:text-neutral-400 text-left">
                          {currUser?.IndemnityInsurance === 1 ? (
                            <i className="fa fa-check-circle text-lg text-left mr-2 text-[#002930]"></i>
                          ) : (
                            <i className="fa fa-times text-lg text-left mr-2 text-red-600"></i>
                          )}
                          Indemnity Insurance
                        </span>
                      )}

                      {currUser?.workCoverInsurance === 1 && (
                        <span className="flex justify-start text-sm items-center text-slate-900 dark:text-neutral-400 text-left">
                          {currUser?.workCoverInsurance === 1 ? (
                            <i className="fa fa-check-circle text-lg text-left mr-2 text-[#002930]"></i>
                          ) : (
                            <i className="fa fa-times text-lg text-left mr-2 text-red-600"></i>
                          )}
                          Work Cover
                        </span>
                      )}

                      {currUser?.workersCompensationInsurance === 1 && (
                        <span className="flex justify-start text-sm items-center text-slate-900 dark:text-neutral-400 text-left">
                          {currUser?.workersCompensationInsurance === 1 ? (
                            <i className="fa fa-check-circle text-lg text-left mr-2 text-[#002930]"></i>
                          ) : (
                            <i className="fa fa-times text-lg text-left mr-2 text-red-600"></i>
                          )}
                          Workers' Compensation Insurance
                        </span>
                      )}

                      {currUser?.policeClearanceCertificate === 1 && (
                        <span className="flex justify-start text-sm items-center text-slate-900 dark:text-neutral-400 text-left">
                          {currUser?.policeClearanceCertificate === 1 ? (
                            <i className="fa fa-check-circle text-lg text-left mr-2 text-[#002930]"></i>
                          ) : (
                            <i className="fa fa-times text-lg text-left mr-2 text-red-600"></i>
                          )}
                          National Police Check
                        </span>
                      )}

                      {currUser?.workingWithChildren === 1 && (
                        <span className="flex justify-start text-sm items-center text-slate-900 dark:text-neutral-400 text-left">
                          {currUser?.workingWithChildren === 1 ? (
                            <i className="fa fa-check-circle text-lg text-left mr-2 text-[#002930]"></i>
                          ) : (
                            <i className="fa fa-times text-lg text-left mr-2 text-red-600"></i>
                          )}
                          Children Check
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {selectedTab === 3 && (
          <>
            <div className="flex justify-center w-full mt-12">
              <div className="w-14 h-14 object-fill bg-contain rounded-full border-2 border-white bg-gray-200 flex justify-center items-center">
                <div className="w-6 h-6">
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADlUlEQVR4nO2YTUgVURTH/5op5WdRZErYpqSUonLTJ7V+lfa5qJ2rItr5fLQpSjOCIAyhhD72GURFm8zCImhnC8U+oLKEvqW0sojeixP/gdvjzcydefPuDOEPLgzv3PO/d94999w7B5ji/6cWQALAbQDDAL6yyXMvgFYAixFhGgD0AUhpNnnRVYgQ0wF0AUhygp8AnAewlatTzCbPjQAusE+KPmcAFIT9ErMA3OGkvgFoA1Cm4Sd92gF8V1anAiGuhBVKowwtrywH8JIa/QAKEQJdnMArAFVZ6Ijva2p1wjANjG8JpxUB6K1kmP3mszGskJI9ERTHqSkp2gi1SnZy2tib+cJyhkzwOebQvxzAGLUXwQAJDiZp1I52h/PDaRUvsU8cBujlYHIm2K2E2H8AaAEwny3O31IOK7ON9lswwBMOZnfNsM4VeYl0WmmTMHMK28cwwAQHK3GxV2awVdI2buNbQrto5BzrNJ5hYx+nXcIpnWravtj4Fiu3hJwzysFqXFJz3CFR2IXWQtrlgMw5/S4bNqZs9lae3FV8iZ8uvltovwsDdHIwSbF2tPlMvx3scxoG2MHBhlz6xRhCE5oHojBMbUnDOadMCZH6AHWXUVO0S2GImxz0aICax6h5AwbZzUGfAsgPQE80nlFzFwxSBOAtB5ZP2mxppNYbahvlEAe/H4DWA2olEAKy6T9zAquz0FmrnPYVCIlTnMTVLDSuU+MkQqSady/57F3jw3+dcrfKdDczivWJ+hBAns+9Iak3dEqZbWRC2z347aTPO81amBH2K+eKTvosYl/x2YcIUQBg0EOYWJfKQRPl0jwAz10K0RvTNm6Sd6U6B9169pG+m9LScNJhrBEfe/Av6zUq6un/6EVl40+zuYpYG1wK3RbS95HGeDInz5yjs2QlXWYDeE+/AxnsB2n7CGCOB90O+smcPFGolP6dwiQTe5WTeoHye43yPb/Ho2adUhT0VOhuouMA/HGF/vcYNvlKmeiaT80Bl3paRnocalM6zOX5IBqHARzh8wcA83xqtlDjsq6D1GAnWRmXK4hfrKrhL7ZsP2GrOadJztGVZpeSjZ+EIe1sAHp91GoOvLMLM1mkGOJztjTr/smel0+DJWxBUK4b9nG+sWz2qNKjk4is01XSb1RpcjsalrLDWBgFAA8U8mZgW1c7QWM3ok835ypXl3+QW+ULjUtb1NpIel1tQwQm5bfJ3KdA1PgDYpWe90NC9SoAAAAASUVORK5CYII=" />
                </div>
              </div>
            </div>
            <span className="block mt-4  text-sm font-medium flex justify-center w-full text-center">
              {currUser?.businessName} <br />
              {currUser?.homeAddress}, <br />
              {currUser?.homeDistrict} ,{currUser?.homeCity} ,{" "}
              {currUser?.homepostCode}
            </span>

            {currUser?.userType === "service provider" && (
              <>
                <div className="flex justify-center w-full mt-10">
                  <div className="w-14 h-14 object-fill bg-contain rounded-full border-2 border-white bg-gray-200 flex justify-center items-center">
                    <div className="w-6 h-6">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADS0lEQVR4nO2ZS0hUURjHf6ZZGJWUVqYWGbQSMlpkRYtE2oRBRAVFmGIYZLWM1tKqwih6CLWpXUXagx4LSVr0EiwhIm3Rg4qg1J5oz4lD/wuHYebOXBnvnIH5wd3M/c7c79x7vv/5vu9AlixpZR3QA4wCfcByMox84DgQibqGgVIyhOlAlxwfAVqAIuCyfrtOBjAHeCyH3wJLo+591L1GHGYa0CtHnwLzY9hs1f1PwDwcZBJwR04+AWb62Ha4vMQOyLlXQFkCW7PEBmVfj2MMybHVSdpvk70ZV4JDjMqxwgBjrmmMWWrO8FxOVQYYU6agN+M24wgdcsioUhB2aFw/jtAih84GHFekcb+BHBxgIfAX+AJMDTBuuyZi8jFn6JJTO5O0nwy8dFGGN1nrPS8J+/2yfwRMwCFylZoY55oT2M4CPsu2FgfZIOfeAQU+du2yMxmxk+QAD+TkQZ/Y+CqbJhxmCfAT+AOsimOzxyq0FuAwrVbgF8T5cldl8zDBMkx7qdsnR03JG4tCSxw6JRZOUqVS1zja4LORfpBNuyu7eywarNp9WUyL/50VL/jbcJijliTH66DUWF/vMI4yEbgtJ+/6BHad1C6iitNJioEXcvKGxCAWG63JtLkaM4uA93LyvI9K1VnL7JSrarZYm6Bx8rTPG6+xBKAzznLMlZiYpNNsvgNKXENjBfAticCutqS5J6pPVmvtU9HXRWA2IbEG+GFNJt6XqbA2zSGVxl5GEFHcmVqmSPe8jNp0NLeENZn11mTO+MSCyQCuRL114/A+JaA25RITz65DfbRQvoy3zC74qJn5YrsVNydVz/jRaMXioPpo485K66E3EySQpjWbLKVW7yyiJTmXEPIyT5rvpbj7WG91Qod9UqWU7jNeM+JNih9YYomEketQMoBuPXDUJ2seCzP0v98JMTc7Zq3tEykqvPZa+V6oNFjN8QGJwliptqR+LWmgytrBTRpyCJgS8D8qLCE5QhrJVw/gl1XXNCfZBCwGnmncLS3btGMOVu9bsdOv9my8XnO5ld70BuxJjzs5agJ6bzmixvk5HWlU6njcZM+vLbkNLYkMSp4Oh7p0ChCJc3VrYhmBCeZdwCWp24iy39aAKU2WLKSQf3jSBy3qRwNwAAAAAElFTkSuQmCC" />
                    </div>
                  </div>
                </div>
                <span className="block mt-4  text-sm font-medium flex justify-center w-full">
                  {currUser?.contactNumber}
                </span>
              </>
            )}

            <div className="flex justify-center w-full mt-10">
              <div className="w-14 h-14 object-fill bg-contain rounded-full border-2 border-white bg-gray-200 flex justify-center items-center">
                <div className="w-6 h-6">
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSUlEQVR4nO2YSWsUQRiGH424oV40irl4MKCiBwOCEZeE5Ae4oKLkIOTiRRz1FwRPEU8KKuPNk3rxkkMg4kWjmYj+AAMKuQiuCGFcxmWk4E0oYo9T3T3d093UA80w1V/VV+9Q31IDHo/H42kT9YI8FE5IXqkXVkg3+WNrkJBPwEHyw17gXaNg/w6cIvscBaqNgv2mPn8Dl8guF7VHe8//BHvJMroNLCM7dADXtbc/wEizrHUM+KqxcWAt7WclcN86/qdd028v8F7jz4FNtI/1wKSVkA6FrSMmtb3SuzfADtKnG5jRHl4D26MWRPNrPNH7z0Af6bEP+CDfFWBj3Mq+Argnmx/AEMlzHPgmnw+A1a1qUZYoSyzOGElQsjLnNWBpE/uF/Zuj8ziEk1+aWG5xejZrlbW28XHecV7kXvGIVVUngHXEZw0wpjXNkToZYm5mmt7NwAtt5iOwP+T8TAjZBcxax6knwhoLQqZbfFMzqdKFQeCL1d+ZzxmJiySk0mIhUw7OzyiVG/u7wDbgpb7P5SFGTON3WSncOB9VajesAu5YKf6qY1ZMVcgG4DDwTE5/Amcb2J4DarJ7BHS2S0i/FcBBz6xs/scB4K1lvyeNYA8KblNbLiheqnqmNOZ6LegCnlq1ZTjpYHcJ7qgsB25Yvm5pLFBIHhiyuglTPLfkVYhht+5FdV36BvIqZD77PdTGa2osYwW7a/VOqhZdsWpRrGBPMsBdOaEuIJdHazE7iyKEQgqZjhHg8/+wZOGhEiPAMyXE4/F4PKTNX1LaBGZV5fLZAAAAAElFTkSuQmCC" />
                </div>
              </div>
            </div>
            <span className="block mt-4  text-sm font-medium flex justify-center w-full">
              {currUser?.email}
            </span>
          </>
        )}

        {selectedTab === 4 && (
          <>
            <div className="flex justify-center w-full mt-12 ">
              <div className="md:w-9/12 lg:w-5/12 rounded-2xl space-y-2.5 px-4">
                {/* ============ */}
                {faqs.map((item: any, index: any) => {
                  return (
                    <Disclosure key={index} defaultOpen={index < 1}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex text-sm items-center justify-between w-full px-4 py-2 font-bold text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                            <span>{item.question}</span>
                            {!open ? (
                              <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                            ) : (
                              <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                            )}
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={panelClassName}
                            as="div"
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          ></Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  );
                })}

                {/* ============ */}
                {faqs.length === 0 && (
                  <>
                    <div className="w-full flex justify-center px-3">
                      <div className="container relative space-y-8 mt-8 lg:space-y-16 lg:my-12">
                        <div className="flex justify-center w-full">
                          <span className="block text-xl font-bold flex justify-center w-full text-center">
                            No FAQs available
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {selectedTab === 5 && (
          <>
            <div className="w-full flex justify-center px-3">
              <div className="container relative space-y-8 my-8 lg:space-y-16 lg:my-12">
                <div className="justify-center w-full">
                  <span className="block text-xl font-bold flex justify-center w-full text-center">
                    Customer Reviews
                  </span>

                  <div className="mt-4 ">
                    <span className="block text-xs font-medium flex justify-center w-full text-center">
                      Reviews count : {reviewData?.length}
                    </span>

                    <span className="flex block text-xs font-medium flex justify-center w-full text-center mt-4">
                      Average Review Rating :{" "}
                      <Rating
                        name="size-small"
                        value={reviewRank}
                        size="small"
                        className=""
                        readOnly
                      />
                    </span>
                  </div>
                </div>

                <SectionSliderReviews
                  proId={proId === undefined ? "" : proId}
                  setreviewData={setreviewData}
                  setreviewRank={setreviewRank}
                />
              </div>
            </div>
          </>
        )}

        {selectedTab === 6 && (
          <Gateway getUser={getCurrentUser} currUser={currUser} />
        )}

        {selectedTab === 7 && <MyJobs currUser={currUser} />}

        {selectedTab === 8 && <BlogList />}

        {selectedTab === 9 && (
          <Account
            getAllFaqs={getAllFaqs}
            getUser={getCurrentUser}
            logUser={currUser}
            setselectedTab={setselectedTab2}
          />
        )}

        {selectedTab === 10 && (
          <ChangePassword getUser={getCurrentUser} logUser={currUser} />
        )}

        {selectedTab === 11 && <Billing getUser={getCurrentUser} />}

        {selectedTab === 12 && <CreateStore />}

        {selectedTab === 13 && <OrderSummary currUser={currUser} />}

        {selectedTab === 14 && <Stocks />}

        {selectedTab === 15 && <Listing />}

        {selectedTab === 16 && <SaveList />}

        {selectedTab === 17 && <Events currUser={currUser} />}

        {selectedTab === 18 && <Contracts currUser={currUser} />}

        {selectedTab === 19 && <FAQForm setselectedTab={setselectedTab2} />}

        {selectedTab === 20 && (
          <CleaningTypes setselectedTab={setselectedTab2} />
        )}

        {selectedTab === 21 && <ShopClean userId={proId} currUser={currUser} />}

        {selectedTab === 22 && <AppliedJobs userId={proId} />}

        {selectedTab === 23 && <AppliedContracts userId={proId} />}

        {selectedTab === 24 && (
          <AddGallery
            getUser={getCurrentUser}
            logUser={currUser}
            setselectedTab={setselectedTab2}
          />
        )}

        {selectedTab === 25 && <Gallery userId={proId} currUser={currUser} />}

        {selectedTab === 26 && <Delivery currUser={currUser} />}

        {selectedTab === 27 && <HireSummary currUser={currUser} />}

        {selectedTab === 28 && <QuotClean currUser={currUser} />}

        {selectedTab === 29 && <MyOrderSummary currUser={currUser} />}

        {selectedTab === 30 && <MyHireSummary currUser={currUser} />}

        {selectedTab === 31 && <MyProfies currUser={currUser} />}

        {selectedTab === 32 && <Packages />}

        {selectedTab === 33 && (
          <ShopPackages userId={proId} currUser={currUser} />
        )}

        {selectedTab === 34 && (
          <Profiles
            onCloseModalQuickView={undefined}
            setShowModalQuickView3={() => console.log("")}
            selectedJob={null}
          />
        )}

        {selectedTab === 35 && <Replies currUser={currUser} />}

        {selectedTab === 99 && <LoadingSpinner open={true} />}

        {/* <div className="">
        <span className="my-20 flex items-center text-2xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
         
        </span>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">

              <div >

                <div className="flex flex-row items-center">
                  <div className="w-6 h-6 mr-2">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADlUlEQVR4nO2YTUgVURTH/5op5WdRZErYpqSUonLTJ7V+lfa5qJ2rItr5fLQpSjOCIAyhhD72GURFm8zCImhnC8U+oLKEvqW0sojeixP/gdvjzcydefPuDOEPLgzv3PO/d94999w7B5ji/6cWQALAbQDDAL6yyXMvgFYAixFhGgD0AUhpNnnRVYgQ0wF0AUhygp8AnAewlatTzCbPjQAusE+KPmcAFIT9ErMA3OGkvgFoA1Cm4Sd92gF8V1anAiGuhBVKowwtrywH8JIa/QAKEQJdnMArAFVZ6Ijva2p1wjANjG8JpxUB6K1kmP3mszGskJI9ERTHqSkp2gi1SnZy2tib+cJyhkzwOebQvxzAGLUXwQAJDiZp1I52h/PDaRUvsU8cBujlYHIm2K2E2H8AaAEwny3O31IOK7ON9lswwBMOZnfNsM4VeYl0WmmTMHMK28cwwAQHK3GxV2awVdI2buNbQrto5BzrNJ5hYx+nXcIpnWravtj4Fiu3hJwzysFqXFJz3CFR2IXWQtrlgMw5/S4bNqZs9lae3FV8iZ8uvltovwsDdHIwSbF2tPlMvx3scxoG2MHBhlz6xRhCE5oHojBMbUnDOadMCZH6AHWXUVO0S2GImxz0aICax6h5AwbZzUGfAsgPQE80nlFzFwxSBOAtB5ZP2mxppNYbahvlEAe/H4DWA2olEAKy6T9zAquz0FmrnPYVCIlTnMTVLDSuU+MkQqSady/57F3jw3+dcrfKdDczivWJ+hBAns+9Iak3dEqZbWRC2z347aTPO81amBspanK+eKTvosYl/x2YcIUQBg0EOYWJfKQRPl0jwAz10K0RvTNm6Sd6U6B9169pG+m9LScNJhrBEfe/Av6zUq6un/6EVl40+zuYpYG1wK3RbS95HGeDInz5yjs2QlXWYDeE+/AxnsB2n7CGCOB90O+smcPFGolP6dwiQTe5WTeoHye43yPb/Ho2adUhT0VOhuouMA/HGF/vcYNvlKmeiaT80Bl3paRnocalM6zOX5IBqHARzh8wcA83xqtlDjsq6D1GAnWRmXK4hfrKrhL7ZsP2GrOadJztGVZpeSjZ+EIe1sAHp91GoOvLMLM1mkGOJztjTr/smel0+DJWxBUK4b9nG+sWz2qNKjk4is01XSb1RpcjsalrLDWBgFAA8U8mZgW1c7QWM3ok835ypXl3+QW+ULjUtb1NpIel1tQwQm5bfJ3KdA1PgDYpWe90NC9SoAAAAASUVORK5CYII=" />


                  </div>
                  <div>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider ">
                      {"ADDRESS"}
                    </h3>


                  </div>

                </div>

                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">

                  {company?.companyName}<br />

                  {company?.address},{" "}

                  {company?.suburb} , {company?.postCode}
                </span>
              </div>

              <div >
                <div className="flex flex-row items-center">
                  <div className="w-6 h-6 mr-2">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSUlEQVR4nO2YSWsUQRiGH424oV40irl4MKCiBwOCEZeE5Ae4oKLkIOTiRRz1FwRPEU8KKuPNk3rxkkMg4kWjmYj+AAMKuQiuCGFcxmWk4E0oYo9T3T3d093UA80w1V/VV+9Q31IDHo/H42kT9YI8FE5IXqkXVkg3+WNrkJBPwEHyw17gXaNg/w6cIvscBaqNgv2mPn8Dl8guF7VHe8//BHvJMroNLCM7dADXtbc/wEizrHUM+KqxcWAt7WclcN86/qdd028v8F7jz4FNtI/1wKSVkA6FrSMmtb3SuzfADtKnG5jRHl4D26MWRPNrPNH7z0Af6bEP+CDfFWBj3Mq+Argnmx/AEMlzHPgmnw+A1a1qUZYoSyzOGElQsjLnNWBpE/uF/Zuj8ziEk1+aWG5xejZrlbW28XHecV7kXvGIVVUngHXEZw0wpjXNkToZYm5mmt7NwAtt5iOwP+T8TAjZBcxax6knwhoLQqZbfFMzqdKFQeCL1d+ZzxmJiySk0mIhUw7OzyiVG/u7wDbgpb7P5SFGTON3WSncOB9VajesAu5YKf6qY1ZMVcgG4DDwTE5/Amcb2J4DarJ7BHS2S0i/FcBBz6xs/scB4K1lvyeNYA8KblNbLiheqnqmNOZ6LegCnlq1ZTjpYHcJ7qgsB25Yvm5pLFBIHhiyuglTPLfkVYhht+5FdV36BvIqZD77PdTGa2osYwW7a/VOqhZdsWpRrGBPMsBdOaEuIJdHazE7iyKEQgqZjhHg8/+wZOGhEiPAMyXE4/F4PKTNX1LaBGZV5fLZAAAAAElFTkSuQmCC" />


                  </div>
                  <div>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider ">
                      {"EMAIL"}
                    </h3>


                  </div>

                </div>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  <a href={`mailto:${company?.email}`}>
                    {company?.email}
                  </a>
                </span>
              </div>


              <div >
                <div className="flex flex-row items-center">
                  <div className="w-6 h-6 mr-2">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEbUlEQVR4nO2ZaahVVRTHfy+1njaYmu+9JuUhZL1MP8QrpKwIpC8NkEODFGnhRAWBZQN+0KCE6EMUDdCANlp9SdFGpHpoZiRaOQTlFCVWVqLvCZrdWPDfsNmcYZ9773nd4P7gwD3nrL3WXnuvvfc660KTJk1qoQWYBDwFfAnsA1byP3NgOrAdqATX+py2rcB4YArwEPA08C7QA3wP/KGrL9D7J7AX2Ap8ALwAzAa61J/CjARWewZM+aPAFUBHgrwZugdYpk78neB8rdfv0n9prBOjgB/U+C9gLjAwkBkGTANelJOh0aMa+feAJ+TkNIXo+cBw6WhN0DsKuBC4FrgXeD3BxqfSk8nXEraQ6vSenwjcopk6Fijep9GaB3QDJ1F/xgCPAb96Ni1yUvkK2Ai06d5GbSHwSzDinwAPABMS4negwm26QvJNjeJWryPuOgzsBr4ALopwaCjwudraTEdxA/CjZ9RC7sGUdTJejn0MHKlyHXwWuajvlPyreYIWGq94BjYD1yQYsdFZAHwTdOi4QtN2qiXADG0UFtenJei4ANivtrdGONIt2S1ZQkM0MiZ4SHE/IEV2YbCrPKdZtMVclJnS8zNwao5sspanTN+VSWSWhPRMyukuzDwCBqo0XnU0U7XV7EuLWaykEJjctRdgJwQLK2ZdaDixWWR3MGscWLhNQ15RZquMeHjPNmrp48K70HNOPPaBO5CZgInAWc551zqfwkoXNyDM6VnB1YjnUJO5GlJSFZcq3a+WJ2udeyOrhBQlfnOPKG5OZ7z9IMhuTJteh0v16n+5PaATfqIOxT1nBGVgefl9JHUt7bYXcj0Cu5sQkdTLsvKlcTd0mppSJJKYJ/wr/tvW/P6GDeDLh70+FYrRN8qtaFG8QrgUtiHOmU0oP6fbPSkX88g5Zq3K38yzgT2KZ3Nv2OngwnrJN+WlSRjg4925TR9ospanzVzeT2jcq9P+skDWRmuHlwFkJnIpjNQpbTq2S+fJOnDX6rB1g2sO3heruE1Te0iLf55SiZB2pesVZc0jqJ4R3izs8JLW0hnmjeLmKtOSENPh6zydkjlFabcLhXqOXrs3y+sVYqUwyFtDluKf7b3L25Vin58L7NKzNQlfqDVjB9XLMvCbUgVKcMRt9/u9746qig9pLJHiPuU9ZdOtL0izubheSm+TwmMqDPQX13lVGetDTXR5IzMnQ66eoeUzR+8Pqy9VMRj4TopeypEtyxFk22S+VZ8Kc78UbKlWQZ0Y4tUGrE5Q+LxwO8dV/PdM8nbMvO/6xIKA1WFjKDO0HB9K9g4K8I4a3d5AjsyU7IoCfrBTjUbTOHR6WUU0vZGFiP5ksPdZEY37/6LQwiqZod6ZEo2rqFhJs1HoUp+s8B2N+wSdTOMwWX2yvwGjWaFGtlM0CrPUp7eKNFqqRotoHBapT48XaeT+h1geUSks6+oJbC/Xc5uZaC5XIytA+PSXE5WEg9JVQsNqTiZtBQyUQSXjKlx2+iiiwFYWPSm2Ld9q0qQJxfgXNOM6M+8ioVAAAAAASUVORK5CYII=" />


                  </div>
                  <div>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider ">
                      {"PHONE"}
                    </h3>


                  </div>

                </div>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  {company?.phoneNumber}
                </span>
              </div>
         
              <div>
                
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={contactUs}>
                <label className="block">
                  <Label>Full name</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    onChange={(e) => {
                      setfirstName(e.target.value);
                      seterrfirstName(false);
                    }}
                  />
                  {errfirstName && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={(e) => {
                      setemail(e.target.value);
                      seterremail(false);
                    }}
                  />
                  {erremail && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </label>
                <label className="block">
                  <Label>Subject</Label>

                  <Input
                    type="text"
                    placeholder="subject"
                    className="mt-1"
                    onChange={(e) => {
                      setsubject(e.target.value);
                      seterrsubject(false);
                    }}
                  />
                  {errsubject && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea className="mt-1" rows={6}
                    placeholder="message"
                    onChange={(e) => {
                      setmessage(e.target.value);
                      seterrmessage(false);
                    }}
                  />
                  {errmessage && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )}
                </label>
                <div>
                  <ButtonPrimary
                    type="submit"
                  >Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

        {/* OTHER SECTIONS */}
        {/* <div className="container">
        <div className="relative my-24 lg:my-32 py-24 lg:py-32">
          <BackgroundSection />
          <SectionPromo1 />
        </div>
      </div> */}

        {/* <div className="relative my-0 lg:my-0 pt-24">

       
        <iframe
          src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=167 Cheltenham Road, Dandenong VIC , 3175&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          className="relative w-full h-[600px] border-none block"
          allowFullScreen

        ></iframe>
      </div> */}
      </div>

      <ModalQuickViewToast
        show={openSuccess}
        onCloseModalQuickView={() => setopenSuccess(false)}
        msg="Your bank account connected successfully !"
      />

      <Modal
        open={false}
        // onClose={() => {
        //   setopenSuccess(false);
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {/* <CheckCircleIcon
                  sx={{ m: 1, color: "#90EE90", width: 40, height: 40 }}
                /> */}
                <i
                  className="fa fa-check-circle text-3xl text-[#002930] mt-1 "
                  aria-hidden="true"
                ></i>
                <Typography
                  className="text-[#002930]"
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                    textAlign: "center"
                    // color: "#F1A700"
                  }}
                >
                  Your bank account connected successfully !
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "9pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center"
                    }}
                  >
                    {/* Thank you for completing your weekly meal plan with us. Your
                    first order will be delivered on  between 3.30pm and 8.30pm every
                    day. Please check your order summary for more details. */}
                    {/* {`Thank you for completing your weekly meal plan with us. Your
                    first order will be delivered on ${localStorage.getItem('deliveryDate')} between 3.30pm and 8.30pm every
                    day. Please check your order summary for more details.`} */}

                    {/* {
                      getDeliverycharge() > 0 ?

                        `Thank you for completing your weekly meal plan with us. Your order will be delivered on ${getSuccessDates()} between 4pm and 8pm. Please make sure to be available someone to collect it from the delivery agent.`

                        :

                        "Thank you for completing your weekly meal plan with us. Your order can be collected on the following days between 4pm and 8pm."

                    } */}

                    {/* Thank you for completing your weekly meal plan with us. Your order can be collected or get delivered through Uber Package delivery on the following days between 4pm and  8pm. */}
                  </Typography>

                  {/* <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "9pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    <p>
                      Breakfast 6am - 9pm <br />
                      Lunch 11 a.m - 1.30
                      <br />
                      Dinner 5.30 p.m - 8.30 p.m
                    </p>
                    <p>
                      27th Fri  -  28th,29th,30th & 31st Meals
                      <br />
                      31st Tue -  01st, 2nd & 3rd Meals
                    </p>
                    {getSuccessDates()}
                  </Typography> */}

                  {/* <Typography
                    component="body1"
                    variant="h5"
                    style={{
                      fontSize: "8pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                      // fontStyle: "italic",
                    }}
                  >
                    <p>
                     
                      Your order can be collected from our pickup location or get it delivered from
                      uber delivery centre.
                     

                    </p>
                  </Typography> */}

                  <Typography
                    // component="body1"
                    variant="h5"
                    style={{
                      fontSize: "8pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center"
                      // fontStyle: "italic",
                    }}
                  >
                    <p>
                      {/* (Delivery times can be vary depending on the road traffic
                      conditions.) */}
                      {/* Please check your order confirmation email for more details. */}
                    </p>
                  </Typography>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
    </>
  );
};

export default PageContact;
