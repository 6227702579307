import React, { FC, useContext, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import { Link, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { addToast } from "shared/Toast";
import { postJobs, postSellerReview, postSiteReview } from "Services/API/Post";
import { GetUserId } from "Services/API/Get";
import BotToast from "shared/BotToast";
import AuthContext from "Context/AuthContext";
import { t } from "i18next";


export interface ProductQuickView2Props {
  className?: string;
  onCloseModalQuickView?: any,
  proId?: any;
  type?: any;
}

const ProductQuickView2: FC<ProductQuickView2Props> = ({ className = "", onCloseModalQuickView, proId = "", type = "" }) => {
  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);

  const [value, setValue] = React.useState<any>(0);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [errtitle, seterrtitle] = useState(false);
  const [errdescription, seterrdescription] = useState(false);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const { user, setUser } = useContext(AuthContext);

  const router = useHistory();

  useEffect(() => {
    if (!user) {
      // addToast("Please login.", "warning");
      router.push("/login");

      return;
    }

  }, [user])


  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={LIST_IMAGES_DEMO[0]}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const addReview = (e?: any) => {
    e.preventDefault();

    if (!title) {
      seterrtitle(true);
    }

    if (!description) {
      seterrdescription(true);
    }

    let obj = {
      feedback: description,
      title: title,
      userId: GetUserId(),
      rating: parseInt(value),
      sellerId: proId,
    }

    console.log(">>>>>obj", obj)

    if (title && description) {

      if (type === "site") {

        postSiteReview(obj)
          .then((res) => {
            // setlopen(false);
            // setbOpen(true);
            // setbMsg("Review successfully added");
            // setbStatus("success");

            setdescription("");
            settitle("");
            setValue(0);


            addToast("Review successfully added", "success");
            onCloseModalQuickView();
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            // setlopen(false);
            console.log("Error", error.response);

            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          })

      } else {

        postSellerReview(obj)
          .then((res) => {
            // setlopen(false);
            // setbOpen(true);
            // setbMsg("Review successfully added");
            // setbStatus("success");

            setdescription("");
            settitle("");
            setValue(0);


            addToast("Review successfully added", "success");
            onCloseModalQuickView();
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            // setlopen(false);
            console.log("Error", error.response);

            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          })


      }


    }

  }

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {

        setbOpen(false)

      }, 10000);
    }
  }, [bOpen])

  return (
    <div className={`nc-ProductQuickView2 ${className} mt-16 sm:mt-0 md:px-40 py-6`}>
      {/* MAIn */}
      <div className="lg:flex">
        {/* CONTENT */}
        <div className="w-full ">
          {/* HEADING */}





          <div className="mt-8">


            <h2 className="flex justify-center text-base sm:text-base text-slate-900 dark:text-neutral-100 font-semibold">
              {t("revTitle")}
            </h2>

            <div className="flex-grow mt-10 md:mt-0 md:pl-0 max-w-3xl space-y-6">
              <div>
                <Input className="mt-1.5"
                  placeholder="e.g: This is a great platform !!"
                  onChange={(e) => {
                    settitle(e.target.value);
                    seterrtitle(false);
                  }}
                  value={title}

                />
                {errtitle && (
                  <div className="text-red-600 text-sm text-right mt-2">
                    This field is required
                  </div>
                )}
              </div>

            </div>

          </div>

          <div className="mt-8 ">


            <h2 className="flex justify-center text-base sm:text-base text-slate-900 dark:text-neutral-100 font-semibold">
            {t("revDesc")}

            </h2>

            <div className="flex-grow mt-10 md:mt-0 md:pl-0 max-w-3xl space-y-6">
              <div>
                <Textarea className="mt-1.5" defaultValue=""
                  onChange={(e) => {
                    if (description.length <= 1000 && e.target.value.length <= 1000) {
                      setdescription(e.target.value);
                      seterrdescription(false);
                    } else if (e.target.value.length > 1000) {
                      setdescription(e.target.value.slice(0, 1000));
                      seterrdescription(false);
                    }
                  }}
                  value={description}
                />
                <span className="text-xs text-right font-normal w-full flex justify-end mt-1">{description.length}/1000 {t("reWords")}</span>
                {errdescription && (
                  <div className="text-red-600 text-sm text-right mt-2">
                    This field is required
                  </div>
                )}
              </div>

            </div>

          </div>

          <div className="flex justify-center mt-3">


            <h2 className="text-base sm:text-base text-slate-900 dark:text-neutral-100 font-semibold">
            {t("revRate")}

            </h2>

          </div>

          <div className="flex justify-center mt-3">
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
          </div>

          <div className="mt-8">
            <BotToast open={bOpen} msg={bMsg} status={bStatus} />
            <button
              className={
                `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b] hover:bg-slate-800 
        w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}

              onClick={addReview}
            >
              {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
              <span className="block  text-sm font-medium">
              {t("Save")}

              </span>

            </button>


          </div>



        </div>


      </div>
    </div>
  );
};

export default ProductQuickView2;
