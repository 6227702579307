import React, { FC, useContext, useState } from "react";
import imageRightPng from "images/hero-right.png";
import imageRightPng2 from "images/hero-right-2.png";
import imageRightPng3 from "images/hero-right-3.png";
import check from "images/check.png";

import backgroundLineSvg from "images/Moon.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import { getCover } from "Services/API/Get";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import { Link, NavLink, useHistory } from "react-router-dom";
import cover1 from "images/cover/cover-4.png";
import cover2 from "images/cover/cover-5.png";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import cover12 from "images/cover/cover-12.png";
import { COLORS } from "Constant/Colors";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

import ICON1 from "images/dekstop/1.png";
import ICON2 from "images/dekstop/2.png";
import ModalQuickView from "components/ModalJob";
import { addToast } from "shared/Toast";
import AuthContext from "Context/AuthContext";
import { t } from "i18next";

interface Hero2DataType {
  image: string;
  heading: string;
  subHeading: string;
  btnText: string;
  btnLink: string;
}

interface Hero2DataType2 {
  image: string;
  caption1: string;
  caption2: string;
}

export interface SectionHero2Props {
  className?: string;
  tabNo?: any;
  settabNo?: any;
}

const DATA: Hero2DataType[] = [
  {
    image: imageRightPng2,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/"
  },
  {
    image: imageRightPng3,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/"
  },
  {
    image: imageRightPng,
    heading: "Exclusive collection for everyone",
    subHeading: "In this season, find the best 🔥",
    btnText: "Explore now",
    btnLink: "/"
  }
];
let TIME_OUT: NodeJS.Timeout | null = null;

const MCOVERS: any = [
  {
    image: cover1
  },
  {
    image: cover2
  }
];

const SectionHero2: FC<SectionHero2Props> = ({
  className = "",
  tabNo,
  settabNo
}) => {
  // =================
  const { t } = useTranslation();
  const [indexActive, setIndexActive] = useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(true);

  const [showModalQuickView, setShowModalQuickView] = useState(false);

  const { user, setUser } = useContext(AuthContext);

  const history = useHistory();

  const [slides, setSlides] = React.useState<Hero2DataType2[]>([
    {
      image: "",
      caption1: "",
      caption2: ""
    }
  ]);
  const [lopen, setlopen] = React.useState(false);

  React.useEffect(() => {
    // getSlides();
  }, []);

  const getSlides = () => {
    setlopen(true);
    getCover()
      .then((res) => {
        console.log("COver res>>>>", res.data);
        setlopen(false);

        setSlides(res.data);
      })
      .catch((error) => {
        console.log("cover error", error.response);
        setlopen(false);
      });
  };

  useInterval(
    () => {
      handleAutoNext();
    },
    isRunning ? 5500 : null
  );
  //

  const handleAutoNext = () => {
    setIndexActive((state) => {
      if (state >= slides.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= slides.length - 1) {
        return 0;
      }
      return state + 1;
    });
    handleAfterClick();
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return slides.length - 1;
      }
      return state - 1;
    });
    handleAfterClick();
  };

  const handleAfterClick = () => {
    toggleIsRunning(false);
    if (TIME_OUT) {
      clearTimeout(TIME_OUT);
    }
    TIME_OUT = setTimeout(() => {
      toggleIsRunning(true);
    }, 1000);
  };
  // =================

  const featureItems = [
    {
      icon: ICON1,
      title: t("featureOneTitle"),
      description: t("featureOneDes"),
      des2: "0"
    },
    {
      icon: ICON1,
      title: t("featureTwoTitle"),
      description: t("featureTwoDes"),
      des2: "*Free ads are available exclusively for residents currently residing in Sri Lanka"
    },
    {
      icon: ICON1,
      title: t("featureThreeTitle"),
      description: t("featureThreeDes"),
      des2: "0"
    },
    {
      icon: ICON1,
      title: t("featureFourTitle"),
      description: t("featureFourDes"),
      des2: "0"
    },
    {
      icon: ICON1,
      title: t("featureFiveTitle"),
      description: t("featureFiveDes"),
      des2: "0"
    },
    {
      icon: ICON1,
      title: t("featureSixTitle"),
      description: t("featureSixDes"),
      des2: "0"
    },
    {
      icon: ICON1,
      title: t("featureSevenTitle"),
      description: t("featureSevenDes"),
      des2: "0"
    },
    {
      icon: ICON1,
      title: t("featureEightTitle"),
      description: t("featureEightDes"),
      des2: "0"
    }
  ];

  const renderItem = (index: number) => {
    const isActive = indexActive === index;
    const item = slides[index];
    if (!isActive) {
      return null;
    }
    return (
      <>
        <ModalQuickView
          show={showModalQuickView}
          onCloseModalQuickView={() => setShowModalQuickView(false)}
          selectedJob={""}
        />

        <div>
          <div
            className={`//nc-SectionHero2Item //nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
            key={index}
          >
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex justify-center">
              {slides.map((_, index) => {
                const isActive = indexActive === index;
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setIndexActive(index);
                      handleAfterClick();
                    }}
                    className={`relative px-1 py-1.5 cursor-pointer`}
                  ></div>
                );
              })}
            </div>

            <div className="invisible md:visible">
              <div
                className={`absolute inset-0 bg-[#003d35] h-full bg-cover ${
                  index != 1 ? "bg-center" : "bg-[center_left_-17.7rem]"
                } lg:bg-[center_bottom_-2.8rem] `}
                style={
                  {
                    // backgroundImage: `url(${IMAGE_SERVER_URL_DEV}${item?.image})`
                    // backgroundImage: `url(${cover12})`
                  }
                }
              ></div>
            </div>

            <div className="visible md:invisible">
              <div
                className={`absolute inset-0 bg-[#003d35] h-full bg-cover ${
                  index != 1 ? "bg-center" : "bg-center"
                } lg:bg-[center_bottom_-2.8rem] `}
                style={
                  {
                    // backgroundImage: `url(${MCOVERS[index]?.image})`
                    // backgroundImage: `url(${cover12})`
                  }
                }
              ></div>
            </div>

            <div className="relative container pb-0 pt-0 lg:py-44 h-[510px] xl:h-[450px] ">
              <div
                className={`relative z-[1] w-full h-full flex justify-center items-center space-y-8 sm:space-y-14 nc-SectionHero2Item__left`}
              >
                <div className="">
                  <header className="   flex flex-col">
                    <div className="mb-10">
                      <h2
                        className={`text-3xl sm:text-3xl md:text-4xl  ${
                          t("langKey") === "Si"
                            ? "font-[Gamunu] text-4xl lg:text-[55px] font-extrabold md:leading-[60px]  lg:px-8"
                            : "lg:text-[50px] font-[Inter] !leading-[114%] font-bold lg:px-8"
                        }   text-slate-50 text-center `}
                      >
                        {/* Sri Lanka's fast growing platform for  */}
                        {t("bPrimary")} <br />
                        <span className="text-[#d49e00]">
                          {/* marriage proposals */} {t("bSecondary")}
                        </span>
                      </h2>

                      <span
                        className={`block  ${
                          t("langKey") === "Si"
                            ? " font-semibold text-[14px]"
                            : "text-sm md:text-sm"
                        } text-slate-100 font-normal flex justify-center px-12 text-center mt-6`}
                      >
                        {t("bDesc")}

                        {/* Discover the life partner with just a few clicks. */}
                      </span>
                    </div>

                    <div className="w-full grid sm:grid-cols-2 flex justify-center items-center gap-y-6 mt-3 gap-x-4">
                      <div className="w-full hidden sm:flex justify-center">
                        <div className="w-full flex justify-end">
                          <NavLink to={"/signup?userType=seeker"}>
                            <button
                              className={`ttnc-ButtonCircle rounded-3xl flex items-center justify-center !leading-none disabled:bg-opacity-70 
sm:h-9 text-slate-900 border border-white bg-white px-2  py-2  sm:py-4 w-[340px] `}

                              // onClick={() => { setShowModalQuickView(true) }}
                            >
                              {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                              <span
                                className={`block   font-medium ${
                                  t("langKey") === "Si"
                                    ? "text-[11px]"
                                    : "text-xs"
                                }`}
                              >
                                {t("bButton1")}

                                {/* Register as a Proposal Seeker/Advertiser */}
                              </span>
                            </button>
                          </NavLink>
                        </div>
                      </div>

                      <div className="w-full flex justify-center sm:hidden">
                        <div className="w-full flex justify-start">
                          <button
                            className={`ttnc-ButtonCircle rounded-3xl flex items-center justify-center !leading-none disabled:bg-opacity-70 
sm:h-9 text-slate-900 border bg-white py-2 sm:px-4 sm:py-4 w-[340px]  `}

                            // onClick={() => { setShowModalQuickView(true) }}
                          >
                            {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                            <span
                              className={`block   font-medium ${
                                t("langKey") === "Si"
                                  ? "text-[11px]"
                                  : "text-xs"
                              }`}
                              onClick={() => {
                                if (!user) {
                                  // addToast("Please Login", "warning");
                                  history.push("/login");

                                  return;
                                } else if (
                                  user?.userType === "service provider"
                                ) {
                                  addToast(
                                    "Please login as a proposal seeker",
                                    "warning"
                                  );
                                  history.push("/login");
                                  return;
                                }

                                setShowModalQuickView(true);
                              }}
                            >
                              {t("hPublish")}
                              {/* Register as a Wedding Service Provider */}
                            </span>
                          </button>
                        </div>
                      </div>

                      <div className="w-full flex justify-center">
                        {/* <div className="w-full flex justify-start">

                <span className="nc-SectionHero2Item__subheading block text-sm md:text-base text-slate-100 font-normal flex justify-center text-center">
                  Want to sell from Sri Lanka to overseas residents ?
                </span>

              </div> */}

                        <div className="w-full flex justify-start">
                          <NavLink to={"/signup?userType=provider"}>
                            <button
                              className={`ttnc-ButtonCircle rounded-3xl flex items-center justify-center !leading-none disabled:bg-opacity-70 
sm:h-9 text-slate-900 border border-white bg-white py-2 sm:px-4 sm:py-4 w-[340px]  `}

                              // onClick={() => { setShowModalQuickView(true) }}
                            >
                              {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                              <span
                                className={`block   font-medium ${
                                  t("langKey") === "Si"
                                    ? "text-[11px]"
                                    : "text-xs"
                                }`}
                              >
                                {t("bButton2")}
                                {/* Register as a Wedding Service Provider */}
                              </span>
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </header>
                </div>
              </div>
              <div className="mt-10 lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
                <div className="w-full h-52 object-contain object-right-bottom nc-SectionHero2Item__image"></div>
                {/* <img
      className="w-full h-full object-contain object-right-bottom nc-SectionHero2Item__image"
      src={`${IMAGE_SERVER_URL_DEV}${item?.image}`}
      alt={item.caption1}
    /> */}
              </div>
            </div>
            {/* </Link> */}
          </div>
          <div className="w-full bg-[#e1efee] md:bg-transparent  justify-center items-center md:container relative flex flex-col pb-2 md:pb-0 md:-mt-10 z-[1]  ">
            <div className="md:bg-white bg-[#e1efee] rounded-md md:shadow-xl w-full flex  flex-wrap gap-[2%] md:gap-0 justify-center items-center md:items-start py-5 pt-8 md:pt-12 px-10 md:px-12">
              {featureItems.map((item) => {
                return (
                  <FeatureCard
                    title={item.title}
                    icon={item.icon}
                    des={item.description}
                    des2={item.des2}
                  />
                );
              })}
            </div>
            <div className=" md:hidden font-normal text-[#000000ce] text-left leading-tight italic pb-2 text-[10px]">
              *Free ads are available exclusively for
          
              residents currently residing in Sri Lanka
            </div>
          </div>

          <div
            className={`w-full container mt-6 sm:mt-10 pt-3 md:pt-10 pb-5 relative z-[1]`}
          >
            <div className="flex  grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-y-0 gap-x-4 sm:gap-x-1">
              {/* <NavLink to={"/jobs"}> */}
              <div
                className={`w-full bg-[#009b88]  py-3 sm:p-5 text-slate-100 flex  pl-0 justify-center sm:pl-0 sm:justify-center items-center rounded-md  cursor-pointer
    ${tabNo === 1 ? "border-b-4 border-[#FFB81C]" : ""}
    `}
                onClick={() => {
                  settabNo(1);
                }}
              >
                <div className="flex flex-col sm:justify-center items-center text-center">
                  <div className="flex  justify-start text-left">
                    <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-start text-left items-center md:space-x-4">
                      <div>
                        <img src={ICON1} className="w-[50px] lg:w-[70px]"></img>
                      </div>
                      <div className="flex flex-col items-center md:items-start text-center md:text-start">
                        <div
                          className={` ${
                            t("langKey") === "Si"
                              ? "text-sm lg:text-md"
                              : "text-xs lg:text-base"
                          }`}
                        >
                          <span>
                            {/* Looking for */}
                            {t("bLeftCardTitle")}
                          </span>
                        </div>

                        <span
                          className={`w-[85%] md:w-full text-center mt-1 md:mt-0 md:items-start font-bold ${
                            t("langKey") === "Si"
                              ? "text-lg lg:text-xl"
                              : "text-lg lg:text-xl"
                          } `}
                        >
                          {/* Wedding Service Providers */}
                          {t("bLeftCardDesc")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`w-full bg-[#bd8d01]  py-3 sm:p-5 text-slate-100 flex pl-0 sm:pl-0 justify-center sm:justify-center items-center rounded-md cursor-pointer
     ${tabNo === 2 ? "border-b-4 border-[#FFB81C]" : ""}
    `}
                onClick={() => {
                  settabNo(2);
                }}
              >
                <div className="flex flex-col justify-center items-center text-center">
                  <div className="flex flex-col md:flex-row gap-3 md:gap-0 justify-start text-left items-center md:space-x-4">
                    <div>
                      <img src={ICON2} className="w-[50px] lg:w-[70px]"></img>
                    </div>
                    <div className="flex flex-col items-center md:items-start text-center md:text-start">
                      <div
                        className={` ${
                          t("langKey") === "Si"
                            ? "text-sm lg:text-md"
                            : "text-xs lg:text-base"
                        }`}
                      >
                        <span>
                          {/* Looking for */}
                          {t("bLeftCardTitle")}
                        </span>
                      </div>

                      <span
                        className={`w-[85%] md:w-full text-center mt-1 md:mt-0 md:items-start font-bold ${
                          t("langKey") === "Si"
                            ? "text-lg lg:text-xl"
                            : "text-lg lg:text-xl"
                        } `}
                      >
                        {/* Wedding Service Providers */}
                        {t("bRightCardDesc")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* </NavLink> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  return <>{slides.map((_, index) => renderItem(index))}</>;
};

const FeatureCard: React.FC<{
  title: string;
  icon: string;
  des: string;
  des2: string;
}> = ({ title, icon, des, des2 }) => {
  return (
    <div className="flex w-full md:w-[24%] md:h-auto items-start gap-2 mb-1 md:mb-5 md:bg-white md:bg-transparent  p-1 md:p-0  ">
      <div className="md:w-[10%] mt-1 md:mt-0  w-[5%]">
        <img src={check} className="w-full h-full" alt="" />
      </div>
      <div className="md:w-[85%] w-[95%] hidden md:flex flex-col items-start gap-2 md:gap-1">
        {t("langKey") === "Si" ? (
          <div className=" font-bold leading-normal text-[#003d35] md:text-[#000000ce] text-start text-[13px]">
            {title} <span className="font-medium text-[#000000ce] ">{des}</span>
          </div>
        ) : (
          <>
            <div className=" font-semibold text-[#003d35] md:text-[#000000ce] text-center md:text-start leading-tight text-[13px]">
              {title}{" "}
            </div>
            <div className=" font-normal text-[#000000ce] text-center md:text-start leading-tight text-[12px]">
              {des}
            </div>
          </>
        )}

        {des2 !== "0" && (
          <div className=" hidden md:block font-normal text-[#000000ce] text-left leading-tight italic text-[10px]">
            {des2}
          </div>
        )}
      </div>
      <div className="md:w-[85%] w-[95%] md:hidden flex flex-col items-start gap-2 md:gap-1">
        <div className=" font-bold leading-normal  text-[#000000] text-start text-[14px]">
          {title} <span className="font-medium text-[#000000ce] ">{des}</span>
        </div>
      </div>
    </div>
  );
};

export default SectionHero2;
