import { getCompany } from "../../Services/API/Get";
let A_SERVER = "";
let U_SERVER = "";
let ECOME_URL = "";
let ADMIN_URL = "";
switch (0) {
  case 1:
    A_SERVER = "https://backend.admin.ozhire.orderbookinventory.com";
    U_SERVER = "https://backend.ozhire.orderbookinventory.com";
    ECOME_URL = "https://leos.zkoobi.com";
    ADMIN_URL = "https://admin.leos.zkoobi.com/";

    break;
  case 2:
    A_SERVER = "https://backend.admin.yolo.zkoobi.com";
    U_SERVER = "https://backend.yolo.zkoobi.com";
    ECOME_URL = "https://yolo.zkoobi.com";
    ADMIN_URL = "https://admin.yolo.zkoobi.com/";
    break;

  case 3:
    A_SERVER = "https://backend.admin.pillow.zkoobi.com";
    U_SERVER = "https://backend.pillow.zkoobi.com";
    ECOME_URL = "https://pillow.zkoobi.com";
    ADMIN_URL = "https://admin.pillow.zkoobi.com/";

    break;
  case 4:
    A_SERVER = "https://backend.admin.uruwalatea.zkoobi.com";
    U_SERVER = "https://backend.uruwalatea.zkoobi.com";
    ECOME_URL = "https://uruwalatea.zkoobi.com";
    ADMIN_URL = "https://admin.uruwalatea.zkoobi.com/";
    break;
  case 5:
    A_SERVER = "https://backend.admin.test5.zkoobi.com";
    U_SERVER = "https://backend.test5.zkoobi.com";
    ECOME_URL = "https://test5.zkoobi.com";
    ADMIN_URL = "https://admin.test5.zkoobi.com/";

    break;
  case 6:
    A_SERVER = "https://backend.admin.feltybunny.zkoobi.com";
    U_SERVER = "https://backend.feltybunny.zkoobi.com";
    ECOME_URL = "https://feltybunny.zkoobi.com";
    ADMIN_URL = "https://admin.feltybunny.zkoobi.com/";

    break;
  case 7:
    A_SERVER = "https://backend.admin.handsondecor.zkoobi.com/";
    U_SERVER = "https://backend.handsondecor.zkoobi.com";
    ECOME_URL = "https://handsondecor.zkoobi.com";
    ADMIN_URL = "https://admin.handsondecor.zkoobi.com/";
    break;
  case 0:
    A_SERVER = "https://backend.mangalya.lk";
    U_SERVER = "https://backend.mangalya.lk";
    ECOME_URL = "https://ncc.ecommerce.oxgymcrm.com/#/";
    break;
}

//Proudction
export const SITE_URL = "https://mangalya.lk/#/";
export const SERVER_URL = U_SERVER;
export const ADMIN_SERVER_URL = A_SERVER;
//Development
export const SERVER_URL_DEV = "http://localhost:4000";

const ADMIN_SERVER_DEV = "http://localhost:5000";
//IMAGE SEVER
// export const IMAGE_SERVER_URL = "http://localhost:5000";
export const IMAGE_SERVER_URL = "https://backend.orderbook.infinitycs.com.lk";
export const IMAGE_SERVER_URL_DEV = SERVER_URL;
export const IMAGE_SERVER_URL_DEV2 = SERVER_URL;


export const SUPPORT_URL = "https://businessdirectory.infinitycs.com.au";

export const CURRENCY = async () => {
  let payload = "";
  const res = await getCompany();

  if (res.statusCode === 200 || res.statusCode === 201) {
    payload = res.data[res.data.length - 1].currency.split("-")[0];
  }
  //res.data[res.data.length - 1].currency?.split["-"][0],
  return payload;
};

//
/**
 * backend.admin.leos.zkoobi.com
 * backend.leos.zkoobi.com
 */

/**
 * backend.admin.yolo.zkoobi.com
 * backend.yolo.zkoobi.com
 */

/**
 * backend.admin.pillow.zkoobi.com
 * backend.pillow.zkoobi.com
 */

/**
 * backend.admin.uruwalatea.zkoobi.com
 * backend.uruwalatea.zkoobi.com
 */

/**
 * backend.admin.test5.zkoobi.com
 * backend.test5.zkoobi.com
 */
