import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/Image-1.png";
import { StarIcon } from "@heroicons/react/24/solid";
import { Rating } from "@mui/material";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import moment from "moment";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  data?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  data
}) => {
  const getState = (item?: any) => {
    if (item === "Victoria") {
      return "VIC";
    } else if (item === "New South Wales") {
      return "NSW";
    } else if (item === "Western Australia") {
      return "WA";
    } else if (item === "Queensland") {
      return "QLD";
    } else if (item === "Tasmania") {
      return "TAS";
    } else if (item === "South Australia") {
      return "SA";
    } else if (item === "Northern Territory") {
      return "NT";
    } else if (item === "Australian Capital Territory") {
      return "ACT";
    }
  };

  return (
    <div className={`nc-CardCategory2 ${className}bg-white md:w-[260px] rounded-md px-3 md:py-2 pt-2 min-h-[450px] md:min-h-[390px]`} data-nc-id="CardCategory2">
      <div
      // className={`flex-1 relative w-full h-0 rounded-2xl overflow-hidden  ${ratioClass}`}
      >
        <div className="p-4  rounded-md">
          <div className="flex ">
            {/* <div className="w-14 h-14 object-fill bg-contain rounded-full"
              style={{
                // backgroundImage: `url(${Company1})`
                backgroundImage: data ? `url(${IMAGE_SERVER_URL_DEV}${data?.avatar})` : `url(${Company1})`
              }}
            >

            </div> */}

            {/* <ButtonCircle
              // className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
              size=" w-11 h-11"
              type="submit"
            >
              <i className="las la-apple-alt text-4xl text-left"></i>


            </ButtonCircle> */}

            <div className="ml-0">
              <h2 className="text-[18px] md:text-base text-[#2f3840] dark:text-neutral-100 font-bold">
                {data ? data?.firstName?.slice(0, 20) : "Company description"}

                {/* {
                  data?.businessName?.length >= 15 ? "...." : ""
                } */}
                {/* {data ? data?.businessName : "Company Name"} */}
              </h2>
              {data?.homeCity && (
                <span className="block  text-base md:text-[12px] text-black dark:text-neutral-400 mt-1">
                  <i className="las la-map-marker text-left mr-2"></i>

                  {data?.country === "Sri Lanka" ? (
                    <>
                      {data?.homeCity} {getState(data?.homeDistrict)}
                    </>
                  ) : (
                    <>{data?.country}</>
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="mt-4">
            <h2 className="text-base md:text-[13px] text-[#2f3840] dark:text-neutral-100 font-semibold">
              {data ? data?.title : "Review Title"}
            </h2>
            <span className="flex flex-col items-start mt-2 text-sm text-neutral-500 dark:text-neutral-400">
              <Rating
                name="size-small"
                value={data?.rating}
                size="small"
                className=""
                readOnly
              />
              {/* <div className="flex items-center space-x-0.5  text-yellow-500 mr-2">
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
              </div> */}

              {/* <span className=" text-black text-xs">
                on {moment(data?.createdAt).format("DD/MM/YYYY")}
              </span> */}
            </span>

            <span className="block mt-2 sm:mt-2 text-sm md:text-xs leading-normal text-[#2f3840] font-normal">
              {/* {
                  data ? data?.feedback?.slice(0, 80) : "Company description - See completed projects and flexible talent strategies from ......."
                }
                {
                  data?.feedback?.length >= 80 ? "......." : ""
                } */}
              {data?.feedback}
            </span>
          </div>

          {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
          {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
        </div>
        <div className="pt-0"></div>
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
    </div>
  );
};

export default CardCategory2;
