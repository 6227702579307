// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/Maps.png";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";

export interface PageAboutProps {
    className?: string;
}

const FOUNDER_DEMO: People[] = [
    {
        id: "1",
        name: `Niamh O'Shea`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "4",
        name: `Danien Jame`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "3",
        name: `Orla Dwyer`,
        job: "Co-founder, Chairman",
        avatar:
            "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "2",
        name: `Dara Frazier`,
        job: "Co-Founder, Chief Strategy Officer",
        avatar:
            "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
];


const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Cancellation Policy || Mangalya.lk</title>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            {/* <BgGlassmorphism /> */}

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-14">
                {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}

                <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10 ">

                    <div className="">

                        <h2 className="text-xl font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
                            Profile Cancellation Policy
                        </h2>
                        <div className="text-left">
                            {/* <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-6">
                                Refunds
                            </h2> */}
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                At Mangalya.lk, we understand that our members may want to deactivate or delete their accounts for various reasons. We have a simple and transparent policy in place to facilitate this process.                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                If a member wishes to deactivate or delete their account, they can do so at any time. However, please note that if a member is in the middle of a paid subscription, they will not be entitled to any refunds. This is because we provide our members with access to our services as soon as payment is made, and we cannot recover the costs associated with that access once it has been granted.                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                To deactivate or delete an account, simply log in to your account and follow the instructions provided on the website. If you encounter any difficulties, please do not hesitate to contact our support team (support@infinitycs.com.au) for assistance.                            </span>


                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                We hope that you will be satisfied with our service and that you will continue to use Mangalya.lk for your cleaning needs. If you have any questions or concerns about our profile cancellation policy, please feel free to contact us.
                            </span>
                            {/* <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-8">
                                Faulty or Damaged Goods Under Warranties
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                In addition to specific guarantees from manufacturers, Oz Cleaning Suppplies and its suppliers provide a guarantee of acceptable quality on every product by law. You’re entitled to a replacement or refund for a major failure. You may also be compensated for other reasonably foreseeable loss or damage. You are also entitled to have the goods replaced or repaired if the goods fail to be of an acceptable quality, but the failure is not the equivalent of what is regarded as a major failure.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                We strongly recommend that you retain a copy of the purchase confirmation/invoice sent to your email, as we may require you to present proof that you purchased the product from us. In the case of any issues, our customer service team will be able to advise whether a refund, exchange, repair or replacement is available.
                            </span> */}




                            {/* <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8 mt-6">
                {FOUNDER_DEMO.map((item) => (
                  <div key={item.id} className="max-w-sm">
                    <NcImage
                      containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                      className="absolute inset-0 object-cover"
                      src={item.avatar}
                    />
                    <h3 className="text-base font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
                      {item.name}
                    </h3>
                    <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                      {item.job}
                    </span>
                  </div>
                ))}
              </div> */}

                        </div>
                    </div>

                </div>





                {/* <SectionFounder /> */}
                {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

                {/* <SectionPromo3 /> */}
            </div>
        </div>
    );
};

export default PageAbout;
