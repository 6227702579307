import React, { HTMLAttributes, ReactNode } from "react";
import NextPrev from "shared/NextPrev/NextPrev";
import { t } from "i18next";

export interface HeadingProps3 extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  rightDescText?: ReactNode;
  rightPopoverOptions?: typeof solutions;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  className2?: any;
  type?: any;
}

const solutions = [
  {
    name: "last 24 hours",
    href: "##"
  },
  {
    name: "last 7 days",
    href: "##"
  },
  {
    name: "last 30 days",
    href: "##"
  }
];
const Heading3: React.FC<HeadingProps3> = ({
  children,
  desc = "",
  className = "mb-8 lg:mb-3 text-[#002930] dark:text-neutral-50 md:px-40",
  isCenter = false,
  hasNextPrev = false,
  fontClass = "text-2xl md:text-3xl font-black",
  rightDescText,
  rightPopoverOptions = solutions,
  className2 = "text-[#002930]",
  type = "",
  ...args
}) => {
  return (
    <div
      className={`nc-Section-Heading relative flex flex-col sm:flex-row px-6 md:px-0 sm:items-end justify-between `}
    >
      <div
        className={
          isCenter
            ? "flex flex-col items-center text-center w-full mx-auto"
            : ""
        }
      >
        <h2
          className={`${isCenter ? "justify-center" : ""} ${fontClass}`}
          {...args}
        >
          {children || `Section Heading`}
          {rightDescText && (
            <>
              <span className="">{`. `}</span>
              <span className="text-white dark:text-neutral-400">
                {rightDescText}
              </span>
            </>
          )}
        </h2>
        {type === "sellers" ? (
          <>
            <div className="w-full grid grid-cols-2 flex justify-center divide-x-[3px] divide-[#737373] mt-10">
              <div className="w-full pr-10">
                <div className="w-full flex justify-end">
                  <span className="nc-SectionHero2Item__subheading block text-sm text-slate-100 font-normal flex justify-center text-center text-[#737373]">
                    Residing overseas and want to sell or promote globally?
                  </span>
                </div>

                <div className="w-full flex justify-end mt-3">
                  <button
                    className={`ttnc-ButtonCircle rounded-3xl flex items-center justify-center !leading-none disabled:bg-opacity-70 
sm:h-9 text-slate-900 font-bold border border-[#2d867b] text-[#2d867b] px-4  py-1 sm:px-12 sm:py-5`}

                    // onClick={() => { setShowModalQuickView(true) }}
                  >
                    {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                    <span className="block  text-xs font-bold ">
                      Open FREE online shop
                    </span>
                  </button>
                </div>
              </div>

              <div className="w-full pl-10">
                <div className="w-full flex justify-start">
                  <span className="nc-SectionHero2Item__subheading block text-sm text-slate-100 font-normal flex justify-center text-center text-[#737373]">
                    Want to sell from Sri Lanka to overseas residents ?
                  </span>
                </div>

                <div className="w-full flex justify-start mt-3">
                  <button
                    className={`ttnc-ButtonCircle rounded-3xl flex items-center justify-center !leading-none disabled:bg-opacity-70 
sm:h-9 text-slate-900 font-bold border border-[#F8761F] text-[#F8761F] px-4  py-1 sm:px-12 sm:py-5`}

                    // onClick={() => { setShowModalQuickView(true) }}
                  >
                    {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                    <span className="block  text-xs font-bold ">
                      Open FREE exporter shop
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : type === "uncover" ? (
          <>
            <div className="w-full grid grid-cols-1 flex justify-center mt-10">
              <div className="w-full">
                <div className="w-full flex justify-center">
                  <span className="nc-SectionHero2Item__subheading block text-sm text-slate-100 font-normal flex justify-center text-center text-[#737373]">
                    Want to promote your services locally and globally ?
                  </span>
                </div>

                <div className="w-full flex justify-center mt-3">
                  <button
                    className={`ttnc-ButtonCircle rounded-3xl flex items-center justify-center !leading-none disabled:bg-opacity-70 
sm:h-9 text-slate-900 font-bold border border-[#00BF63] text-[#00BF63] px-4  py-2 sm:px-12 sm:py-5`}

                    // onClick={() => { setShowModalQuickView(true) }}
                  >
                    {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                    <span className="block  text-xs font-bold ">
                      Create a service provider page
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <span
            className={`mt-5 md:mt-5 font-normal ${
              t("langKey") === "Si" ? "md:w-[70%]" : "md:w-[70%]"
            }  block text-[14px]  sm:text-[14px]  text-white dark:text-neutral-400 `}
          >
            {desc}
          </span>
        )}
        {/* {!!desc && (
          <span className={`mt-2 md:mt-3 font-normal block text-base sm:text-lg ${className2} dark:text-neutral-400 `}>
            {desc}
          </span>
        )} */}
      </div>
      {hasNextPrev && !isCenter && (
        <div className="mt-4 flex justify-end sm:ml-2 sm:mt-0 flex-shrink-0">
          <NextPrev onClickNext={() => {}} onClickPrev={() => {}} />
        </div>
      )}
    </div>
  );
};

export default Heading3;
