import React, { FC, useContext, useEffect, useState, Fragment } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import detail1JPG from "images/products/detail1.jpg";
import detail2JPG from "images/products/detail2.jpg";
import detail3JPG from "images/products/detail3.jpg";
import NotifyAddTocart from "./NotifyAddTocart";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Label from "./Label/Label";
import Select from "shared/Select/Select";
import { IMAGE_SERVER_URL } from "Constant/System";
import { addToast } from "shared/Toast";
import {
  postApplication,
  postApplicationContract,
  postSellerReview
} from "Services/API/Post";
import { AppliedJobs, GetUserId, getProfiles } from "Services/API/Get";
import AuthContext from "Context/AuthContext";
import LoadingSpinner from "shared/LoadingSpinner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BotToast from "shared/BotToast";
import moment from "moment";
import { Autocomplete } from "@mui/material";
import WishContext from "Context/WishContext";
import { t } from "i18next";
import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import ModalQuickView from "./ModalApplicantPkg";

const renderXClear = () => {
  return (
    <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-3 w-3"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  );
};

export interface ProductQuickView2Props {
  className?: string;
  onCloseModalQuickView?: any;
  jobId?: any;
  type?: any;
  shop?: any;
  selectedAdds?: any;
  setselectedAdds?: any;
}

const ProductQuickView2: FC<ProductQuickView2Props> = ({
  className = "",
  onCloseModalQuickView,
  jobId,
  type = "quote",
  shop = false,
  selectedAdds = [],
  setselectedAdds
}) => {
  const router = useHistory();

  const { sizes, variants, status, allOfSizes } = PRODUCTS[0];
  const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  const [qualitySelected, setQualitySelected] = React.useState(1);

  const [value, setValue] = React.useState<any>(0);
  const [firstName, setfirstName] = useState("");
  const [errfirstName, seterrfirstName] = useState(false);

  const [images, setImages] = useState([]);

  const [description, setdescription] = useState("");

  const [errdescription, seterrdescription] = useState(false);

  const [errimages, seterrImages] = useState(false);

  const { user, setUser } = useContext(AuthContext);

  const [lopen, setlopen] = React.useState(false);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const [profiles, setprofiles] = useState<any>([]);
  const [selectedProfile, setselectedProfile] = useState<any>("");

  // const [editorData, setEditorData] = useState(type === "job" ?
  //   `<p>Dear ${jobId?.businessName},</p><p>&nbsp;</p><p>I hope this email finds you well. I am writing to express my interest in this position at ${jobId?.businessName}. Please find my attached resume for your consideration. I am excited about the opportunity to contribute my skills and experience to your team.</p><p>&nbsp;</p><p>Thank you for considering my application.</p><p>&nbsp;</p><p>Sincerely,</p><p>${user ? user?.firstName + " " + user?.lastName : "[Your Name]"},</p><p>${user ? user?.contactNumber : "[Your Contact Information]"},</p>` :
  //   type === "contract" ?
  //     `<p>Dear service ${jobId?.firstName + " " + jobId?.lastName},</p><p>&nbsp;</p><p>We are a leading service provider dedicated to delivering excellence in [insert type of service]. Whether you need [specific services offered], we've got you covered. Trust [service provider] to handle your [type of service] needs, and experience the difference firsthand.</p><p>&nbsp;</p><p>Sincerely,</p><p>${user ? user?.firstName + " " + user?.lastName : "[Your Name]"},</p><p>${user ? user?.contactNumber : "[Your Contact Information]"}
  //     `
  //     : "");

  const [editorData, setEditorData] = useState("<br/><br/><br/><br/>");

  const [errjobType, seterrjobType] = useState(false);

  const { applied, setapplied } = useContext(WishContext);

  const [showModalQuickView, setShowModalQuickView] = useState(false);

  useEffect(() => {
    if (!user) {
      // addToast("Please Login", "warning");
      router.push("/login");

      return;
    } else if (user.userType === "service provider") {
      addToast("You should login as a regular user.", "warning");
      router.push("/login");

      return;
    }
  }, [user]);

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={LIST_IMAGES_DEMO[0]}
          qualitySelected={qualitySelected}
          show={t.visible}
          sizeSelected={sizeSelected}
          variantActive={variantActive}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const submitApplictaion = () => {
    // return;

    // if (!user) {
    //   router.push("/login");
    //   return;
    // }

    // if (images.length === 0) {
    //   seterrImages(true);
    // }

    console.log("ss");

    if (!editorData) {
      seterrdescription(true);
    }

    if (!selectedProfile) {
      seterrjobType(true);
    }

    if (type === "contract") {
      if (
        !(
          user?.userType === "service seeker" ||
          user?.userType === "service provider"
        )
      ) {
        addToast(
          "You should login as a service seeker or service provider.",
          "warning"
        );
        router.push("/login");

        return;
      }

      let obj = {
        description: editorData,
        serviceId: jobId?.id,
        userId: GetUserId(),
        document: images,
        createdAt: moment().format("YYYY-MM-DD HH:mm:ss")
      };

      console.log("obj>>", obj);
  
        if (editorData && images.length > 0) {
          setlopen(true);

          postApplicationContract(obj)
            .then((res) => {
              setlopen(false);

              setbOpen(true);
              setbMsg("Quotation successfully submitted");
              setbStatus("success");

              // addToast("Quotation successfully submitted", "success");
              // onCloseModalQuickView();
              // setOpenToast(true);
              // setColor(COLORS.Primary);
              // setAlertClass("success");
              // setMsg("Successfully updated");
              // getCurrentUser();
              // seteDisable(true);
              // getUser();

              // history.push("/");
              // window.location.reload();
            })
            .catch((error) => {
              setlopen(false);
              console.log("Error", error.response);

              setbOpen(true);
              setbMsg(error.response.data.message);
              setbStatus("warning");

              // addToast(error.response.data.message, "warning");
              // setOpenToast(true);
              // setColor(COLORS.Orange);
              // setAlertClass("error");
              // setMsg(error.response.data.message);
            });
        }
      } else {
        let obj = {};

        if (shop) {
          obj = {
            description: editorData,
            adId: jobId?.id,
            userId: GetUserId(),
            // document: images,
            document: selectedProfile?.document,
            createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            memberId: selectedProfile?.id,
            selectedAdds: selectedAdds,
            language: t("langKey")
          };
        } else {
          obj = {
            description: editorData,
            adId: jobId?.id,
            userId: GetUserId(),
            // document: images,
            document: selectedProfile?.document,
            createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            memberId: selectedProfile?.id,
            selectedAdds: [jobId?.id],
            language: t("langKey")
          };
        }

        console.log("obj>>", obj);

        if (
          // editorData &&
          selectedProfile
          // images.length > 0
        ) {
          if (user?.published_ad === 1) {
            setlopen(true);
            postApplication(obj)
              .then((res) => {
                setlopen(false);

                setbOpen(true);
                setbMsg(t("appSucc"));
                setbStatus("success");
                setselectedAdds([]);

                AppliedJobs({
                  jobType: JSON.stringify([])
                })
                  .then((res) => {
                    console.log(">>>>>>>jobs", res.data);
                    setapplied(res.data.result);
                  })
                  .catch((error) => {
                    // setlopen(false);
                    setapplied([]);
                    console.log("Error", error.response);

                    // setOpenToast(true);
                    // setColor(COLORS.Orange);
                    // setAlertClass("error");
                    // setMsg(error.response.data.message);
                  });

                // addToast("Quotation successfully submitted", "success");
                onCloseModalQuickView();
                // setOpenToast(true);
                // setColor(COLORS.Primary);
                // setAlertClass("success");
                // setMsg("Successfully updated");
                // getCurrentUser();
                // seteDisable(true);
                // getUser();

                // history.push("/");
                // window.location.reload();
              })
              .catch((error) => {
                setlopen(false);
                console.log("Error", error.response);

                if (error.response.data.statusCode === 410) {
                  setShowModalQuickView(true);
                  addToast(error.response.data.message, "warning");
                } else {
                  setbOpen(true);
                  setbMsg(error.response.data.message);
                  setbStatus("warning");
                }

                // addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
              });
          } else {
            addToast(t("appFail"), "warning");
          }
        }
      }
   
  };

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {
        setbOpen(false);
      }, 10000);
    }
  }, [bOpen]);

  React.useEffect(() => {
    listProfiles();
  }, []);

  const listProfiles = () => {
    getProfiles({
      jobType: JSON.stringify([])
      // title: search,
      // jobType:JSON.stringify([])
    })
      .then((res) => {
        console.log(">>>>>>>jobs", res.data);

        const subArr = res.data.result?.map((d: any, key: any) => {
          return {
            ...d,
            key: key
            // id: d.state_code
          };
        });

        setprofiles(subArr);

        // setprofiles(res.data.result);
      })
      .catch((error) => {
        // setlopen(false);
        setprofiles([]);
        console.log("Error", error.response);
        // addToast(error.response.data.message, "warning");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  };

  return (
    <>
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        selectedJob={""}
      />
      <div
        className={`nc-ProductQuickView2 ${className} mt-16 sm:mt-0 md:px-32 py-6`}
      >
        <LoadingSpinner open={lopen} />
        {/* MAIn */}
        <div className="lg:flex">
          {/* CONTENT */}
          <div className="w-full ">
            {/* HEADING */}

            <div className="w-full flex justify-center px-3">
              <div className="w-full">
                <div className="flex justify-center w-full mt-0">
                  <span className="block text-xl font-bold flex justify-center w-full text-center">
                    {type === "job" ? t("appAd") : "Submit Quotation"}
                  </span>
                </div>

                <div className="w-full mt-6 space-y-4 mb-20">
                  <div>
                    {/* <Label className="text-sm">{t("adPro")}</Label> */}

                    <Popover className="relative">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-md border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${
                  selectedProfile
                    ? "!border-primary-500 bg-primary-50 text-primary-900"
                    : "bg-[#F4F4F4] border-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
                          >
                            <span className="ml-2 whitespace-nowrap text-gray-500 text-sm">
                              {selectedProfile
                                ? `${t("proHead")} ${selectedProfile?.key + 1}`
                                : t("adPro")}
                            </span>
                            {!selectedProfile ? (
                              <ChevronDownIcon className="w-4 h-4 ml-3" />
                            ) : (
                              <span
                                onClick={() =>
                                  // setSizesState([])
                                  setselectedProfile("")
                                }
                              >
                                {renderXClear()}
                              </span>
                            )}
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-[999999] lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                              <div className=" max-h-[200px] overflow-y-auto overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                                <div className="relative flex flex-col px-5 py-6 space-y-5">
                                  {profiles.map((item: any, key: any) => (
                                    <div key={item} className="">
                                      <Checkbox
                                        name={`${t("proHead")} ${key + 1}`}
                                        label={`${t("proHead")} ${key + 1}`}
                                        checked={selectedProfile?.key === key}
                                        // defaultChecked={categoriesState.includes(item.mainId)}
                                        sizeClassName="w-5 h-5"
                                        labelClassName="text-sm font-normal"
                                        onChange={(checked) => {
                                          setselectedProfile(item);
                                          seterrjobType(false);

                                          close();

                                          // setadDetails(item);
                                          // var data = ""

                                          // setselectedGender([item]);

                                          // setSelectedcat(item.value);

                                          // setselectedColour("");
                                          // setselectedSize("");
                                          // setCurrentPage(1);
                                          // removeLocal();
                                        }}
                                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    {/* <Autocomplete
                    className="mt-1.5"
                    // className="form-group"
                    // className="border-neutral-200"
                    disablePortal
                    // defaultValue={{ value: category }}
                    value={{ name: profiles.length > 0 && profiles.find((v: any) => v.id === selectedProfile?.id) ? profiles.find((v: any) => v.id === selectedProfile?.id).name : "" }}

                    // id="combo-box-demo"
                    options={profiles}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      setselectedProfile(value);
                      seterrjobType(false);

                      // setCountry(value);
                    }}
                    // sx={{
                    //   // width: "80vw",
                    //   background:"transparent",
                    //   borderRadius: "20px",
                    //   // border: "none",
                    //   // borderColor: "transparent",

                    //   "& .MuiInputBase-input": {
                    //     height: 15,
                    //     // border: "none",
                    //     fontSize: "14px",
                    //     fontFamily: "'Jost', sans-serif",
                    //     // borderColor: "transparent",
                    //   },
                    //   //view
                    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    //     // borderColor: "transparent",
                    //   },

                    //   "&:hover .MuiOutlinedInput-input": {
                    //     color: "black",
                    //   },
                    //   "&:hover .MuiInputLabel-root": {
                    //     color: COLORS.GrayBackground,
                    //   },
                    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    //   {
                    //     // borderColor: "transparent",
                    //   },
                    //   ".MuiAutocomplete-root fieldset:hover": {
                    //     color: "transparent",
                    //     // borderColor: "transparent",
                    //   },
                    // }}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <Input {...params.inputProps} className="" placeholder="Select Profile" />
                      </div>
                    )}
                    ListboxProps={{
                      style: {
                        maxHeight: "150px",
                        fontSize: "14px",
                        // border: '1px solid white'
                      },
                    }}
                  /> */}

                    {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setjobType(e.target.value);
                                    seterrjobType(false);
                                }}
                                value={jobType}
                            >
                                <option value={""} className="" >Select a job type</option>
                                <option value="Seasonal">Seasonal</option>
                                <option value="Casual">Casual</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Fixed">Fixed term contract</option>
                                <option value="Part Time">Permanent – Part Time</option>
                                <option value="Full Time">Permanent – Full time</option>

                            </Select> */}
                    {errjobType && (
                      <div className="text-red-600 text-sm text-right mt-2">
                        This field is required
                      </div>
                    )}
                  </div>

                  <div>
                    {/* <Textarea className="mt-1.5" rows={8}
                    placeholder="Describe anything here.."
                    onChange={(e) => {
                      setdescription(e.target.value);
                      seterrdescription(false);
                    }}
                    value={description}
                  /> */}
                    {/* <Label className="text-sm">{type === "job" ? "Description" : "Description"}</Label>
                  <CKEditor

                    editor={ClassicEditor}
                    data={editorData}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorData(data);
                      console.log({ event, editor, data });
                      seterrdescription(false);
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  />
                  {errdescription && (
                    <div className="text-red-600 text-sm text-right mt-2">
                      This field is required
                    </div>
                  )} */}
                  </div>

                  {/* <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                  <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                    {images?.map((faker: any, fakerKey: any) => (
                      <>
                        <div
                          // key={fakerKey}
                          className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                        >
                        
                          <div className="flex flex-col">
                            <img className="cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                            <span className="text-xs flex justify-center text-center">{faker?.name}</span>
                          </div>
                          <div
                            className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                          >
                            <div
                              onClick={(e) => {

                                var array = [...images]
                                array.splice(fakerKey, 1);
                                setImages(array)

                              }}
                            >
                             
                              <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                            </div>
                          </div>


                        </div>
                    
                      </>

                    ))}
                  </div>
                  <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative">
                   
                    <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                    <span className="text-primary mr-1">
                      {type === "job" ? "Upload or drag and drop CV" : "Upload or drag and drop quoatation"}
                      
                    </span>{" "}
                   
                    <input
                      id="horizontal-form-1"
                      type="file"
                      accept={"application/pdf"}
                      className="w-full h-full top-0 left-0 absolute opacity-0"
                      onChange={(e?: any) => {

                        if (images.length === 0) {
                          let file = e.target.files[0];

                          seterrImages(false);

                          let reader = new FileReader();
                          reader.readAsDataURL(file);
                          let fileInp = e.target.files[0];
                          let baseURL;
                          // on reader load somthing...
                          reader.onload = () => {
                            // Make a fileInfo Object
                            baseURL = reader.result;
                            let obj: any =
                            {
                              path: baseURL,
                              thumbnail: false,
                              name: file.name
                            }
                              ;

                            setImages(images.concat(obj))


                          };
                        }


                      }}
                    />
                  </div>

                </div>
                <span className="flex justify-end text-xs">(.pdf only)</span>
                {errimages && (
                  <div className="text-red-600 text-sm text-right mt-2">
                    This field is required
                  </div>
                )} */}

                  <div className="w-full items-center justify-center">
                    <BotToast open={bOpen} msg={bMsg} status={bStatus} />
                    <button
                      className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b] 
w-full h-9 text-slate-50 font-medium mt-4 `}
                      onClick={submitApplictaion}
                    >
                      {/* <i className="las la-pencil-alt text-xl text-left mr-2"></i> */}
                      <span className="block  text-sm font-medium ">
                        {type === "job" ? t("appNow") : "Submit the quotation"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductQuickView2;
