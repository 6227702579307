import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonPrimaryProps extends ButtonProps {}

const ButtonPrimary2: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonPrimary w-full md:w-auto text-[12.5px] disabled:bg-opacity-90 bg-[#fcfcfc] border-[0.5px] border-[#003d35] dark:bg-slate-100 hover:bg-slate-800 text-[#003d35] hover:text-slate-100 dark:text-slate-800 py-3 min-w-[175px] ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary2;
