// import rightImg from "images/hero-right1.png";
import rightImg from "images/about/Maps.png";
import React, { FC } from "react";
import SectionFounder, { People } from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionPromo3 from "components/SectionPromo3";
import NcImage from "shared/NcImage/NcImage";

export interface PageAboutProps {
    className?: string;
}

const FOUNDER_DEMO: People[] = [
    {
        id: "1",
        name: `Niamh O'Shea`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "4",
        name: `Danien Jame`,
        job: "Co-founder and Chief Executive",
        avatar:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "3",
        name: `Orla Dwyer`,
        job: "Co-founder, Chairman",
        avatar:
            "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
    {
        id: "2",
        name: `Dara Frazier`,
        job: "Co-Founder, Chief Strategy Officer",
        avatar:
            "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
    },
];


const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
    return (
        <div
            className={`nc-PageAbout overflow-hidden relative ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Shipping || Mangalya.lk</title>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            {/* <BgGlassmorphism /> */}

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-14">
                {/* <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        /> */}

                <div className="flex items-center justify-items-center justify-center grid grid-cols-1 lg:grid-cols-1 gap-10 ">

                    <div className="">

                        <h2 className="text-xl font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100">
                            SHIPPING
                        </h2>
                        <div className="text-justify">
                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-4">
                                Do you do Click and Collect?
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-2">
                                Yes we do.
                            </span>
                            <div
                                id=""
                                className="prose prose-sm !max-w-screen-2xl sm:prose lg:prose-lg mx-auto text-neutral-6000 dark:text-neutral-400"
                            >
                                <ol>
                                    <li>Add your items to the cart </li>
                                    <li>Proceed to checkout and fill in your billing details</li>
                                    <li>Select the click and collect option under shipping</li>
                                    <li>Once the order is placed, you will be given call or a text letting you know that your order is ready to be picked up.</li>
                                    <li>Then, simply come to our address and pick up your order</li>
                                </ol>
                            </div>



                            <div className="flex-grow mt-6">
                                <img className="w-full" src={rightImg} alt="" />
                            </div>
                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-6">
                                Where Does spotly Deliver To?
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                spotly delivers EVERYWHERE in The Dendenong Metro Area. However, if you like an order shipped outside of Dandenong, please contact us and we can quote a delivery.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                If your location falls under any of the following postcodes, you do not need to contact us for delivery.
                            </span>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Postcodes that we ship to:<br />
                                3002, 3004, 3067, 3101, 3102, 3103, 3104, 3105, 3106, 3107, 3108, 3109, 3111, 3113, 3114, 3121, 3122, 3123, 3124, 3125, 3126, 3127, 3128, 3129, 3130, 3131, 3132, 3133, 3134, 3135,

                                3136, 3137, 3138, 3141, 3142, 3143
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-6">
                                How Much Is The Delivery/Shipping Fee?
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                The standard shipping price for all Melbourne Metro orders is $16.95 including GST.
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-6">
                                Do You Deliver On Weekends And Public Holidays?
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Not at this stage. spotly only delivers from Monday to Friday (excluding public holidays) from 9:00am to 5:00pm. However, you can order products online at spotly 24 hours a day, 7 days a week and your order will be processed the following business day.
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-6">
                                Can I Make Changes To My Order?
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                Changes can be made to your order only if the request is made prior to your products leaving our store for delivery. Please notify us immediately of any changes to your order
                            </span>

                            <h2 className="text-base font-semibold text-neutral-900 md:text-2xl xl:text-2xl dark:text-neutral-100 mt-6">
                                My Order Has Never Arrived?
                            </h2>
                            <span className="block text-base xl:text-base text-neutral-6000 dark:text-neutral-400 mt-3">
                                If your order has not arrived within 5 working days, please contact us through the “Contact Us” page and we will follow up on your order
                            </span>

                            {/* <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8 mt-6">
                {FOUNDER_DEMO.map((item) => (
                  <div key={item.id} className="max-w-sm">
                    <NcImage
                      containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                      className="absolute inset-0 object-cover"
                      src={item.avatar}
                    />
                    <h3 className="text-base font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
                      {item.name}
                    </h3>
                    <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                      {item.job}
                    </span>
                  </div>
                ))}
              </div> */}

                        </div>
                    </div>

                </div>





                {/* <SectionFounder /> */}
                {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>

        <SectionStatistic /> */}

                {/* <SectionPromo3 /> */}
            </div>
        </div>
    );
};

export default PageAbout;
