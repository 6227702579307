import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      langKey: "En",

      hPublish: "Publish Proposal Ad",
      hPublish2: "Publish Proposal Ad",
      hLogin: "Login",
      hLogout: "Logout",
      hLang: "Select Language",

      bPrimary: "Sri Lanka's fast growing online platform for",
      bSecondary: "marriage proposals",
      bDesc: "Discover the life partner with just a few clicks.",
      bButton1: "Register as a Proposal Seeker/Advertiser",
      bButton2: "Register as a Wedding Service Provider",
      bLeftCardTitle: "Looking For",
      bLeftCardDesc: "Marriage Proposals",
      bRightCardDesc: "Wedding Service Providers",

      signUp: "Sign Up",
      signUpAcc: "Select Account Type",
      signUpEmail: "Email Address",
      signUpPwrd: "Create Password",
      signUpAlready: "Already have an account?",
      signUpAlreadyText: "Sign in",

      signIn: "Sign In",
      signInForgot: "Forgot password?",

      logCap1: "New user?",
      logCap2: "Create an account",

      pTitleAcc: "Details of the Account Owner",
      pDescAcc:
        "These details are for office use only and will not be available to the public.",
      pTitleSeek: "Details of the Proposal Seeker",
      pDescSeek:
        "The following details will appear in the proposal advertisement.",
      pButton1: "Save & Add Another Proposal Seeker (Optional)",
      pButton2: "Save & Create a Proposal Advertisement",
      pButtonBottom: "Publish your ad",

      pButtonSeeker: "Save & Add Another Proposal Seeker",
      pButtonSeeker2: "Save & Create a Proposal Advertisement",

      Save: "Save",

      fType: "Type",
      fHei: "Height",
      fOcc: "Occupation",
      fHor: "Horroscope",
      fGender: "Gender",
      fGtype: "Bride/Groom",
      fAge: "Age",
      fCast: "Cast",
      fJob: "Occupation/Job",
      fHorr: "Horroscope Status",
      fRace: "Race",
      fRel: "Religion",
      fEdu: "Educational Status",
      fMarr: "Marriage Staus",
      fDis: "District",
      fCity: "City",
      fCountry: "Current Country of the Account Owner",
      fCountryTwo: "Current Country of the Proposal Seeker",
      fCountryFilter: "Current Country of the Proposal Seeker",
      fHeight: "Height (feet & inch)",
      fiContact: "Contact number",
      fAddress: "Address",
      fWtsap: "Whatsapp number",
      fFam: "Family Background",
      fFeet: "Feet",
      fInch: "Inch",
      fSort: "Sort order",

      pTitle: "Title",
      pAdd: "Current Address",
      pAcc: "Name of the account owner",
      pCreate: "Create Profile",
      pSuccess: "Profile successfully added",
      pSalu: "Mr/Mrs/Miss",

      adTitle: "My Ads Summary",
      adButton: "Create my advertisement",
      adPkg: "Pricing",
      adPkg1: "Starter",
      adPkg1Title:
        "Choose the following paid ad options to boost your ad's visibility.  (Optional)",
      adMain: "Publish your advertisement for FREE for up to 12 months.",
      adMain2: "Publish your advertisement for USD 20 for up to 12 months.",
      adPkg1Desc:
        "You will receive notifications via SMS or email when your ad receives responses. Easily access and view all replies by logging into your account and navigating to the 'Replies' section.",
      adPkg1Desc2:
        "The published advertisement is valid for a period of 1 month",
      adPkg2: "Basic",
      adPkg2Desc:
        "Enhance visibility by featuring your ad at the top of search results for a period of 3 months",
      adPkg2Desc2:
        "The published advertisement is valid for a period of 3 months",
      adPkg3: "Plus",
      adPkg3Desc:
        "Enhance visibility by featuring your ad at the top of search results for a period of 6 months",
      adPkg3Desc2:
        "The published advertisement is valid for a period of 6 months",
      adPro: "Select Profile",
      adButton2: "Checkout",
      adSucc: "Ad successfully added",
      adUp: "Profile succesfully updated",

      hButton: "Search most matching proposals",
      hButton2: "Search service provider",
      hView: "View More Details",
      hButton3: "Search for Sinhala proposals",
      hButton4: "Search for English proposals",

      fContact: "Contact Us",
      fEmail: "Email Us",
      fAdd: "Address",
      fOther: "Other",
      fUse: "Quick Links",
      fSocial: "Social",
      fPrivacy: "Privacy Policy",
      fAddVal:
        "First floor, Capital Trust Tower 3, No 92 Glennie St, Colombo 02.",

      fTesti: "Testimonials",
      fTestiSub:
        "Love stories that began with us are our greatest pride. Discover what couples have to say about their journey to finding their perfect match through our platform.",
      fSucs: "Success Stories",
      fSucsSub:
        "These real-life testimonials highlight how our platform has made a meaningful impact, turning dreams into reality and bringing hearts together across distances and cultures.",
      fTestiButton: "Write a review",

      revTitle: "Title of your review",
      revDesc: "Write Your Review",
      revRate: "How would you rate the marketplace ?",
      reWords: "characters",

      hProfiles: "Profiles",
      hAds: "Ads",
      hReps: "Replies",
      hChgPwrd: "Change Password",

      pwOld: "Old Password",
      pwNew: "New Password",
      pwConf: "Confirm Password",

      proHead: "Profile",

      repHead: "List of Replies",
      repSelect: "Select the proposal seeker",

      repCountry: "Country",
      repTitle: "Contact Details",
      repOwnerName: "Contact Person",
      repOwnerContact: "Contact Number",
      repOwnerType: "Contact Person Type",

      appDetails: "Reply Your Details",
      appAd: "Reply Your Details",
      appNow: "Reply Your Details Now",
      appSucc: "Your Details Successfully Submitted",
      appFail: "Please Publish and Add before applying",

      ownerType: "Account Owner's Type",

      verify: "Verify",

      resetPwrd: "Reset Password",
      newPwrd: "Enter New Password",
      oldPwrd: "Re enter New Password",
      agree:
        "I confirm that all the information provided here is accurate and truthful. I understand that providing any false, misleading, or inaccurate information may result in the permanent removal of my account by Mangalya.lk.",

      titleOTP: "Enter Your OTP",
      capOTP: "",
      butOTP: "Verify OTP",

      appPkg: "Select Package",
      featureOneTitle: "100% Privacy Guaranteed",
      featureOneDes:
        "Your personal information is fully protected, ensuring complete confidentiality.",
      featureTwoTitle: "Post Ads for Free",
      featureTwoDes:
        "Enjoy the convenience of posting your marriage proposals at no cost.",
      featureThreeTitle: "Save 95% of Your Time",
      featureThreeDes:
        "Our advanced search tools drastically reduce the time it takes to find the perfect match.",
      featureFourTitle: "No Stamp & Postal Charges",
      featureFourDes:
        "Say goodbye to traditional fees like stamps and postal charges everything is handled online.",
      featureFiveTitle: "Instant Responses",
      featureFiveDes:
        "No more waiting around receive proposals and replies in real-time.",
      featureSixTitle: "Bilingual Support",
      featureSixDes:
        "Easily create and post advertisements in both Sinhala and English.",
      featureSevenTitle: "Global Reach",
      featureSevenDes:
        "Post and manage your proposals from anywhere in the world, with the flexibility to respond from any location.",
      featureEightTitle: "Multiple Ads, One Platform",
      featureEightDes:
        "Effortlessly manage and post multiple marriage proposals at once.",

      fFooterDes: "Sri Lanka's fast growing platform for marriage proposals"
    }
  },
  pl: {
    translation: {
      langKey: "Si",

      hPublish: "මංගල දැන්වීම පල කරන්න/ලියාපදිංචි වීමට",
      hPublish2: "මංගල දැන්වීම පල කරන්න",
      hLogin: "ගිණුමට පිවිසෙන්න",
      hLogout: "ගිණුමෙන් ඉවත් වන්න",
      hLang: "භාශාව තෝරන්න",

      bPrimary: "ශ්‍රී ලංකාවේ වඩාත් වේගයෙන් ජනප්‍රිය වන ඔන්ලයින්",
      bSecondary: "මංගල දැන්වීම් පිටුව",
      bDesc: "ඔබේ ජීවන සහකරු  වැඩි ඈතක නොවේ",
      bButton1: "මංගල යෝජනා පලකිරීමට/සෙවීමට ලියාපදිංචි වන්න",
      bButton2: "මංගල සේවා සපයන්නෙකු ලෙස ලියාපදිංචි වන්න",
      bLeftCardTitle: "",
      bLeftCardDesc: "මංගල දැන්වීම් වෙත පිවිසෙන්න",
      bRightCardDesc: "මංගල සේවා සපයන්නන්",

      signUp: "ලියාපදිංචි වන්න",
      signUpAcc: "ඔබට අදාල ගිණුම් වර්ගය තෝරන්න",
      signUpEmail: "විද්‍යුත් ලිපිනය",
      signUpPwrd: "රහස් කේතනය (Password)",
      signUpAlready: "දැනටමත් ඔබ ලියාපදිංචි වී සිටීද?",
      signUpAlreadyText: "එසේනම් ගිණුමට ඇතුලත් වන්න.",

      signIn: "ගිණුමට ඇතුලත් වන්න",
      signInForgot: "රහස් කේතනය (Password) අමතකයි",

      logCap1: "නව පරිශීලකයෙක්?",
      logCap2: "ලියාපදිංචි වන්න",

      pTitleAcc: "ගිණුම විවෘත කරන්නාගේ විස්තර",
      pDescAcc:
        "පහත ඇතුලත් කරනු ලබන තොරතුරු කාර්යාල භාවිතය සඳහා පමණක් වන අතර මංගල දැන්වීමේ අන්තර්ගත නොවේ.",
      pTitleSeek: "විවාහ අපේක්ෂකයාගේ විස්තර",
      pDescSeek: "පහත ඇතුලත් කරනු ලබන තොරතුරු මංගල දැන්වීමේ අන්තර්ගත වේ.",
      pButton1:
        "තොරතුරු සුරක්ෂිත කර අනෙක් විවාහ යෝජකයාගේ විස්තර ඇතුලත් කිරීමට (අනිවාර්ය නොවේ)",
      pButton2: "තොරතුරු සුරක්ෂිත කර මංගල දැන්වීම පලකරන්න",
      pButtonBottom: "දැන්වීම පළ කරන්න",

      pButtonSeeker:
        "තොරතුරු සුරක්ෂිත කර අනෙක් විවාහ යෝජකයාගේ විස්තර ඇතුලත් කිරීමට",
      pButtonSeeker2: "තොරතුරු සුරක්ෂිත කර මංගල දැන්වීම පලකරන්න",

      Save: "තොරතුරු සුරක්ෂිත කරන්න",

      fType: "ස්වභාවය",
      fHei: "උස",
      fOcc: "රැකියාව",
      fHor: "කේන්දරය",
      fGender: "සහකරු/සහකාරිය",
      fGtype: "සහකරු/සහකාරිය",
      fAge: "වයස",
      fCast: "කුලය",
      fJob: "රැකියාව",
      fHorr: "කේන්දරයේ ස්වභාවය",
      fRace: "ජාතිය",
      fRel: "ආගම",
      fEdu: "අධ්‍යාපන මට්ටම",
      fMarr: "අවිවාහක/දික්කසාද",
      fDis: "දිස්ත්‍රික්කය",
      fCity: "නගරය",
      fCountry: "ගිණුම විවෘත කරන්නාගේ රට",
      fCountryTwo: "විවාහ අපේක්ශකයාගේ රට",
      fCountryFilter: "විවාහ අපේක්ශකයාගේ රට",
      fHeight: "උස (අඩි සහ අඟල්)",
      fiContact: "දුරකථන අංකය",
      fAddress: "ලිපිනය",
      fWtsap: "වට්සැප් අංකය",
      fFam: "පවුල් පසුබිම",
      fFeet: "අඩි",
      fInch: "අඟල්",
      fSort: "අනුපිළිවෙල",

      pTitle: "ශීර්ශය",
      pAdd: "පදිංචි ලිපිනය",
      pAcc: "ගිණුම විවෘත කරන්නාගේ නම",
      pCreate:
        "දැන්වීම පලකිරීමට පෙර අනෙකුත් තොරතුරු ඇතුලත්කර ගිණුම සම්පූර්ණ කරන්න",
      // "pSuccess": "ගිණුම සම්පූර්ණ කිරීම සාර්ථකයි. දැන් දැන්වීම පලකරන්න. ඔබට සරිලන පැකේජය තෝරාගන්න",
      pSuccess: "ගිණුම සම්පූර්ණ කිරීම සාර්ථකයි.",
      pSalu: "මයා/මිය/මෙනවිය",

      adTitle: "දැන්වීම්",
      adButton: "දැන්වීම පලකරන්න",
      adPkg: "පැකේජ",
      adPkg1: "මාස දොලහක දැන්වීමක්",
      adPkg1Title:
        "ඔබගේ දැන්වීම ඉහලින්ම  පලකර ගැනීම සදහා සුදුසු පැකේජයක් තෝරාගන්න.(අනිවාර්ය නොවේ)",
      adMain: "ඔබ විසින් පළකරන  දැන්වීම මාස දොලහක කාලයක් සඳහා නොමිලේ පලකරන්න.",
      adMain2:
        "ඔබ විසින් පළකරන  දැන්වීම මාස දොලහක කාලයක් සඳහා USD 20 කට පලකරන්න.",
      adPkg1Desc:
        "දැන්වීමට අදාල පිළිතුරු ඔබගේ විද්‍යුත් ලිපිනයට/දුරකථන අංකයට යොමු කෙරේ. දැන්වීමේ ඔබ දුරකථන අංකය සඳහන් කර ඇත්නම් අදාල පාර්ශ්වයන් විසින් ඍජුවම ඔබ සම්බන්ධ කරගැනීමට ද ඉඩ පවතී",
      adPkg1Desc2: "ඔබ විසින් පලකරන ලද දැන්වීම මාස එකක කාලයක් සදහා වලංගු වේ",
      adPkg2: "මාස තුනක දැන්වීමක්",
      adPkg2Desc:
        "ඔබ විසින් පලකරන ලද දැන්වීම මාස තුනක කාලයක් සදහා ඉහලින්ම දිස්වන දැන්වීම් අතර පවතී",
      adPkg2Desc2: "ඔබ විසින් පලකරන ලද දැන්වීම මාස තුනක කාලයක් සදහා වලංගු වේ",
      adPkg3: "මාස හයක දැන්වීමක්",
      adPkg3Desc:
        "ඔබ විසින් පලකරන ලද දැන්වීම මාස හයක කාලයක් සදහා ඉහලින්ම දිස්වන දැන්වීම් අතර පවතී",
      adPkg3Desc2: "ඔබ විසින් පලකරන ලද දැන්වීම මාස හයක කාලයක් සදහා වලංගු වේ",
      adPro: "ගිණුමක් තෝරන්න",
      adButton2: "ගෙවීම් කිරීමට",
      adSucc: "ඔබගේ දැන්වීම සාර්ථකව පලකරන ලදී. සුභ මංගලම් !",
      adUp: "ගිණුම සම්පූර්ණ කිරීම සාර්ථකයි",

      hButton: "ඔබගේ තේරීමට අදාල මංගල යෝජනා සදහා මෙය ක්ලික් කරන්න",
      hButton2: "ඔබගේ තේරීමට අදාල සේවා සපයන්නෙකු සදහා ක්ලික් කරන්න",
      hView: "වැඩි විස්තර",
      hButton3: "සිංහල මංගල යෝජනා සඳහා",
      hButton4: "ඉංග්‍රීසී මංගල යෝජනා සඳහා",

      fContact: "අපව අමතන්න",
      fEmail: "විද්‍යුත් ලිපිනය",
      fAdd: "ලිපිනය",
      fOther: "වෙනත්",
      fUse: "කෙටි යෙදවුම්",
      fSocial: "සමාජ මාධ්‍ය",
      fPrivacy: "රහස්‍යතා ප්‍රතිපත්තිය",
      fAddVal:
        "First floor, Capital Trust Tower 3, No 92 Glennie St, Colombo 02.",

      fTesti: "ඇගයීම්",
      fTestiSub:
        "අපගේ වෙබ් පිටුව පිලිබඳ අදාල පරිශීලකයින් විසින් කරන ලද ඇගයීම් කිහිපයක් පහතින්",
      fSucs: "ආදරණීය හමුවීම්",
      fSucsSub:
        "අපගේ වෙබ් පිටුවේ පළවූ මංගල යෝජනා මගින් මුණගැසි සාර්ථක ලෙස යුගදිවියට එළඹී ඔබ සැමට අපගේ සුභාශිංසන !",
      fTestiButton: "ඇගයීමක් ලියන්න",

      revTitle: "ඔබගේ සමාලෝචනයේ මාතෘකාව",
      revDesc: "ඔබගේ සමාලෝචනය ලියන්න",
      revRate: "ඔබ  සමාලෝචනය ශ්‍රේණිගත කරන්නේ කෙසේද?",
      reWords: "අකුරු",

      hProfiles: "ගිණුම්",
      hAds: "දැන්වීම්",
      hReps: "පිළිතුරු",
      hChgPwrd: "රහස් කේතනය (Password) වෙනස් කරන්න",

      pwOld: "පරණ රහස් කේතනය (Password)",
      pwNew: "නව රහස් කේතනය (Password)",
      pwConf: "රහස් කේතනය (Password) තහවුරු කරන්න",

      proHead: "විවාහ අපේක්ෂක",

      repHead: "ලැබී ඇති පිළිතුරු",
      repSelect: "විවාහ අපේක්ෂකයා තෝරන්න",
      repTitle: "සම්බන්ධ කරගැනීම",
      repOwnerName: "සම්බන්ධ කරගන්නාගේ නම",
      repOwnerContact: "සම්බන්ධ කරගන්න",
      repOwnerType: "සම්බන්ධ කරගන්නාගේ ස්වභාවය",

      repCountry: "රට",

      appDetails: "පිළිතුරු යොමු කරන්න",
      appAd: "පිළිතුරු යොමු කරන්න",
      appNow: "පිළිතුරු යොමු කරන්න",
      appSucc: "පිළිතුරු යොමු කිර්‍රිම සාර්ථකයි",
      appFail: "පිළිතුරු යොමු කිරීමට පෙර මංගල දැන්වීමක් පලකරන්න",
      ownerType: "ගිණුම විවෘත කරන්නාගේ ස්වභාවය",

      verify: "තහවුරු කරගන්න",

      resetPwrd: "රහස් කේතනය (Password) වෙනස් කරන්න",
      newPwrd: "නව රහස් කේතනය (Password)",
      oldPwrd: "නැවතත් නව රහස් කේතනය (Password)",

      agree:
        "මෙහි සපයන ලද සියලුම තොරතුරු සත්‍ය සහ නිවැරදි බවට මා එකඟ වෙමි. අසත්‍ය, වංචනික හෝ වැරදි තොරතුරු ලබා දීම  මගින්  මාගේ  ගිණුම Mangalya.lk විසින් ස්ථිරවම ඉවත් කරනු ලබන බවද මා විසින් තේරුම් ගන්නා ලද බව තහවුරු කරමි",

      titleOTP: "OTP අංකය ඇතුලත් කරන්න",
      capOTP:
        "(විද්‍යුත් ලිපිනයට/දුරකතන අංකයට ලැබුණු OTP අංකය මෙහි ඇතුලත් කරන්න)",
      butOTP: "OTP අංකය තහවුරු කරගන්න",

      appPkg: "පැකේජය තෝරන්න",
      featureOneTitle: "100% ක්ම",
      featureOneDes: "ඔබගේ පෞගලිකත්වය සුරක්ෂිතයි.",
      featureTwoTitle: "100% ක්ම නොමිලයේම",
      featureTwoDes: "දැන්වීම පලකරගත හැකිවීම.",
      featureThreeTitle: "මංගල යෝජනා",
      featureThreeDes: "සෙවීමට ගතවන කාලය 95% න්ම අඩුවීම.",
      featureFourTitle: "මුද්දර කවර, මුද්දර, සහ තැපැල්",
      featureFourDes: "ගාස්තු නොමැත.",
      featureFiveTitle: "පිළිතුරු ලැබෙන තෙක්",
      featureFiveDes: "මග බලා සිටිය යුතු නැත.",
      featureSixTitle: "සිංහල සහ ඉංග්‍රීසි",
      featureSixDes: "භාෂා දෙකෙන්ම දැන්වීම් පලකිරීමේ හැකියාව.",
      featureSevenTitle: "ලෝකයේ ඕනෑම තැනක",
      featureSevenDes: "සිට දැන්වීම් පලකිරීමේ සහ පිළිතුරු යොමු කිරීමේ හැකියාව.",
      featureEightTitle: "මංගල දැන්වීම් කිහිපයක්",
      featureEightDes: "එකවර පලකිරීමේ හැකියාව.",
      fFooterDes:
        "ශ්‍රී ලංකාවේ වඩාත් වේගයෙන් ජනප්‍රිය වන ඔන්ලයින් මංගල දැන්වීම් පිටුව"
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pl",
  fallbackLng: "pl"
});

export default i18n;
