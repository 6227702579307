import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import LoadingSpinner from "shared/LoadingSpinner";

const Page404: React.FC = () => (
  <div className="">
    <Helmet>
      <title>Mangalya.lk</title>
    </Helmet>
    <LoadingSpinner open={true} />
  </div>
);

export default Page404;
