import React, { FC, useEffect, useId } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory2 from "components/CardCategories/CardCategory2New";
import PopularCategory from "components/CardCategories/PopularCategory";
import MemberCard from "components/CardCategories/MemberCard";
import department1Png from "images/brands/logo-1.png";
import department2Png from "images/brands/logo-2.png";
import department3Png from "images/brands/logo-3.png";
import department4Png from "images/brands/logo-4.png";
import department5Png from "images/brands/logo-5.png";
import department6Png from "images/brands/logo-6.png";
import department7Png from "images/brands/logo-7.png";
import { Link, NavLink } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CategoriesPopular, getWholeSalers } from "Services/API/Get";

export interface CardCategoryData {
  name: string;
  desc: string;
  img: string;
  color?: string;
}
const CATS: CardCategoryData[] = [
  {
    name: "Travel Kits",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100",
  },
  {
    name: "Beauty Products",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100",
  },
  {
    name: "Sport Kits",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department5Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department6Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department7Png,
    color: "bg-orange-100",
  },
];
export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
  setpageLimit?: any;
  pageLimit?: any;

}

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "",
  subHeading = "",
  className = "",
  itemClassName = "",
  data = [],
  setpageLimit,
  pageLimit
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  // useEffect(() => {
  //   if (data.length > 0) {



  //     const OPTIONS: Glide.Options = {
  //       perView: 5,
  //       autoplay: 3000,
  //       hoverpause: false,
  //       type: data.length > 5 ? 'carousel' : 'slider',
  //       // gap: 32,
  //       bound: true,
  //       breakpoints: {
  //         1280: {
  //           perView: 5 - 1,
  //           type: data.length > 4 ? 'carousel' : 'slider',
  //         },
  //         1024: {
  //           gap: 20,
  //           perView: 5 - 1,
  //           type: data.length > 4 ? 'carousel' : 'slider',
  //         },
  //         768: {
  //           gap: 20,
  //           perView: 4 - 1,
  //           type: data.length > 3 ? 'carousel' : 'slider',
  //         },
  //         640: {
  //           gap: 20,
  //           perView: 2,
  //           type: data.length > 2 ? 'carousel' : 'slider',
  //         },
  //         500: {
  //           gap: 8,
  //           perView: 2,
  //           type: data.length > 2 ? 'carousel' : 'slider',
  //         },
  //       },
  //     };

  //     let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
  //     slider.mount();
  //     // @ts-ignore
  //     return () => slider.destroy();

  //   }

  // }, [UNIQUE_CLASS, data]);

  const [categories, setcategories] = React.useState<any>(data);
  const [images, setimages] = React.useState<any>([]);

  React.useEffect(() => {

    // getallCategory();

  }, []);

  const getallCategory = async () => {

    getWholeSalers({
      limit: "5",
      userId: ""
    })
      .then(async (response) => {
        console.log("sproviders>>>>", response?.data);

        setcategories(response.data.result);


      })
      .catch(() => {

        setcategories([])

      });

  }

  return (
    <>
      {
        data.length > 0 &&

        <div className={`nc-SectionSliderCategories ${className}`}>
          <div
            className="w-full"
          // className={`${UNIQUE_CLASS} flow-root`}
          >
            {/* <Heading desc={subHeading} isCenter={true}
            // hasNextPrev
            >
              {heading}
            </Heading> */}
            <div
              className="glide__track"
              data-glide-el="track"
            >
              <ul
                className={`flex grid grid-cols-2 sm:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-2 gap-y-4 lg:gap-7`}

              // className="glide__slides"
              >
                {data?.map((item: any, index: any) => {
                  return (
                    <li className={`glide__slide ${itemClassName}`}>
                      <MemberCard
                        data={item}
                        providers={true}
                      // featuredImage={item.img}
                      // name={item.name}
                      // desc={item.desc}
                      // bgClass={item.color}
                      />
                    </li>

                  )
                }

                )}

              </ul>

            </div>
            {/* <NavLink to={"/service-providers"}> */}
            <div className="flex mt-4 justify-center items-center">
              <ButtonPrimary
                onClick={() => {

                  setpageLimit(pageLimit + 20);
                }}
              >See more</ButtonPrimary>
            </div>
            {/* </NavLink> */}
          </div >
        </div>


      }
    </>

  );
};

export default SectionSliderCategories;
