import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { getCompany, getTestimonial } from "Services/API/Get";
import Img from "images/Footer.png";
import COMPLOGO from "images/comp-logo.png";
import facebook from "images/facebook.png";
import instagram from "images/insta.png";
import titktok from "images/titktok.png";
import youtube from "images/youtube.png";
import linkedin from "images/linkedin.svg";
import { t } from "i18next";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: any = [
  {
    id: "5",
    title: t("fContact"),
    menus: [
      // { href: "/servicing", label: "Repairs and Servicing" },
      // { href: "/shop", label: "Shop" },
      { href: "/sellers", label: "0766 224 324" }
      // { href: "/rent", label: "Rent Machinery" },
      // { href: "/service-providers", label: "Service Provider" },
      // { href: "/contracts", label: "Cleaning Contracts" },
      // { href: "/jobs", label: "Jobs" },
    ]
  },
  {
    id: "1",
    title: t("fEmail"),
    menus: [
      // { href: "/shipping", label: "Shipping" },
      { href: "/about", label: "info@xxxx.lk" }
      // { href: "/conditions", label: "Privacy Policy" },
      // { href: "/cancellation", label: "Cancellation Policy" },
      // { href: "/faqs", label: "FAQs" },
    ]
  },
  {
    id: "1",
    title: t("fAdd"),
    menus: [{ href: "/shipping", label: "234 Devala Road Colombo 07" }]
  },
  {
    id: "2",
    title: t("fOther"),
    menus: [{ href: "/conditions", label: "Privacy Policy" }]
  }
];

const Footer: React.FC = () => {
  const [company, setcompany] = useState<any>("");
  const [lopen, setlopen] = useState<any>(false);

  const CompanyGet = () => {
    setlopen(true);
    getCompany()
      .then((res) => {
        setlopen(false);
        console.log(res.data);
        console.log("company res>>>", res.data[res.data.length - 1]);
        setcompany(res.data[res.data.length - 1]);
        // setLogo(res.data[res.data.length - 1].image)
        // this.setState({
        //   logo: res.data[res.data.length - 1].image,
        // });
      })
      .catch((error) => {
        setlopen(false);
        console.error(error);
      });
  };

  React.useEffect(() => {
    CompanyGet();
  }, []);

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div className="flex justify-start lg:justify-center">
        <div key={index} className="text-sm lg:ml-[0px]">
          <h2
            className={`${
              index === -1 ? "text-transparent" : "text-white"
            }  dark:text-[#002930] text-sm whitespace-nowrap`}
          >
            {menu.title}
          </h2>
          <ul className="mt-5 space-y-4">
            {menu.menus.map((item, index) => (
              <>
                <li key={index}>
                  {/* <NavLink
                    to={{ pathname: item.href }}
                    key={index}
                    className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold "
                  // href={item.href}
                  // target="_blank"
                  // rel="noopener noreferrer"
                  >
                    
                  </NavLink> */}
                  <div className="text-white dark:text-[#002930] dark:hover:text-slate-900 font-bold ">
                    {item.label}
                  </div>
                </li>
              </>
            ))}
            {menu?.title === "Contact Support" && (
              //     <button
              //       className={
              //         `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
              // w-full h-9 text-slate-50 font-medium mt-4 `}
              //     >
              //       {/* <i className="las la-pencil-alt text-xl text-left mr-2"></i> */}
              //       <span className="block  text-sm font-medium "

              //       >
              //         Submit a ticket
              //       </span>

              //     </button>
              <li>
                <a
                  href="https://businessdirectory.infinitycs.com.au"
                  target="_blank"
                  className="text-[#002930] dark:text-[#002930] hover:text-slate-900 dark:hover:text-slate-900"
                >
                  {/* <NavLink
                to={{ pathname: item.href }}
                key={index}
                className="text-[#002930] dark:text-[#002930] hover:text-slate-900 dark:hover:text-slate-900"
              // href={item.href}
              // target="_blank"
              // rel="noopener noreferrer"
              > */}
                  Submit a ticket
                </a>
                {/* </NavLink> */}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="nc-Footer relative py-10 md:py-20 px-6 md:px-0 lg:pt-10 lg:pb-24 dark:border-neutral-700 bg-[#ffffff]">
        <div className="container w-full flex flex-col md:flex-row md:justify-between gap-y-10 gap-x-5 sm:gap-x-8 lg:gap-x-10">
          <div className="hidden md:grid grid-cols-1 gap-1 col-span-2 md:col-span-4 lg:md:col-span-1  lg:flex lg:flex-col">
            <div className="col-span-1 md:col-span-1">
              <Logo />
            </div>
            <div className="md:w-[75%] font-[Inter] text-base md:text-xs text-[#2f3840]">
              {t("fFooterDes")}
            </div>
          </div>

          {/* {widgetMenus.map(renderWidgetMenuItem)} */}
          <div className="w-full  md:hidden">
            <SocialItems />
          </div>

          <div className="flex  md:w-[30%] justify-start xl:justify-center">
            <div
              className={`${
                t("langKey") === "Si" ? "text-base" : "text-base"
              } lg:ml-[0px]`}
            >
              <h2 className="text-black font-semibold dark:text-[#002930] whitespace-nowrap">
                {t("fUse")}
              </h2>
              <ul className="md:mt-5 mt-2 space-y-4">
                {[1].map((item, index) => (
                  <li key={index}>
                    <div className="text-[#2f3840] text-sm dark:text-[#002930] mb-2 dark:hover:text-slate-900 font-normal font-['Inter']">
                      Marriage Proposals
                    </div>
                    <div className="text-[#2f3840] text-sm dark:text-[#002930] mb-2 dark:hover:text-slate-900 font-normal font-['Inter']">
                      Wedding Service Providers
                    </div>
                    <div className="text-[#2f3840] text-sm dark:text-[#002930] mb-2 dark:hover:text-slate-900 font-normal font-['Inter']">
                      Success Stories
                    </div>
                    <div className="text-[#2f3840] text-sm dark:text-[#002930] mb-2 dark:hover:text-slate-900 font-normal font-['Inter']">
                      Testimonial
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex  md:w-[30%] justify-start xl:justify-center">
            <div
              className={`${
                t("langKey") === "Si" ? "text-base" : "text-base"
              } lg:ml-[0px]`}
            >
              <h2 className="text-black font-semibold dark:text-[#002930] whitespace-nowrap">
                {t("fOther")}
              </h2>
              <ul className="md:mt-5 mt-2  space-y-4">
                {[1].map((item, index) => (
                  <li key={index}>
                    <div className="text-[#2f3840] text-sm dark:text-[#002930] mb-2 dark:hover:text-slate-900 font-normal font-['Inter']">
                      Privacy Policy
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="flex justify-start xl:justify-center">
            <div
              className={`${
                t("langKey") === "Si" ? "text-base" : "text-base"
              } lg:ml-[0px]`}
            >
              <h2 className="text-black font-semibold dark:text-[#002930] whitespace-nowrap">
                {t("fContact")}
              </h2>
              <ul className="mt-5 space-y-4">
                {[1].map((item, index) => (
                  <li key={index}>
                    <div className="text-[#2f3840] text-sm dark:text-[#002930] mb-2 dark:hover:text-slate-900 font-normal font-['Inter']">
                      {t("fAddVal")}
                    </div>
                    <div className="text-[#2f3840] text-sm dark:text-[#002930] mb-2 dark:hover:text-slate-900 font-normal font-['Inter']">
                      {"0766 224 324"}
                    </div>
                    <div className="text-[#2f3840] text-sm dark:text-[#002930] dark:hover:text-slate-900 font-normal font-['Inter']">
                      {"info@mangalya.lk"}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className=" hidden md:block">
            <SocialItems />
          </div>
        </div>
      </div>
      <div className="w-full bg-[#f4f4f4] py-1 px-8 md:px-0 ">
        <div className="flex flex-col w-full justify-center items-center  pb-1">
          <a
            href={"https://infinitixx.com"}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={COMPLOGO} className="h-[40px] cursor-pointer mt-2" />
          </a>
        </div>
      </div>
    </div>
  );
};

const SocialItems = () => {
  return (
    <div className="flex justify-start xl:justify-center">
      <div
        className={`${
          t("langKey") === "Si" ? "text-base" : "text-base"
        } lg:ml-[0px]`}
      >
        <h2 className="text-black font-semibold dark:text-[#002930] whitespace-nowrap">
          {t("fSocial")}
        </h2>
        <ul className="mt-5 space-y-4">
          <div className="flex space-x-5">
            <a
              href="https://www.facebook.com/profile.php?id=61551660879692&mibextid=ZbWKwL"
              target="_blank"
            >
              <div className="w-[52px] h-[52px] md:w-10 md:h-10 rounded-full flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
                <img src={facebook} alt="" />
              </div>
            </a>

            <a
              href="https://instagram.com/tidytraderr.au?igshid=NTc4MTIwNjQ2YQ=="
              target="_blank"
            >
              <div className="w-[52px] h-[52px] md:w-10 md:h-10 rounded-full flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
                <img src={instagram} alt="" />
              </div>
            </a>

            <div className="w-[52px] h-[52px] md:w-10 md:h-10 rounded-full flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
              <img src={titktok} alt="" />
            </div>
            <div className="w-[52px] h-[52px] md:w-10 md:h-10 rounded-full flex items-center justify-center text-slate-100 hover:bg-white hover:text-slate-900 cursor-pointer">
              <img src={youtube} alt="" />
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
