import React, { FC, useEffect, useId, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory2 from "components/CardCategories/CardCategory2New";
import PopularCategory from "components/CardCategories/PopularCategory";
import ReviewCard from "components/CardCategories/ReviewCard";
import department1Png from "images/brands/logo-1.png";
import department2Png from "images/brands/logo-2.png";
import department3Png from "images/brands/logo-3.png";
import department4Png from "images/brands/logo-4.png";
import department5Png from "images/brands/logo-5.png";
import department6Png from "images/brands/logo-6.png";
import department7Png from "images/brands/logo-7.png";
import { Link } from "react-router-dom";
import ModalQuickView from "components/ModalReview";
import { GetSiteReviews, getWholeSalers } from "Services/API/Get";
import { t } from "i18next";
import Heading3 from "components/Heading/Heading3";

export interface CardCategoryData {
  name: string;
  desc: string;
  img: string;
  color?: string;
}
const CATS: CardCategoryData[] = [
  {
    name: "Travel Kits",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100"
  },
  {
    name: "Beauty Products",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100"
  },
  {
    name: "Sport Kits",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100"
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100"
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department5Png,
    color: "bg-orange-100"
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department6Png,
    color: "bg-orange-100"
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department7Png,
    color: "bg-orange-100"
  }
];
export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
  Reviews?: any;
}

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "Shop by department",
  subHeading = "",
  className = "",
  itemClassName = "",
  data = [],
  Reviews
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const [showModalQuickView, setShowModalQuickView] = useState(false);
  const [results, setresults] = React.useState<any>(data);
  const [images, setimages] = React.useState<any>([]);

  useEffect(() => {
    if (data.length > 0) {
      const OPTIONS: Glide.Options = {
        perView: 4,
        gap: 10,
        autoplay: 3000,
        hoverpause: false,
        type: data.length > 5 ? "carousel" : "slider",
        bound: true,
        breakpoints: {
          1280: {
            perView: 4,
            type: data.length > 4 ? "carousel" : "slider"
          },
          1024: {
            gap: 20,
            perView: 3,
            type: data.length > 3 ? "carousel" : "slider"
          },
          768: {
            gap: 20,
            perView: 2,
            type: data.length > 2 ? "carousel" : "slider"
          },
          640: {
            gap: 20,
            perView: 1,
            type: data.length > 1 ? "carousel" : "slider"
          },
          500: {
            // gap: 20,
            perView: 1,
            type: data.length > 1 ? "carousel" : "slider"
          }
        }
      };

      let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
      slider.mount();
      // @ts-ignore
      return () => slider.destroy();
    }
  }, [UNIQUE_CLASS, results, data]);

  React.useEffect(() => {
    // getallCategory();
    Reviews();
  }, [showModalQuickView]);

  const getallCategory = async () => {
    GetSiteReviews({
      limit: "5"
    })
      .then(async (response) => {
        console.log("reviews>>>>", response?.data);

        setresults(response.data);
      })
      .catch(() => {
        setresults([]);
      });
  };

  return (
    <>
      {
        // data.length > 0 &&

        <div
        className={`nc-SectionSliderCategories px-6 md:px-0 ${className} relative z-[1] `}
      >
        <div className={`${UNIQUE_CLASS} flow-root`}>
          <Heading3
            desc={subHeading}
            hasNextPrev
            isCenter={true}
            className="mb-2 px-8 lg:mb-14 text-[#2f3840]"
              className2={"text-[#002930]"}
              fontClass={` text-white ${ 
                t("langKey") === "Si" ? "text-2xl md:text-3xl " : "text-2xl md:text-3xl"
              }  font-extrabold`}
            >
              {heading}
              {/* Revealing the Resounding Voices of <br /> Delight and Satisfaction */}
            </Heading3>
            <div className="glide__track mt-16" data-glide-el="track">
              <ul className="glide__slides md:pr-2">
                {data.map((item: any, index: any) => (
                  <li key={index} className={`glide__slide ${itemClassName}`}>
                    <ReviewCard
                      data={item}
                      featuredImage={item.img}
                      // name={item.name}
                      // desc={item.desc}
                      bgClass={item.color}
                    />
                  </li>
                ))}
     
              </ul>
            </div>
            <div className="flex w-full mt-4 md:mt-6 items-center justify-center">
              <button
                className={`ttnc-ButtonCircle w-full md:w-auto rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 border-[1px] border-white
       py-3 px-12 font-medium mt-5 `}
                onClick={() => {
                  setShowModalQuickView(true);
                }}
              >
                {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                <span
                  className={`block ${
                    t("langKey") === "Si" ? "text-xs" : "text-xs"
                  } font-normal text-white`}
                >
                  {t("fTestiButton")}
                </span>
              </button>
            </div>
          </div>
        </div>
      }

      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        type={"site"}
      />
    </>
  );
};

export default SectionSliderCategories;
