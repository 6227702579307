import React, { FC, useState, useEffect, useRef, Fragment, useContext } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";
import _, { lowerCase, map, upperFirst } from "lodash";
import { postAds, postApplicationPay, postJobs } from "Services/API/Post";
import { addToast } from "shared/Toast";
import { CurrentUser, GetUserId, getCities, getDistricts, getJobDetails, getOrderDetails, getProfiles, getUserIp, getUserLocation } from "Services/API/Get";
import { Button, Grid, TextField, Typography, Autocomplete, Box } from "@mui/material";
import { UpdateAds, UpdateJobs, UpdatePackage } from "Services/API/Put";
import { useHistory } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import moment from "moment";
import SubData from "data/australian_postcodes.json";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "shared/LoadingSpinner";
import DatePicker from "react-multi-date-picker"
import BotToast from "shared/BotToast";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import JobsCard from "components/CardCategories/JobsCard";
import { t } from "i18next";

import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import WishContext from "Context/WishContext";
import ModalQuickView from "components/ModalProfile";


const theme = createTheme({
    palette: {
        primary: {
            main: "#2d867b",
        },
        secondary: {
            main: "#2d867b",
        },
    },
});

const CATEG = [{
    value: "Seasonal",
    name: "Seasonal"
},
{
    value: "Casual",
    name: "Casual"
},
{
    value: "Temporary",
    name: "Temporary"
},
// {
//     value: "Fixed",
//     name: "Fixed"
// },
{
    value: "Part Time",
    name: "Permanent – Part Time"
},
{
    value: "Full Time",
    name: "Permanent – Full time"
},


]



const Jobs = ({ selectedJob = null, onCloseModalQuickView }) => {


    const { lan, setLan } = useContext(WishContext);

    const [images, setImages] = useState([]);

    const [title, settitle] = useState(selectedJob ? selectedJob?.language === "En" ? "English" : selectedJob?.language === "Si" ? "Sinhala" : "" : "");
    const [jobType, setjobType] = useState(selectedJob ? selectedJob.jobType : "");
    const [country, setcountry] = useState(selectedJob ? selectedJob.country : "Australia");
    const [homeDistrict, sethomeDistrict] = useState(selectedJob ? selectedJob.state : "");
    const [homeCity, sethomeCity] = useState(selectedJob ? selectedJob.suburb : "");
    const [duration, setduration] = useState(selectedJob ? selectedJob.duration : "");
    const [description, setdescription] = useState(selectedJob ? selectedJob.description : "");
    const [currency, setcurrency] = useState(selectedJob ? selectedJob.currency : "AUD");
    const [rate, setrate] = useState(selectedJob ? selectedJob.hourlyRate : "");

    let day = moment().add(365, "days")
    day = moment(day).format("YYYY-MM-DD")

    console.log(">>>>>>>day", moment(day).toDate())
    // setexpire(moment(day).toDate());

    const [expire, setexpire] = useState(selectedJob ? moment(selectedJob.expiryDate).toDate() : moment(day).toDate());

    const [expireVisibility, setexpireVisibility] = useState(selectedJob?.expireVisibility ? moment(selectedJob?.expireVisibility).toDate() : "");

    const [Keywords, setKeywords] = useState("");

    const [oldPkg, setoldPkg] = useState(selectedJob ? selectedJob?.packageId?.toString() : "");

    const [errimages, seterrImages] = useState([]);

    const [errtitle, seterrtitle] = useState(false);
    const [errjobType, seterrjobType] = useState(false);
    const [errcountry, seterrcountry] = useState(false);
    const [errsuburb, seterrsuburb] = useState(false);
    const [errduration, seterrduration] = useState(false);
    const [errdescription, seterrdescription] = useState(false);
    const [errcurrency, seterrcurrency] = useState(false);
    const [errrate, seterrrate] = useState(false);
    const [errexpire, seterrexpire] = useState(false);
    const [errKeywords, seterrKeywords] = useState(false);
    const [errhomeDistrict, seterrhomeDistrict] = useState(false);
    const [errhomeCity, seterrhomeCity] = useState(false);

    const [homeDistrictId, sethomeDistrictId] = useState(0);
    const [allHCities, setallHCities] = useState([]);
    const [allHDistricts, setallHDistricts] = useState([]);

    const [currUser, setcurrUser] = useState("");

    const [packageId, setpackageId] = useState(selectedJob ? selectedJob?.packageId.toString() : "1");

    const [lOpen, setlOpen] = useState(false);

    const [errpackages, seterrpackages] = useState(false);

    const history = useHistory();

    const [newSubArray, setnewSubArray] = useState([]);
    const [newSubs, setnewSubs] = useState([]);

    const [bOpen, setbOpen] = useState(false);
    const [bMsg, setbMsg] = useState("");
    const [bStatus, setbStatus] = useState("");

    const ref = useRef(null);

    const [editorData, setEditorData] = useState(selectedJob.duties ? selectedJob.duties : "<br/><br/><br/><br/><br/>");

    const [newSubs2, setnewSubs2] = useState([]);

    const [profiles, setprofiles] = useState([]);
    const [selectedProfile, setselectedProfile] = useState(selectedJob ? { id: selectedJob?.memberId, name: selectedJob.title } : "");


    const handleScroll = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: "center", });
    };

    const [adDetails, setadDetails] = useState("");

    const [ipCountry, setipCountry] = useState(selectedJob ? selectedJob?.currentResidesCountry : "Other");

    const [policy1, setpolicy1] = useState(true);

    const [showModalQuickView, setShowModalQuickView] = useState(false);

    const [selectedAd, setselectedAd] = useState("");


    useEffect(() => {
        if (!selectedJob)
            settitle(lan)
    }, [lan, selectedJob])



    React.useEffect(() => {

        let newSubArray = JSON.parse(JSON.stringify(SubData));

        newSubArray = newSubArray?.map((d) => {
            if (d.type === "Delivery Area") {

                return {
                    ...d,
                    locality: upperFirst(lowerCase(d.locality)),
                };

            }

        });

        newSubArray = _.compact(newSubArray)

        setnewSubArray(_.orderBy(newSubArray, ['locality'], ['asc']));

        console.log(">>>>>>>subs", newSubArray.filter((u) => u.state === 0))


    }, []);

    const packageSelect = (item) => {

        console.log("pkg>>>>", item)

        let obj = {}

        if (item === "1") {

            obj = {
                email: currUser?.email,
                amountPayable: "FREE",
                packageDetails: [
                    {
                        packagePrice: "FREE",
                        qty: 1,
                        packageName: "FREE" + " " + "Subscription",
                        days: 7
                    }
                ],
                packageId: 1,
                userId: currUser?.id,
            }


        } else if (item === "2") {

            obj = {
                email: currUser?.email,
                amountPayable: "15",
                packageDetails: [
                    {
                        packagePrice: "15",
                        qty: 1,
                        packageName: "Basic" + " " + "Subscription",
                        days: 28

                    }
                ],
                packageId: 2,
                userId: currUser?.id,
            }



        } else if (item === "3") {

            obj = {
                email: currUser?.email,
                amountPayable: "30",
                packageDetails: [
                    {
                        packagePrice: "30",
                        qty: 1,
                        packageName: "Plus" + " " + "Subscription",
                        days: 84
                    }
                ],
                packageId: 2,
                userId: currUser?.id,
            }


        }

        // let obj = {
        //     email: currUser?.email,
        //     amountPayable: item.pricing.split("$")[1],
        //     packageDetails: [
        //         {
        //             packagePrice: item.pricing.split("$")[1],
        //             qty: 1,
        //             packageName: item.name + " " + "Subscription",
        //         }
        //     ],
        //     packageId: item.id,
        //     userId: currUser?.id,
        // }

        console.log("obj>>>", obj)

        setlOpen(true);

        UpdatePackage(obj, currUser?.id)
            .then((res) => {

                console.log("res>>>>", res.data)

                if (res.data !== undefined) {
                    if (res.data === "success") {
                        history.push("/profile");
                    } else {
                        window.location.href = res.data;
                    }

                }

            })
            .catch((error) => {
                setlOpen(false);
                console.log("error", error);
                console.log("current user error", error.response.data.message);
                if (error.response.data.statusCode === 401) {
                    history.push("/login");
                }
            });

    }

    // React.useEffect(() => {

    //     if (packageId) {
    //         packageSelect(packageId);
    //     }

    // }, [packageId]);

    React.useEffect(() => {
        listDistricts();
        getCurrentUser();
        // listProfiles();

    }, []);


    React.useEffect(() => {
        listProfiles();

    }, [selectedJob, showModalQuickView]);


    React.useEffect(() => {
        if (homeDistrictId !== 0) {
            listHomecities();
        }
    }, [homeDistrictId]);

    useEffect(() => {

        // getUserIp()
        //     .then((res) => {
        //         console.log(">>>>>>ip", res)

        //         getUserLocation(res?.ip)
        //             .then((res) => {
        //                 console.log(">>>>>>ip location", res)

        //                 if (res?.data.country_name === "Sri Lanka") {

        //                     setipCountry("Sri Lanka")
        //                     setpackageId("1");

        //                 } else {

        //                     setipCountry(res?.data.country_name)
        //                     setpackageId("4");

        //                 }

        //             })
        //             .catch((error) => {

        //                 // setprofiles([]);
        //                 console.log("Error", error.response);

        //             })
        //     })
        //     .catch((error) => {

        //         // setprofiles([]);
        //         console.log("Error", error.response);

        //     })

    }, [])


    const listProfiles = () => {
        getProfiles({
            jobType: JSON.stringify([]),
            // title: search,
            // jobType:JSON.stringify([])
        })
            .then((res) => {
                console.log(">>>>>>>jobs", res.data);

                const subArr = res.data.result?.map((d, key) => {
                    return {
                        ...d,
                        key: key,
                        // id: d.state_code
                    };
                });

                setprofiles(subArr);


                if (selectedJob) {
                    setselectedProfile(res.data.result.find((v) => v.id === selectedProfile?.id))
                    setselectedAd(res.data.result.find((v) => v.id === selectedProfile?.id))
                    setadDetails(res.data.result.find((v) => v.id === selectedProfile?.id))
                }

                if (selectedAd) {
                    setselectedAd(res.data.result.find((v) => v.id === selectedAd?.id))
                    setadDetails(res.data.result.find((v) => v.id === selectedAd?.id))
                }
            })
            .catch((error) => {
                // setlopen(false);
                setprofiles([]);
                console.log("Error", error.response);
                // addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    };

    const listDistricts = () => {
        getDistricts()
            .then((res) => {
                console.log("districts>>>", res);
                setallHDistricts(res.data);
                // setallODistricts(res.data);
            })
            .catch((error) => {
                console.log("Error", error.response);
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };
    const listHomecities = () => {
        getCities(homeDistrict)
            .then((res) => {
                console.log("cities>>>", res.data.records);
                setallHCities(res.data.records);
            })
            .catch((error) => {
                console.log("Error", error.response);
                setallHCities([])
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            });
    };

    const handleChangeHdist = (e, value) => {
        if (value !== null) {
            sethomeDistrict(value.name_en);
            seterrhomeDistrict(false);
            sethomeDistrictId(value.id);

        } else {
            sethomeDistrict("");
            sethomeDistrictId(1);
        }

        console.log("state>>", value);
        // setnewSubs(newSubArray.filter((u) => u.state === value.id))

        setnewSubs([])

        const filtered = newSubArray.filter((u) => u.state === value.id)

        const perChunk = 700

        const result = filtered.reduce((filtered, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!filtered[chunkIndex]) {
                filtered[chunkIndex] = [] // start a new chunk
            }

            filtered[chunkIndex].push(item)

            return filtered
        }, [])

        console.log("<<<<<<<", result)

        for (let i = 0; i < result.length - 1; i++) {

            let item = result[i];

            setTimeout(() => {
                setnewSubs(existing => [...existing, ...item]);
            }, i === 0 ? 0 : (2000));
        }


    };
    const handleChangeHcity = (e, value) => {
        if (value !== null) {
            // console.log(">><<", value.fields.scc_name)
            sethomeCity(value);
            // sethomepostCode(value.postcode);
            // sethomeCity(value.fields.scc_name);
            // sethomepostCode(value.postcode);
            seterrhomeCity(false);
            // seterrhomepostCode(false);
        } else {
            sethomeCity("");
            // sethomepostCode("");

        }

        console.log("city>>", value);
    };


    const saveJob = async (e) => {

        e.preventDefault();

        // if (!description) {
        //     seterrdescription(true);
        // }

        if (!selectedProfile) {
            seterrjobType(true);
        }
        if (!packageId) {
            seterrpackages(true);
        }

        let obj2 = {}

        let item = packageId

        if (item === "1") {

            obj2 = {
                email: currUser?.email,
                amountPayable: "FREE",
                packageDetails: [
                    {
                        packagePrice: "FREE",
                        qty: 1,
                        packageName: "FREE" + " " + "Subscription",
                        days: 365,
                        priority: "z",
                    }
                ],
                packageId: 1,
                userId: currUser?.id,
                priority: "z",

            }


        } else if (item === "2") {

            obj2 = {
                email: currUser?.email,
                amountPayable: "50",
                packageDetails: [
                    {
                        packagePrice: ipCountry === "Sri Lanka" ? "0.66" : "20",
                        qty: 1,
                        packageName: "Standard" + " " + "Subscription",
                        days: 90,
                        priority: ipCountry === "Sri Lanka" ? "a" : "y",
                        applications: 10,

                    }
                ],
                packageId: 2,
                userId: currUser?.id,
                priority: ipCountry === "Sri Lanka" ? "a" : "y",
                price: ipCountry === "Sri Lanka" ? "200 LKR" : "20 USD",
                applications: 10,
            }



        } else if (item === "3") {

            obj2 = {
                email: currUser?.email,
                amountPayable: "90",
                packageDetails: [
                    {
                        packagePrice: ipCountry === "Sri Lanka" ? "1.63" : "50",
                        qty: 1,
                        packageName: "Basic" + " " + "Subscription",
                        days: 180,
                        priority: ipCountry === "Sri Lanka" ? "a" : "y",
                        applications: 15,
                    }
                ],
                packageId: 3,
                userId: currUser?.id,
                priority: ipCountry === "Sri Lanka" ? "a" : "y",
                price: ipCountry === "Sri Lanka" ? "500 LKR" : "50 USD",
                applications: 15,

            }


        } else if (item === "4") {

            obj2 = {
                email: currUser?.email,
                amountPayable: "90",
                packageDetails: [
                    {
                        packagePrice: ipCountry === "Sri Lanka" ? "2.93" : "90",
                        qty: 1,
                        packageName: "Plus" + " " + "Subscription",
                        days: 180,
                        priority: ipCountry === "Sri Lanka" ? "a" : "y",
                        applications: 20,
                    }
                ],
                packageId: 3,
                userId: currUser?.id,
                priority: ipCountry === "Sri Lanka" ? "a" : "y",
                price: ipCountry === "Sri Lanka" ? "900 LKR" : "90 USD",
                applications: 20,

            }

        }


        let orderMyNo = 100000;

        await getJobDetails()
            .then((res) => {
                console.log("res>>>", res)
                orderMyNo = parseInt(res.data[res.data.length - 1]?.id) + 1;
            })
            .catch((error) => {
                console.error("order history error", error.response);
            });

        let obj = {
            adId: !selectedJob ? "" : selectedJob?.id,
            title: selectedProfile?.name,
            userId: GetUserId(),
            memberId: selectedProfile?.id,
            description: description,
            duties: editorData,
            createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            email: currUser?.email,
            packageUpdatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            packageDetails: obj2,
            oldPkg: oldPkg,
            expiryDate: moment(expire).format("YYYY-MM-DD"),
            language: title === "English" ? "En" : title === "Sinhala" ? "Si" : "",
            expireVisibility: expireVisibility ? moment(expireVisibility).format("YYYY-MM-DD") : "",
            ipCountry: ipCountry,
            contactNumber: currUser?.contactNumber

        }

        obj = { ...selectedProfile, ...obj }



        console.log("obj>>>>", obj)


        if (
            packageId
            // selectedProfile
        ) {

            if (!selectedJob) {

                setlOpen(true);
                postApplicationPay(obj)
                    .then((res) => {
                        setlOpen(false);
                        if (res.data.includes('stripe')) {
                            window.location.href = res.data;
                            return;
                        }
                        // setlOpen(false);

                        setbOpen(true);
                        setbMsg(t("adSucc"));
                        setbStatus("success");

                        // addToast("Job successfully added", "success");
                        settitle("");
                        setjobType("");
                        setcountry("");
                        setdescription("");
                        sethomeDistrict("");
                        sethomeCity("");
                        setduration("");
                        setrate("");
                        setImages([]);
                        setKeywords("");
                        setexpire("");
                        setcurrency("");
                        setEditorData("<br/><br/><br/><br/><br/>");
                        setselectedProfile("")
                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        setlOpen(false);
                        console.log("Error", error.response);

                        if (error.response.data.message === "You can only publish one FREE Ad!") {
                            if (t('langKey') === "Si") {
                                setbMsg("ඔබගේ නොමිලේ පලකල හැකි දැන්වීම් සීමාව පසුකර ඇත. එක් දැන්වීමක් පමණක් නොමිලේ පල කල හැක.");

                            } else {
                                setbMsg(error.response.data.message);

                            }

                        } else {
                            setbMsg(error.response.data.message);

                        }
                        setbOpen(true);
                        setbStatus("warning");

                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })
            } else {
                setlOpen(true);
                UpdateAds(obj, selectedJob?.id)
                    .then((res) => {
                        setlOpen(false);
                        if (res.data.includes('stripe')) {
                            window.location.href = res.data;
                            return;
                        } else {

                            setbOpen(true);
                            setbMsg("Ad successfully updated ");
                            setbStatus("success");

                        }


                        // addToast("Job successfully updated ", "success");
                        settitle("");
                        setjobType("");
                        setcountry("");
                        setdescription("");
                        sethomeDistrict("");
                        sethomeCity("");
                        setduration("");
                        setrate("");
                        setImages([]);
                        setKeywords("");
                        setexpire("");
                        setcurrency("");
                        onCloseModalQuickView();
                        // setOpenToast(true);
                        // setColor(COLORS.Primary);
                        // setAlertClass("success");
                        // setMsg("Successfully updated");
                        // getCurrentUser();
                        // seteDisable(true);
                        // getUser();

                        // history.push("/");
                        // window.location.reload();
                    })
                    .catch((error) => {
                        setlOpen(false);
                        console.log("Error", error.response);
                        setbOpen(true);
                        setbMsg(error.response.data.message);
                        setbStatus("warning");

                        // addToast(error.response.data.message, "warning");
                        // setOpenToast(true);
                        // setColor(COLORS.Orange);
                        // setAlertClass("error");
                        // setMsg(error.response.data.message);
                    })

            }

        } else {
            // handleScroll();
            setbOpen(true);
            setbMsg("Required fields are missing and add missing fields.");
            setbStatus("warning");
        }


    }

    const getCurrentUser = () => {
        setlOpen(true);

        CurrentUser()
            .then((res) => {
                if (res.statusCode === 200) {
                    const cData = res.data[0];
                    // setUser(cData);
                    const obj = {
                        id: cData.id,
                        email: cData?.email,
                        firstName: cData?.firstName,
                        lastName: cData?.lastName,
                        phone: cData?.mobileNumber,
                    };
                    //   this.setState({
                    //     firstName: cData?.firstName,
                    //     lastName: cData?.lastName,
                    //     email: cData?.email,
                    //     tele: cData?.phone,
                    //   });
                    console.log("<><><><>", cData);
                    setcurrUser(cData);

                    setoldPkg(cData?.applicationPkg)

                    if (cData?.applicationPkg === 1) {
                        setpackageId("2");
                    } else if (cData?.applicationPkg === 2) {
                        setpackageId("3");
                    } else if (cData?.applicationPkg === 3) {
                        setpackageId("4");
                    } else if (cData?.applicationPkg === 4) {
                        setpackageId("4");
                    }

                    if (!selectedJob) {

                        setipCountry(cData?.country)


                        if (cData?.country === "Sri Lanka") {


                            // setpackageId("2");

                        } else {


                            // setpackageId("2");
                            let day = moment().add(30, "days")
                            day = moment(day).format("YYYY-MM-DD")


                            setexpire(moment(day).toDate());
                            setexpireVisibility(moment(day).toDate());

                        }
                    }



                    setlOpen(false);


                    // setpackageId(cData.packageId ? cData.packageId : "");

                    // if (!selectedJob) {

                    //     if (cData.packageId === "1") {

                    //         let day = moment().add(7, "days")
                    //         day = moment(day).format("YYYY-MM-DD")

                    //         console.log(">>>>>>>", moment(day).toDate())
                    //         setexpire(moment(day).toDate());

                    //     } if (cData.packageId === "2") {

                    //         let day = moment().add(28, "days")
                    //         day = moment(day).format("YYYY-MM-DD")

                    //         console.log(">>>>>>>", moment(day).toDate())
                    //         setexpire(moment(day).toDate());

                    //     } else if (cData.packageId === "3") {

                    //         let day = moment().add(84, "days")
                    //         day = moment(day).format("YYYY-MM-DD")

                    //         console.log(">>>>>>>", moment(day).toDate())
                    //         setexpire(moment(day).toDate());

                    //     }
                    // }

                    localStorage.setItem(
                        `${window.location.hostname}.profile`,
                        JSON.stringify(obj)
                    );
                }
            })
            .catch((error) => {
                setlOpen(false);

                console.log("error", error);
                console.log("current user error", error.response.data.message);
            });
    };


    function CustomInput({ onFocus, value, onChange }) {
        return (
            <div className="mt-1.5 flex">
                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-xl las la-calendar"></i>
                </span>
                <Input
                    readOnly
                    className="!rounded-l-none"
                    placeholder="DD-MM-YYYY"
                    onFocus={onFocus}
                    value={value}
                    onChange={onChange}

                />

            </div>
        )
    }

    useEffect(() => {
        if (bOpen === true) {
            setTimeout(() => {

                setbOpen(false)

            }, 10000);
        }
    }, [bOpen])

    const renderXClear = () => {
        return (
            <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </span>
        );
    };

    return (
        <>
            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                selectedJob={selectedAd}

            />
            <LoadingSpinner open={lOpen} />
            <div className="justify-center w-full mt-2">




                {
                    !selectedJob &&

                    <div className="flex justify-center">
                        {/* <span className="text-xs font-normal flex justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6">

                            Are you looking to hire talent for your company? You can post your
                            job vacancies through this page.

                        </span> */}
                    </div>
                }




            </div>
            <div className="w-full flex justify-center px-3 mb-20">

                <div className="w-full md:w-11/12 lg:w-full">

                    <span className="block text-xl font-bold flex justify-start w-full text-left text-[#003D35]">

                        {selectedJob ? "Edit Ad" : t("appPkg")}
                    </span>

                    <div className="w-full mt-6 space-y-4">
                        {

                            // !selectedJob &&

                            <>

                                {/* <div>
                                    <Label className="text-sm">{t("adPro")}</Label>

                                    <Popover className="relative"
                                    >
                                        {({ open, close }) => (
                                            <>
                                                <Popover.Button
                                                    disabled={selectedJob ? true : false}
                                                    className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-md border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedProfile ? "!border-primary-500 bg-primary-50 text-primary-900"
                                                            : "bg-white border-white dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                                                        }
                `}
                                                >


                                                    <span className="ml-2 whitespace-nowrap text-slate-900 text-sm">{selectedProfile ? `${t("proHead")} ${(_.map(profiles, "id").findIndex((x) => x === selectedProfile?.id) + 1)}` : t("adPro")}
                                                    </span>
                                                    {!selectedProfile ? (
                                                        <ChevronDownIcon className="w-4 h-4 ml-3" />
                                                    ) : (
                                                        <span onClick={() =>
                                                        // setSizesState([])
                                                        {
                                                            setselectedProfile("")
                                                            setselectedAd("")
                                                            getCurrentUser();
                                                            close();
                                                        }
                                                        }>{renderXClear()}</span>
                                                    )}
                                                </Popover.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 translate-y-1"
                                                    enterTo="opacity-100 translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100 translate-y-0"
                                                    leaveTo="opacity-0 translate-y-1"
                                                >
                                                    <Popover.Panel className="absolute z-[999999] lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                                                        <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                                                            <div className="relative flex flex-col px-5 py-6 space-y-5">


                                                                {profiles.map((item, key) => (
                                                                    <div key={item} className="">
                                                                        <Checkbox
                                                                            name={`${t("proHead")} ${key + 1}`}
                                                                            label={`${t("proHead")} ${key + 1}`}
                                                                            checked={selectedProfile?.key === key}
                                                                            // defaultChecked={categoriesState.includes(item.mainId)}
                                                                            sizeClassName="w-5 h-5"
                                                                            labelClassName="text-sm font-normal"
                                                                            onChange={(checked) => {

                                                                                setselectedProfile(item);

                                                                                setselectedAd(item);

                                                                                seterrjobType(false);

                                                                                setadDetails(item);

                                                                                console.log(">>>>>>", item)

                                                                                setipCountry(item?.currentResidesCountry === "ශ්‍රී ලංකාව" ? "Sri Lanka" : item?.currentResidesCountry)

                                                                                if (item?.currentResidesCountry === "Sri Lanka" || item?.currentResidesCountry === "ශ්‍රී ලංකාව") {


                                                                                    setpackageId("1");

                                                                                } else {


                                                                                    setpackageId("4");
                                                                                    let day = moment().add(30, "days")
                                                                                    day = moment(day).format("YYYY-MM-DD")


                                                                                    setexpire(moment(day).toDate());
                                                                                    setexpireVisibility(moment(day).toDate());

                                                                                }

                                                                                close();

                                                                                // var data = ""



                                                                                // setselectedGender([item]);

                                                                                // setSelectedcat(item.value);

                                                                                // setselectedColour("");
                                                                                // setselectedSize("");
                                                                                // setCurrentPage(1);
                                                                                // removeLocal();

                                                                            }}
                                                                        // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                                                                        />

                                                                    </div>
                                                                ))}

                                                            </div>

                                                        </div>
                                                    </Popover.Panel>
                                                </Transition>
                                            </>
                                        )
                                        }
                                    </Popover >

                                 
                                    {errjobType && (
                                        <div className="text-red-600 text-sm text-right mt-2">
                                            This field is required
                                        </div>
                                    )}
                                </div> */}


                                <div>
                                    {/* <Label className="text-sm">{t("adPkg")}</Label> */}

                                    {/* <ul class="grid w-full gap-4 mt-6">
                                        {
                                            ipCountry === "Sri Lanka" &&

                                            <li>
                                                <input type="radio" id="hosting-small" name="hosting" class="hidden peer"
                                                    disabled={parseInt(oldPkg) > 1 && parseInt(oldPkg) != 4}
                                                    aria-describedby="helper-radio-text"
                                                    checked={ipCountry === "Sri Lanka" ? packageId === "1" : packageId === "4"}
                                                    onChange={(e) => {
                                                        seterrpackages(false);
                                                        setpackageId(e.target.value);

                                                        let day = moment().add(365, "days")
                                                        day = moment(day).format("YYYY-MM-DD")

                                                        console.log(">>>>>>>", moment(day).toDate())
                                                        setexpireVisibility(moment(day).toDate());
                                                    }}
                                                    value={ipCountry === "Sri Lanka" ? "1" : "4"}
                                                />
                                                <label for="hosting-small" class={`inline-flex items-start justify-between w-full h-full py-6 px-10 bg-white rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500   dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 ${packageId === "1" ? "text-[#2d867b] border border-[#2d867b]" : "text-slate-900 border-none"} ${parseInt(oldPkg) > 1 ? "bg-gray-50" : "bg-white"}`}

                                                >
                                                    <div class="block">
                                                       
                                                        <button
                                                            className={
                                                                `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
font-medium cursor-pointer  bg-[#b69d52] px-2.5 lg:px-4 h-5 mb-2`}



                                                        >

                                                            <span className="block  text-[8px] lg:text-[10px] font-medium whitespace-nowrap text-white">
                                                                Select
                                                            </span>

                                                        </button>
                                                        <div class="w-full text-2xl text-[#2D867B] font-bold">{ipCountry === "Sri Lanka" ? "Free Ad" : "USD 20 Ad"}</div>
                                                        <div class="w-full text-sm mt-2 text-[#45535E] font-medium">{ipCountry === "Sri Lanka" ? t("adMain") : t("adMain2")}</div>
                                                        <div class="w-full text-xs mt-2 text-[#737373]">{t("adPkg1Desc")}</div>
                                                    </div>
                                                    
                                                </label>
                                            </li>
                                        }


                                    </ul> */}

                                    {/* <div class="flex w-full justify-between mt-4 ">
                                        <div class="flex">
                                            <div class="flex items-center h-5">
                                                <input id="helper-radio"
                                                    disabled={parseInt(oldPkg) > 1}
                                                    aria-describedby="helper-radio-text"
                                                    checked={packageId === "1"}
                                                    onChange={(e) => {
                                                        setpackageId(e.target.value);
                                                        let day = moment().add(7, "days")
                                                        day = moment(day).format("YYYY-MM-DD")

                                                        console.log(">>>>>>>", moment(day).toDate())
                                                        setexpire(moment(day).toDate());
                                                    }}
                                                    type="radio"
                                                    value="1"
                                                    class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            </div>
                                            <div className="flex">
                                                <div class="ml-2 text-sm">
                                                    <label for="helper-radio" className={`cursor-pointer font-medium ${parseInt(oldPkg) > 1 ? "text-slate-900" : "text-gray-900"}  dark:text-gray-300`}>Starter

                                                    </label>
                                                    <p id="helper-radio-text" class="cursor-pointer text-xs font-normal text-slate-900 dark:text-gray-300">Free</p>
                                                </div>
                                                <div className="flex ml-2">
                                                    <Tooltip title={"One ad posting (job ad or marketplace listing) up  to 7 days."} placement="right-start" TransitionComponent={Zoom} arrow >
                                                        <i className="las la-info-circle cursor-pointer"></i>
                                                    </Tooltip>

                                                </div>
                                            </div>
                                        </div>



                                        <div class="flex">
                                            <div class="flex items-center h-5">
                                                <input id="helper-radio2"
                                                    disabled={parseInt(oldPkg) > 2}
                                                    aria-describedby="helper-radio-text"
                                                    checked={packageId === "2"}
                                                    onChange={(e) => {
                                                        setpackageId(e.target.value);

                                                        let day = moment().add(28, "days")
                                                        day = moment(day).format("YYYY-MM-DD")

                                                        console.log(">>>>>>>", moment(day).toDate())
                                                        setexpire(moment(day).toDate());
                                                    }}
                                                    type="radio"
                                                    value="2"
                                                    class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            </div>

                                            <div className="flex">
                                                <div class="ml-2 text-sm">
                                                    <label for="helper-radio2" className={`cursor-pointer font-medium ${parseInt(oldPkg) > 2 ? "text-slate-900" : "text-gray-900"}  dark:text-gray-300`}>Basic</label>
                                                    <p id="helper-radio-text" class="cursor-pointer text-xs font-normal text-slate-900 dark:text-gray-300">$ 15 per Ad</p>

                                                </div>
                                                <div className="flex ml-2">
                                                    <Tooltip title={"One ad posting (job ad or marketplace listing) up  to 28 days.  Showcase in the homepage results"} placement="right-start" TransitionComponent={Zoom} arrow >
                                                        <i className="las la-info-circle cursor-pointer"></i>
                                                    </Tooltip>

                                                </div>
                                            </div>

                                        </div>

                                        <div class="flex">
                                            <div class="flex items-center h-5">
                                                <input id="helper-radio3"
                                                    disabled={parseInt(oldPkg) > 3}
                                                    aria-describedby="helper-radio-text"
                                                    type="radio"
                                                    onChange={(e) => {
                                                        console.log(">>>", e.target.value)
                                                        setpackageId(e.target.value);

                                                        let day = moment().add(84, "days")
                                                        day = moment(day).format("YYYY-MM-DD")

                                                        console.log(">>>>>>>", moment(day).toDate())
                                                        setexpire(moment(day).toDate());
                                                    }}
                                                    checked={packageId === "3"}
                                                    value="3"
                                                    class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            </div>
                                            <div className="flex">
                                                <div class="ml-2 text-sm">

                                                    <label for="helper-radio3" className={`cursor-pointer font-medium ${parseInt(oldPkg) > 3 ? "text-slate-900" : "text-gray-900"}  dark:text-gray-300`}>Plus</label>
                                                    <p id="helper-radio-text" class="cursor-pointer text-xs font-normal text-slate-900 dark:text-gray-300">$ 30 per Ad</p>
                                                </div>
                                                <div className="flex ml-2">
                                                    <Tooltip title={"One ad posting (job ad or marketplace listing) up  to 84 days.  Showcase in the homepage results.   Priority listing in search results."} placement="right-start" TransitionComponent={Zoom} arrow >
                                                        <i className="las la-info-circle cursor-pointer"></i>
                                                    </Tooltip>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>

                                {/* {
                                    ipCountry === "Sri Lanka" &&

                                    <div>

                                        <span className="flex text-base text-[#002930] mt-6">{"Sponsored Ad"}</span>

                                        <span className="flex text-xs text-[#003D35] mt-1">{t("adPkg1Title")}</span>

                                    </div>

                                } */}

                                <ul class="grid w-full gap-4 mt-2">
                                    {
                                        // ipCountry != "Sri Lanka" &&
                                        //                                         <li>
                                        //                                             <input type="radio" id="hosting-small4" name="hosting" class="hidden peer"
                                        //                                                 disabled={oldPkg && parseInt(oldPkg) != 4}
                                        //                                                 aria-describedby="helper-radio-text"

                                        //                                                 onChange={(e) => {
                                        //                                                     seterrpackages(false);

                                        //                                                     console.log(">>>", e.target.value)
                                        //                                                     setpackageId(e.target.value);

                                        //                                                     let day = moment().add(30, "days")
                                        //                                                     day = moment(day).format("YYYY-MM-DD")

                                        //                                                     console.log(">>>>>>>", moment(day).toDate())
                                        //                                                     setexpireVisibility(moment(day).toDate());

                                        //                                                     if (ipCountry != "Sri Lanka") {

                                        //                                                         let day = moment().add(30, "days")
                                        //                                                         day = moment(day).format("YYYY-MM-DD")


                                        //                                                         setexpire(moment(day).toDate());

                                        //                                                     }

                                        //                                                 }}
                                        //                                                 checked={packageId === "4"}
                                        //                                                 value="4"
                                        //                                             />
                                        //                                             <label for="hosting-small4" class={`inline-flex items-start justify-between w-full h-full p-5 bg-white rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 ${packageId === "4" ? "text-[#2d867b] border border-[#2d867b]" : "text-slate-900 border-none"} ${(oldPkg && parseInt(oldPkg) != 4) ? "bg-gray-50" : "bg-white"} hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700`}

                                        //                                             >
                                        //                                                 <div class="block">
                                        //                                                     {/* <div class="w-full text-base font-semibold">{t("adPkg3")}</div> */}
                                        //                                                     <button
                                        //                                                         className={
                                        //                                                             `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
                                        // font-medium cursor-pointer  bg-[#b69d52] px-2.5 lg:px-4 h-5 mb-2`}



                                        //                                                     >

                                        //                                                         <span className="block  text-[8px] lg:text-[10px] font-medium whitespace-nowrap text-white">
                                        //                                                             Select
                                        //                                                         </span>

                                        //                                                     </button>
                                        //                                                     <div class="w-full text-base text-[#2D867B] font-bold">{

                                        //                                                         "USD 20"
                                        //                                                     }
                                        //                                                     </div>
                                        //                                                     <div class="w-full text-xs mt-2">{ipCountry === "Sri Lanka" ? t("adPkg1Desc") : t("adPkg1Desc2")}</div>

                                        //                                                     {
                                        //                                                         ipCountry != "Sri Lanka" &&

                                        //                                                         <div class="w-full text-xs mt-2 text-[#737373]">{t("adPkg1Desc")}</div>

                                        //                                                     }
                                        //                                                 </div>

                                        //                                             </label>
                                        //                                         </li>

                                        <li>
                                            <input type="radio" id="hosting-small3" name="hosting" class="hidden peer"
                                                disabled={parseInt(oldPkg) >= 2}
                                                aria-describedby="helper-radio-text"
                                                checked={packageId === "2"}
                                                onChange={(e) => {
                                                    seterrpackages(false);

                                                    setpackageId(e.target.value);

                                                    let day = moment().add(30, "days")
                                                    day = moment(day).format("YYYY-MM-DD")

                                                    console.log(">>>>>>>", moment(day).toDate())
                                                    setexpireVisibility(moment(day).toDate());

                                                    if (ipCountry != "Sri Lanka") {

                                                        let day = moment().add(30, "days")
                                                        day = moment(day).format("YYYY-MM-DD")


                                                        setexpire(moment(day).toDate());

                                                    }
                                                }}
                                                value="2"
                                            />
                                            <label for="hosting-small3" class={`inline-flex items-start justify-between w-full h-full p-5 bg-white rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 ${packageId === "2" ? "text-[#2d867b] border border-[#2d867b]" : "text-slate-900 border-none"} ${parseInt(oldPkg) >= 2 ? "bg-gray-50" : "bg-white"} hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700`}

                                            >
                                                <div class="block">
                                                    {/* <div class="w-full text-base font-semibold">{t("adPkg2")}</div> */}
                                                    <button
                                                        className={
                                                            `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
font-medium cursor-pointer  bg-[#b69d52] px-2.5 lg:px-4 h-5 mb-2`}



                                                    >

                                                        <span className="block  text-[8px] lg:text-[10px] font-medium whitespace-nowrap text-white">
                                                            Select
                                                        </span>

                                                    </button>
                                                    <div class="w-full text-base text-[#2D867B] font-bold">

                                                        {
                                                            ipCountry === "Sri Lanka" ?

                                                                "LKR 200"
                                                                :
                                                                "USD 20"
                                                        }

                                                    </div>
                                                    <div class="w-full text-xs mt-2">{ipCountry === "Sri Lanka" ? t("adPkg2Desc") : t("adPkg2Desc2")}</div>

                                                    {
                                                        ipCountry != "Sri Lanka" &&

                                                        <div class="w-full text-xs mt-2 text-[#737373]">{t("adPkg1Desc")}</div>

                                                    }
                                                </div>
                                                {/* <svg class="w-5 h-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
        </svg> */}
                                            </label>
                                        </li>
                                    }

                                    <li>
                                        <input type="radio" id="hosting-small1" name="hosting" class="hidden peer"
                                            disabled={parseInt(oldPkg) >= 3}
                                            aria-describedby="helper-radio-text"
                                            checked={packageId === "3"}
                                            onChange={(e) => {
                                                seterrpackages(false);

                                                setpackageId(e.target.value);

                                                let day = moment().add(90, "days")
                                                day = moment(day).format("YYYY-MM-DD")

                                                console.log(">>>>>>>", moment(day).toDate())
                                                setexpireVisibility(moment(day).toDate());

                                                if (ipCountry != "Sri Lanka") {

                                                    let day = moment().add(90, "days")
                                                    day = moment(day).format("YYYY-MM-DD")


                                                    setexpire(moment(day).toDate());

                                                }
                                            }}
                                            value="3"
                                        />
                                        <label for="hosting-small1" class={`inline-flex items-start justify-between w-full h-full p-5 bg-white rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 ${packageId === "3" ? "text-[#2d867b] border border-[#2d867b]" : "text-slate-900 border-none"} ${parseInt(oldPkg) >= 3 ? "bg-gray-50" : "bg-white"} hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700`}

                                        >
                                            <div class="block">
                                                {/* <div class="w-full text-base font-semibold">{t("adPkg2")}</div> */}
                                                <button
                                                    className={
                                                        `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
font-medium cursor-pointer  bg-[#b69d52] px-2.5 lg:px-4 h-5 mb-2`}



                                                >

                                                    <span className="block  text-[8px] lg:text-[10px] font-medium whitespace-nowrap text-white">
                                                        Select
                                                    </span>

                                                </button>
                                                <div class="w-full text-base text-[#2D867B] font-bold">

                                                    {
                                                        ipCountry === "Sri Lanka" ?

                                                            "LKR 500"
                                                            :
                                                            "USD 50"
                                                    }

                                                </div>
                                                <div class="w-full text-xs mt-2">{ipCountry === "Sri Lanka" ? t("adPkg2Desc") : t("adPkg2Desc2")}</div>

                                                {
                                                    ipCountry != "Sri Lanka" &&

                                                    <div class="w-full text-xs mt-2 text-[#737373]">{t("adPkg1Desc")}</div>

                                                }
                                            </div>
                                            {/* <svg class="w-5 h-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                                </svg> */}
                                        </label>
                                    </li>
                                    <li>
                                        <input type="radio" id="hosting-small2" name="hosting" class="hidden peer"
                                            disabled={parseInt(oldPkg) >= 4}
                                            aria-describedby="helper-radio-text"

                                            onChange={(e) => {
                                                seterrpackages(false);

                                                console.log(">>>", e.target.value)
                                                setpackageId(e.target.value);

                                                let day = moment().add(180, "days")
                                                day = moment(day).format("YYYY-MM-DD")

                                                console.log(">>>>>>>", moment(day).toDate())
                                                setexpireVisibility(moment(day).toDate());

                                                if (ipCountry != "Sri Lanka") {

                                                    let day = moment().add(180, "days")
                                                    day = moment(day).format("YYYY-MM-DD")


                                                    setexpire(moment(day).toDate());

                                                }
                                            }}
                                            checked={packageId === "4"}
                                            value="4"
                                        />
                                        <label for="hosting-small2" class={`inline-flex items-start justify-between w-full h-full p-5 bg-white rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 ${packageId === "4" ? "text-[#2d867b] border border-[#2d867b]" : "text-slate-900 border-none"} ${parseInt(oldPkg) >= 4 ? "bg-gray-50" : "bg-white"} hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700`}

                                        >
                                            <div class="block">
                                                {/* <div class="w-full text-base font-semibold">{t("adPkg3")}</div> */}
                                                <button
                                                    className={
                                                        `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
font-medium cursor-pointer  bg-[#b69d52] px-2.5 lg:px-4 h-5 mb-2`}



                                                >

                                                    <span className="block  text-[8px] lg:text-[10px] font-medium whitespace-nowrap text-white">
                                                        Select
                                                    </span>

                                                </button>
                                                <div class="w-full text-base text-[#2D867B] font-bold">{
                                                    ipCountry === "Sri Lanka" ?

                                                        "LKR 900"
                                                        :
                                                        "USD 90"
                                                }
                                                </div>
                                                <div class="w-full text-xs mt-2">{ipCountry === "Sri Lanka" ? t("adPkg3Desc") : t("adPkg3Desc2")}</div>

                                                {
                                                    ipCountry != "Sri Lanka" &&

                                                    <div class="w-full text-xs mt-2 text-[#737373]">{t("adPkg1Desc")}</div>

                                                }
                                            </div>
                                            {/* <svg class="w-5 h-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                                </svg> */}
                                        </label>
                                    </li>
                                </ul>
                                {errpackages && (
                                    <div className="text-red-600 text-sm text-right mt-2">
                                        This field is required
                                    </div>
                                )}
                            </>
                        }

                        {/* <div ref={ref}>
                            <Label className="text-sm">Ad title</Label>
                            <Input className="mt-1.5"
                                placeholder="Enter Ad title"
                                onChange={(e) => {
                                    settitle(e.target.value);
                                    seterrtitle(false);
                                }}
                                value={title}
                            />
                            {errtitle && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}



                        {/* <div>
                            <Label className="text-sm">{t("hLang")}</Label>

                            <Popover className="relative"
                            >
                                {({ open, close }) => (
                                    <>
                                        <Popover.Button
                                            // disabled={selectedJob ? true : false}
                                            className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-md border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${title ? "!border-primary-500 bg-primary-50 text-primary-900"
                                                    : "bg-white border-white dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                                                }
                `}
                                        >


                                            <span className="ml-2 whitespace-nowrap text-slate-900 text-sm">{title ? (title === "Sinhala" ? "සිංහල" : title) : t("hLang")}
                                            </span>
                                            {!selectedProfile ? (
                                                <ChevronDownIcon className="w-4 h-4 ml-3" />
                                            ) : (
                                                <span onClick={() =>
                                                    // setSizesState([])
                                                    settitle("English")
                                                }>{renderXClear()}</span>
                                            )}
                                        </Popover.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 translate-y-1"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-150"
                                            leaveFrom="opacity-100 translate-y-0"
                                            leaveTo="opacity-0 translate-y-1"
                                        >
                                            <Popover.Panel className="absolute z-40 lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                                                <div className="max-h-[300px] overflow-y-auto rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                                                    <div className="relative flex flex-col px-5 py-6 space-y-5">


                                                        {["English", "Sinhala"].map((item) => (
                                                            <div key={item} className="">
                                                                <Checkbox
                                                                    name={item}
                                                                    label={item === "Sinhala" ? "සිංහල" : item}
                                                                    checked={title === item}
                                                                    // defaultChecked={categoriesState.includes(item.mainId)}
                                                                    sizeClassName="w-5 h-5"
                                                                    labelClassName="text-sm font-normal"
                                                                    onChange={(checked) => {


                                                                        settitle(item);

                                                                        close();

                                                                    }}
                                                                // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                                                                />

                                                            </div>
                                                        ))}
                    
                                                    </div>
                  
                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )
                                }
                            </Popover >

                           
                            {errtitle && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                        {/* <div>
                            <Label className="text-sm">Description</Label>
                            <Textarea className="mt-1.5" rows={8}
                                placeholder="Add brief description"
                                onChange={(e) => {
                                    setdescription(e.target.value);
                                    seterrdescription(false);
                                }}
                                value={description}
                            />
                            {errdescription && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                        {/* <div>
                            <Label className="text-sm">More Info</Label>
                            <CKEditor

                                editor={ClassicEditor}
                                data={editorData}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data);
                                    console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}




                        {
                            (selectedJob.packageExpire === 0 || !selectedJob) &&

                            <div className="w-full items-center justify-center">





                                {/* <div className="form-check flex mt-6">
                                    <input id="checkbox-switch-pol1" className="cursor-pointer w-5 h-5 text-[#002930] bg-white border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"
                                        checked={policy1}

                                        onChange={(e) => {
                                            console.log("<<<<<", e.target.checked)
                                            setpolicy1(e.target.checked)

                                            // onChangeUserArr(e, arr.privilege)
                                            // this.onChangeAllArr(e, arr.privilege);
                                        }}
                                    />
                                    <label
                                        className="form-check-label ml-2 text-xs flex items-center cursor-pointer"
                                        htmlFor="checkbox-switch-pol1"
                                    >
                                        {t("agree")}
                                    </label>



                                </div> */}

                                <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                                <button
                                    // disabled={!policy1}

                                    className={
                                        `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-9 text-slate-50 font-medium mt-8 `}
                                    onClick={saveJob}

                                >
                                    {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                                    <span className="block  text-sm font-medium "
                                    >
                                        {!selectedJob ? (packageId === "1" ? t("pButtonBottom") : t("adButton2")) : t("Save")}
                                    </span>

                                </button>


                            </div>
                        }


                    </div>

                </div>
            </div>

        </>
    )

}

export default Jobs;